import { IonButton, IonCol, IonText } from "@ionic/react";
import React from "react";
import cleanMatColor from "../../data/cleanMatColor";
import cleanMatShape from "../../data/cleanMatShape";
import cleanMountType from "../../data/cleanMountType";
import cleanArtType from "../../data/cleanType";

const CustomDescription: React.FC<{
  onShowCustomPanelHandler?: () => void;
  artType:string;
  mount:string;
  matSize:number;
  matColor:string;
  matShape:string;
  wSelected:number;
  hSelected:number;
  out_w_cm: number;
  out_h_cm: number;
  showCustomButton:boolean;
}> = (props) => {

  return (
<React.Fragment>
        <IonCol size="12" style={{ paddingBottom: "0.5rem" }}>
          <IonText color="medium shade">
            <p className="text-arttype-title" style={{ marginBottom: "0px" }}>
              TYPE D'OEUVRE :
            </p>
          </IonText>
          <IonText color="dark">
            <p className="text-arttype"> {cleanArtType[props.artType]}</p>
          </IonText>
        </IonCol>
        {props.mount !== "canvas" && (
          <IonCol size="12" style={{ paddingBottom: "0.5rem" }}>
            <IonText color="medium shade">
              <p className="text-arttype-title" style={{ marginBottom: "0px" }}>
                PERSONNALISATION :
              </p>
            </IonText>
            <IonText color="dark">
              <p
                className="text-arttype"
                style={{
                  display: "inline-block",
                }}
              >
                Montage <strong>{cleanMountType[props.mount]}</strong>
              </p>
            </IonText>
            {props.matSize !== 0 && (
              <React.Fragment>
                <IonText color="primary">
                  <p
                    className="text-arttype"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    &nbsp;/&nbsp;
                  </p>
                </IonText>
                <IonText color="dark">
                  <p
                    className="text-arttype"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {"Passe-partout "}
                    <strong>{cleanMatShape[props.matShape]}</strong>
                  </p>
                </IonText>
                <IonText color="primary">
                  <p
                    className="text-arttype"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    &nbsp;/&nbsp;
                  </p>
                </IonText>
                <IonText color="dark">
                  <p
                    className="text-arttype"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {"Couleur "}
                    <strong>{cleanMatColor[props.matColor]}</strong>
                  </p>
                </IonText>
              </React.Fragment>
            )}
          </IonCol>
        )}
        <IonCol size="12" style={{ paddingBottom: "0.5rem" }}>
          <IonText color="medium shade">
            <p className="text-arttype-title" style={{ marginBottom: "0px" }}>
              TAILLE DE L'OEUVRE :
            </p>
          </IonText>
          <IonText color="dark">
            <p className="text-arttype">
              {props.wSelected} x {props.hSelected} cm
            </p>
          </IonText>
        </IonCol>
        <IonCol
          size="12"
          //className="ion-padding-bottom"
        >
          <IonText color="medium shade">
            <p className="text-arttype-title" style={{ marginBottom: "0px" }}>
              TAILLE DU CADRE :
            </p>
          </IonText>
          <IonText color="dark">
            <p className="text-arttype">
              {Math.round(Number(props.out_w_cm))} x{" "}
              {Math.round(Number(props.out_h_cm))} cm
            </p>
          </IonText>
        </IonCol>
        {props.showCustomButton && <IonCol size="12">
          <IonButton
            mode="ios"
            expand="full"
            onClick={props.onShowCustomPanelHandler}
            color="primary"
            fill="clear"
          >
            <p
              style={{
                textDecoration: "underline",
              }}
            >
              Personnaliser mon cadre
            </p>
          </IonButton>
        </IonCol>}
    </React.Fragment>
    
  );
};

export default CustomDescription;
