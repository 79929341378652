import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonButton,
} from "@ionic/react";
import ProgressiveImage from "react-progressive-graceful-image";

const MountDisplay: React.FC<{ onChange: () => {} }> = (props) => {
  const classic_title = "classique - une valeur \n sûre";
  const classic_p1 =
    "Sans surprise, c'est le montage le plus connu du public. Votre œuvre sera ici fixée sur un carton plume sans acide de 5mm afin d'en assurer une planéité et conservation optimale.";
  const classic_p2 =
    "Ensuite et selon votre choix, votre œuvre sera soit directement au contact du verre de présentation anti-UV ou bien séparé de ce dernier par un passe-partout.";
  const classic_artwork = "Photos et œuvres sur papier";
  const classic_dim = "Toutes dimensions";
  const floating_title = "flottant - saisissant et sophistiqué";
  const floating_p1 =
    "Imaginez ici votre œuvre surélevée de quelques mm au-dessus du carton de fond de manière à créer un espace entre le verre, l’œuvre et le fond de votre cadre. Effet waouh garanti.";
  const floating_p2 =
    "Ce type de montage est recommandé aussi bien pour les photos afin leur donner un style moderne et original, que pour les sujets sur papier dont le contour ou la texture de la feuille fait partie intégrante de l’oeuvre.";
  const floating_artwork = "Photos, œuvres sur papiers structurés";
  const floating_dim = "Petites et moyennes dimensions";
  const box_title = "profond - moderne \net épuré";
  const box_p1 =
    "Nous éloignons ici la vitre de présentation de l’œuvre afin d’apporter une sensation de profondeur intense à votre encadrement.";
  const box_p2 =
    "Nous conseillons ce montage aussi bien pour les tirages photos que pour les oeuvres sur papier. C’est aussi l’encadrement par défaut dans le cas d’objets et œuvres présentant une certaine épaisseur.";
  const box_artwork = "Photos & œuvres sur papier, objet";
  const box_dim = "Toutes dimensions";
  const canvas_title = "caisse américaine - pour les toiles et tableaux ";
  const canvas_p1 =
    "La caisse américaine est un montage minimaliste dit ouvert, c’est-à-dire sans verre de présentation. C’est le montage par excellence pour vos tableaux, toiles montées sur châssis et vos tirages photos sur alu-dibond. Votre œuvre sera ici placée en retrait de l’avant du cadre procurant ainsi une impression de flottement.";
  const canvas_p2 =
    "Dans le cas où votre toile ne serait pas encore tendue, nous réalisons la tension sur un châssis en pin massif.";
  const canvas_artwork = "Tableaux & toiles, tirages sur alu-dibond";
  const canvas_dim = "Toutes dimensions";

  const [titleMount, setTitleMount] = useState<string>(classic_title);
  const [p1Mount, setP1Mount] = useState<string>(classic_p1);
  const [p2Mount, setP2Mount] = useState<string>(classic_p2);
  const [mount, setMount] = useState<string>("classic");
  const [artType, setArtType] = useState<string>(classic_artwork);
  const [dim, setDim] = useState<string>(classic_dim);

  const changeMountHandler = (mountType: string) => {
    switch (mountType) {
      case "classic": {
        setMount("classic");
        setTitleMount(classic_title);
        setP1Mount(classic_p1);
        setP2Mount(classic_p2);
        setArtType(classic_artwork);
        setDim(classic_dim);
        break;
      }
      case "floating": {
        setMount("floating");
        setTitleMount(floating_title);
        setP1Mount(floating_p1);
        setP2Mount(floating_p2);
        setArtType(floating_artwork);
        setDim(floating_dim);
        break;
      }
      case "box": {
        setMount("box");
        setTitleMount(box_title);
        setP1Mount(box_p1);
        setP2Mount(box_p2);
        setArtType(box_artwork);
        setDim(box_dim);
        break;
      }
      case "canvas": {
        setMount("canvas");
        setTitleMount(canvas_title);
        setP1Mount(canvas_p1);
        setP2Mount(canvas_p2);
        setArtType(canvas_artwork);
        setDim(canvas_dim);
        break;
      }
      default: {
        setTitleMount(classic_title);
        setP1Mount(classic_p1);
        setP2Mount(classic_p2);
        setArtType(classic_artwork);
        setDim(classic_dim);
        break;
      }
    }
    props.onChange();
  };
  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <ProgressiveImage
        delay={200}
          src={"assets/img/mount/" + mount + ".jpg"}
          placeholder={"assets/img/mount/" + mount + "_tn.jpg"}
        >
          {(src: string | undefined, loading: any) => (
            <IonCol
              size="12"
              size-md="5.5"
              className="y-align image-cover"
              style={{
                minHeight: "300px",
                backgroundImage: "url('" + src + "')",
                filter: loading ? "blur(15px)" : "",
              }}
            ></IonCol>
          )}
        </ProgressiveImage>
        <IonCol size="12" offset-md="1" size-md="5.5">
          <IonText style={{ transition: "all 0.3s" }}>
            <div style={{ minHeight: "250px" }}>
              <h2 style={{ display: "block", whiteSpace: "pre-line" }}>
                Le montage {titleMount}
              </h2>
              <p style={{ display: "block" }}>{p1Mount}</p>
              <p style={{ display: "block" }}>{p2Mount}</p>
            </div>
          </IonText>
          <div
            style={{ width: "100%" }}
            className="ion-padding-top ion-padding-bottom ion-text-center ion-padding-bottom"
          >
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol size="6" className="ion-padding-start ion-text-center">
                  <div style={{ minHeight: "100px" }}>
                    <IonImg
                      style={{ display: "inline-block", width: "50px" }}
                      src="assets/img/mount/artwork.png"
                    />
                    <IonText
                      className="ion-text-wrap"
                      style={{ display: "block" }}
                    >
                      <p className="text-guid-specs">{artType}</p>
                    </IonText>
                  </div>
                </IonCol>
                <IonCol
                  size="6"
                  className="ion-text-center ion-padding-start ion-padding-end"
                >
                  <div style={{ minHeight: "100px" }}>
                    <IonImg
                      style={{ display: "inline-block", width: "50px" }}
                      src="assets/img/mount/size.png"
                    />
                    <IonText
                      className="ion-text-wrap"
                      style={{ display: "block" }}
                    >
                      <p className="text-guid-specs">{dim}</p>
                    </IonText>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mount === "classic" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            style={{ display: "inline-block" }}
            onClick={(e) => changeMountHandler("classic")}
          >
            <p className="text-guide-button">LE CLASSIQUE</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mount === "floating" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("floating")}
            style={{ display: "inline-block" }}
          >
            <p className="text-guide-button">LE FLOTTANT</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mount === "box" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("box")}
          >
            <p className="text-guide-button">LE PROFOND</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mount === "canvas" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("canvas")}
          >
            <p className="text-guide-button">LA CAISSE AMÉRICAINE</p>
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MountDisplay;
