import React from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonIcon,
} from "@ionic/react";

import { closeOutline } from "ionicons/icons";

const DeliveryInfoModal: React.FC<{
  show: boolean;
  onDismiss: () => void;
}> = (props) => {
  return (
    <IonModal isOpen={props.show} backdropDismiss={false}>
      <IonHeader className="ion-no-border">
        <IonToolbar color="undefined" className="">
          <IonButtons slot="end"><IonButton size='large' fill='clear' onClick={e=>props.onDismiss()}><IonIcon icon={closeOutline}/></IonButton></IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="undefined">
        <IonGrid>
          <IonRow>
            <IonCol size="12" className="ion-text-center">
              <IonText color="dark">
                <h2>Par voie postale</h2>
                <p>
                  Collecte des oeuvres par colissimo 
                  <br/><strong>GRATUIT</strong>
                  <br/>
                  <br/>Livraison des commandes par colissimo (3-5j)
                  <br/><strong>6.90 €</strong>
                  <br/>Livraison des commandes par DHL (2j)
                  <br/><strong>13.90 €</strong>
                </p>
              </IonText>
            </IonCol>
            <IonCol size="12" className="ion-text-center">
              <IonText color="dark">
                <h2>Par coursier</h2>
                <p>
                Disponible sur Lyon et Grenoble uniquement
                <br/>
                  Collecte des oeuvres + livraison de la commande
                  <br/><strong>39€</strong>
                </p>
              </IonText>
            </IonCol>
            <IonCol size="12" className="ion-text-center ion-padding-top">
              <IonButton
                size="large"
                mode="ios"
                fill="outline"
                color="dark"
                shape="round"
                onClick={e=>props.onDismiss()}
                className="xl-button"
              >
                <IonText className="ion-text-wrap">
                  <p className="text-button">FERMER</p>
                </IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default DeliveryInfoModal;
