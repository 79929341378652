import React, { useRef, useState, useContext } from "react";
import { calculateMaxSize } from "../../util/frame";
import Cropper from "react-cropper";
import "../../theme/cropper.css";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
  IonFooter,
  IonText,
  IonProgressBar,
  IonLoading,
  isPlatform,
  IonFab,
  IonFabButton,
  IonFabList,
} from "@ionic/react";
import {
  refreshOutline,
  squareOutline,
  arrowBackOutline,
  closeOutline,
  contrastOutline,
  arrowForwardOutline,
  tabletLandscapeOutline,
  tabletPortraitOutline,
} from "ionicons/icons";
import ImageUpload from "../../components/shop/ImageUpload";
import { useForm } from "../../hooks/form-hook";
import { useHistory } from "react-router";
import { useHttpClient } from "../../hooks/http-hook";
import fContext from "../../context/f-context";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import WizardLama from "../../components/shop/WizardLama";
import { useAuth } from "../../hooks/auth-hook";
import AuthContext from "../../context/auth-context";

const cleanArtType: { [id: string]: string } = {
  document: "Documents/Photos",
  art_on_paper: "Oeuvre",
  unmounted_canvas: "Toile",
  mounted_canvas: "Toile",
  object: "Objet",
  textil: "Textile/Maillot",
};

const BuildDigital: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [takenPhoto, setTakenPhoto] = useState<{
    path: string | undefined;
    preview: string;
  }>();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

  const cropper = useRef<Cropper>(null);

  const [squareMode, setSquareMode] = useState(true);
  const [landscapeMode, setLandscapeMode] = useState(false);
  const [portraitMode, setPortraitMode] = useState(false);
  const [bwMode, setBwMode] = useState(false);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const fCtx = useContext(fContext);
  const authCtx = useContext(AuthContext);
  const isOldEiffageFormat = authCtx.code
    ?.split("")
    .every(
      (character: string) => !"AZERTYUIOPQSDFGHJKLMWXCVBN".includes(character)
    );

  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      address: {
        value: "",
        isValid: false,
      },
      image: {
        value: null,
        isValid: false,
      },
    },
    false
  );

  const startDigitalPickerHandler = async () => {
    //classic input file html
    inputRef.current!.click();
  };

  const backToUploadingChoiceHandler = () => {
    fCtx.loadImageUrl("", 0, 0);
  };

  const previewUrlHandler = (preview_url: string) => {
    var artwork = new Image();
    artwork.onload = function () {
      //set context image values
      fCtx.loadImageUrl("local", artwork.width, artwork.height);
      setPreviewUrl(preview_url);
      return true;
    };
    artwork.src = URL.createObjectURL(formState.inputs.image.value);
    return true;
  };

  const oldRot = () => {
    cropper.current!.rotate(90);

    if (!squareMode) {
      cropper.current!.setAspectRatio(NaN);
    } else {
      cropper.current!.setAspectRatio(1);
    }
  };

  const startRotationHandler = () => {
    var contData = cropper.current!.getContainerData();

    cropper.current!.setCropBoxData({
      width: 2,
      height: 2,
      top: contData.height / 2 - 1,
      left: contData.width / 2 - 1,
    });

    cropper.current!.rotate(90);

    var canvData = cropper.current!.getCanvasData();
    var newWidth = canvData.width * (contData.height / canvData.height);

    if (newWidth >= contData.width) {
      var newHeight = canvData.height * (contData.width / canvData.width);
      var newCanvData = {
        height: newHeight,
        width: contData.width,
        top: (contData.height - newHeight) / 2,
        left: 0,
      };
    } else {
      var newCanvData = {
        height: contData.height,
        width: newWidth,
        top: 0,
        left: (contData.width - newWidth) / 2,
      };
    }

    cropper.current!.setCanvasData(newCanvData);
    cropper.current!.setCropBoxData(newCanvData);

    if (!squareMode) {
      cropper.current!.setAspectRatio(NaN);
    } else {
      cropper.current!.setAspectRatio(1);
    }
  };

  const startSquareModeHandler = () => {
    setSquareMode(true);
    setPortraitMode(false);
    setLandscapeMode(false);

    cropper.current!.setAspectRatio(1);
  };

  const startPortraitModeHandler = () => {
    setPortraitMode(true);
    setSquareMode(false);
    setLandscapeMode(false);
    if (isOldEiffageFormat) {
      cropper.current!.setAspectRatio(10 / 15);
    } else {
      cropper.current!.setAspectRatio(17.25 / 23);
    }
  };

  const startLandscapeModeHandler = () => {
    setLandscapeMode(true);
    setPortraitMode(false);
    setSquareMode(false);
    if (isOldEiffageFormat) {
      cropper.current!.setAspectRatio(15 / 10);
    } else {
      cropper.current!.setAspectRatio(23 / 17.25);
    }
  };

  const startBwModeHandler = () => {
    setBwMode(!bwMode);
  };

  const cropHandler = () => {
    //console.log(cropper.current!.getData(true));
  };

  const fileInputHandler = async () => {
    try {
      let cropper_width = cropper.current!.getData(true).width;
      let cropper_height = cropper.current!.getData(true).height;

      const formData = new FormData();

      formData.append("image", formState.inputs.image.value);
      formData.append("x", cropper.current!.getData(true).x.toString());
      formData.append("y", cropper.current!.getData(true).y.toString());
      formData.append("width", cropper_width.toString());
      formData.append("height", cropper_height.toString());
      formData.append("bw_mode", bwMode.toString());
      formData.append(
        "rotate",
        cropper.current!.getData(true).rotate.toString()
      );
      formData.append("art_type", fCtx.artType.toString());

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/upload",
        "POST",
        formData
      );

      //console.log(responseData);
      var artwork = new Image();
      artwork.onload = function () {
        //set context image values
        const size_lists = calculateMaxSize(cropper_width, cropper_height);
        //console.log(size_lists);
        fCtx.addImageUrl(
          responseData.imagePath,
          responseData.article_id,
          responseData.original_name,
          responseData.min_price_list,
          responseData.lama_list,
          cropper_width,
          cropper_height,
          size_lists.w_list,
          size_lists.h_list
        );

        if (portraitMode) {
          if (isOldEiffageFormat) {
            fCtx.selectSize(10, 15, 0);
          } else {
            fCtx.selectSize(17.25, 23, 0);
            fCtx.selectMatSize(4);
          }
        } else if (landscapeMode) {
          if (isOldEiffageFormat) {
            fCtx.selectSize(15, 10, 0);
          } else {
            fCtx.selectSize(23, 17.25, 0);
            fCtx.selectMatSize(4);
          }
        } else {
          fCtx.selectSize(13, 13, 0);
        }

        //redirect to next page
        setSquareMode(true);
        setPortraitMode(false);
        setLandscapeMode(false);
        setBwMode(false);

        history.push("/gallery");
      };
      artwork.src = URL.createObjectURL(formState.inputs.image.value);
    } catch (err) {
      console.log(err);
    }
    console.log(fCtx.artType);
    return true;
  };

  const sampleArtHandler = async () => {
    // 3 parameters to change when changing sample photo
    const sample_art_path =
      "https://lama-assets.s3.eu-west-3.amazonaws.com/public/assets/img/sample_art/sample_art_3.jpg";
    const sample_width = 4480;
    const sample_height = 6720;
    try {
      const formData = new FormData();

      formData.append("sample_art_path", sample_art_path.toString());
      formData.append("art_type", fCtx.artType.toString());
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/sampleArt",
        "POST",
        formData
      );

      fCtx.addImageUrl(
        responseData.imagePath,
        responseData.article_id,
        responseData.original_name,
        responseData.min_price_list,
        responseData.lama_list,
        sample_width,
        sample_height,
        responseData.lama_list,
        responseData.lama_list
      );

      history.push("/gallery");
    } catch (err) {
      console.log(err);
    }
    console.log(fCtx.artType);
    return true;
  };

  const continueFunnelHandler = () => {
    history.push("/gallery");
  };

  const deleteFrameHandler = async () => {
    //history.push("/what-we-frame-today");
    const art_type_selected = fCtx.artType;
    await fCtx.deleteFrame();
    if (art_type_selected === "canvas") {
      fCtx.selectMount("canvas");
      fCtx.selectMatStyle("standard");
      fCtx.selectMatSize(-1.54);
    } else {
      fCtx.selectMatColor("white");
      fCtx.selectMatStyle("standard");
      fCtx.selectMatSize(8);
      fCtx.selectMount("simple");
      fCtx.selectSelectedStyle("standard");
    }
    fCtx.selectArtType(art_type_selected);
  };

  return (
    <IonPage>
      <IonHeader className={isPlatform("mobile") ? "ion-no-border" : ""}>
        {isPlatform("mobile") && (
          <IonToolbar color="undefined" className="">
            {fCtx.imageUrl &&
              fCtx.imageUrl !== "" &&
              fCtx.imageUrl === "local" &&
              previewUrl && (
                <IonButtons slot="start">
                  <IonButton
                    color="dark"
                    onClick={
                      fCtx.articleId === ""
                        ? backToUploadingChoiceHandler
                        : deleteFrameHandler
                    }
                  >
                    <IonIcon size="large" icon={arrowBackOutline}></IonIcon>
                  </IonButton>
                </IonButtons>
              )}
            {(!fCtx.imageUrl ||
              fCtx.imageUrl === "" ||
              (fCtx.imageUrl === "local" && !previewUrl)) && (
              <IonButtons slot="start">
                <IonButton
                  color="dark"
                  routerLink="/what-we-frame-today"
                  routerDirection="back"
                >
                  <IonIcon size="large" icon={arrowBackOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            )}
            {fCtx.imageUrl &&
              fCtx.imageUrl !== "" &&
              fCtx.imageUrl !== "local" && (
                <IonButtons slot="end">
                  <IonButton
                    color="dark"
                    onClick={
                      fCtx.articleId === ""
                        ? backToUploadingChoiceHandler
                        : deleteFrameHandler
                    }
                  >
                    <IonIcon size="large" icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonButtons>
              )}
          </IonToolbar>
        )}
        {isPlatform("desktop") && <DesktopToolbar />}
      </IonHeader>
      <IonContent fullscreen={isPlatform("mobile") ? true : false}>
        <IonGrid className="ion-full-height" style={{ paddingTop: "0px" }}>
          {isPlatform("desktop") && <WizardLama />}
          {(!fCtx.imageUrl ||
            fCtx.imageUrl === "" ||
            (fCtx.imageUrl === "local" && !previewUrl)) && (
            <React.Fragment>
              <IonRow>
                <IonCol
                  size="12"
                  size-md="10"
                  offset-md="1"
                  size-lg="8"
                  offset-lg="2"
                  size-xl="7"
                  offset-xl="2.5"
                  className="ion-text-center ion-margin-bottom ion-padding-bottom"
                >
                  <IonText>
                    <h1>
                      {fCtx.artType === "digital_photo" || fCtx.artType === ""
                        ? "Importez votre photo."
                        : "Avez-vous une photo de votre " +
                          cleanArtType[fCtx.artType] +
                          "?"}
                    </h1>
                    <p>
                      {fCtx.artType === "digital_photo"
                        ? "Merci d‘utiliser des .jpg, .png, .jpeg, et de 30Mo max. "
                        : "Ceci afin de réaliser une pré-visualisation de votre encadrement (.jpg, .png, .jpeg, 30 Mo max.)."}
                    </p>
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  size-md="4"
                  size="2"
                  className="ion-text-center ion-padding-bottom"
                >
                  {/*isPlatform("desktop") && (
                    <IonCard
                      className="ion-text-center"
                      button
                      color={"undefined"}
                      disabled
                    >
                      <IonIcon
                        className="iconSize ion-padding-top ion-margin-top"
                        icon={logoInstagram}
                      />
                      <IonCardContent style={{ paddingTop: "8px" }}>
                        <IonText className="text-home-card">
                          <p className="ion-padding-bottom text-home-card">
                            INSTAGRAM
                          </p>
                        </IonText>
                      </IonCardContent>
                    </IonCard>
                  )*/}
                  {/*isPlatform("mobile") && (
                    <React.Fragment>
                      <IonButton size="large" color="clear" mode="ios" disabled>
                        <IonIcon
                          color="dark"
                          icon={logoInstagram}
                          size="large"
                        />
                      </IonButton>
                      <IonText color="medium" className="ion-block ion-bold">
                        <p style={{ marginTop: "0px" }}>Instagram</p>
                      </IonText>
                    </React.Fragment>
                  )*/}
                </IonCol>
                <IonCol size-md="4" size="8" className="ion-text-center">
                  <ImageUpload
                    id="image"
                    onInput={inputHandler}
                    onFileRead={previewUrlHandler}
                    //errorText="Please provide an image."
                  />
                </IonCol>
                <IonCol size="2" className="ion-text-center ion-padding-bottom">
                  {/*isPlatform("desktop") && (
                    <IonCard
                      className="ion-text-center"
                      button
                      color={"undefined"}
                      disabled
                    >
                      <IonIcon
                        className="iconSize ion-padding-top ion-margin-top"
                        icon={logoPinterest}
                      />
                      <IonCardContent style={{ paddingTop: "8px" }}>
                        <IonText className="text-home-card">
                          <p className="ion-padding-bottom text-home-card">
                            PINTEREST
                          </p>
                        </IonText>
                      </IonCardContent>
                    </IonCard>
                  )*/}
                  {/*isPlatform("mobile") && (
                    <React.Fragment>
                      <IonButton size="large" color="clear" mode="ios" disabled>
                        <IonIcon
                          color="dark"
                          icon={logoPinterest}
                          size="large"
                        />
                      </IonButton>
                      <IonText color="medium" className="ion-block ion-bold">
                        <p style={{ marginTop: "0px" }}>Pinterest</p>
                      </IonText>
                    </React.Fragment>
                  )*/}
                </IonCol>
                <IonCol size="12" className="ion-text-center">
                  <IonText>
                    {fCtx.artType !== "digital_photo" && (
                      <IonButton onClick={sampleArtHandler} fill="clear">
                        <p
                          style={{
                            textDecoration: "underline",
                            display: "inline-block",
                          }}
                        >
                          Non, utiliser une de nos images aléatoires
                        </p>
                      </IonButton>
                    )}
                  </IonText>
                </IonCol>
              </IonRow>
            </React.Fragment>
          )}
          <IonRow class="ion-align-items-start ion-full-height">
            {previewUrl &&
              fCtx.imageUrl &&
              fCtx.imageUrl !== "" &&
              fCtx.articleId === "" && (
                <React.Fragment>
                  <IonCol
                    size="12"
                    class="ion-text-center"
                    //style={{ height: "13%" }}
                    style={{ paddingTop: "0px" }}
                  >
                    <IonText>
                      <h1
                        style={isPlatform("mobile") ? { marginTop: "0px" } : {}}
                      >
                        Choisissez votre format d’impression.
                      </h1>
                      <p>
                        {isOldEiffageFormat
                          ? "3 formats possibles : Paysage 15x10cm, Portrait 10x15 et Carré 13x13."
                          : "2 formats possibles : Paysage 23x17,25cm et Portrait 17,25x23"}
                      </p>
                      {/*<h4>C’est pas obligé, c’est comme vous voulez.</h4>*/}
                    </IonText>
                  </IonCol>
                  <IonCol
                    className={
                      isPlatform("desktop")
                        ? "ion-text-center equal-sized-item-desktop"
                        : "ion-text-center equal-sized-item"
                    }
                    size="12"
                    size-md="6"
                    offset-md="3"
                  >
                    <Cropper
                      ref={cropper}
                      src={previewUrl}
                      style={
                        bwMode
                          ? { height: "100%", filter: "grayscale(1)" }
                          : { height: "100%" }
                      }
                      //className="equal-sized-item"
                      // Cropper.js options
                      checkOrientation={true}
                      guides={false}
                      viewMode={2}
                      zoomable={false}
                      zoomOnTouch={false}
                      movable={false}
                      autoCropArea={1}
                      scalable={false}
                      background={false}
                      aspectRatio={4 / 3}
                      crop={cropHandler}
                    />
                  </IonCol>
                  <IonCol
                    size="4"
                    class="ion-text-center"
                    //style={{ height: "8%" }}
                    size-md="3"
                    offset-md="1.5"
                  >
                    <IonButton
                      onClick={startRotationHandler}
                      fill="clear"
                      color="dark"
                      mode="ios"
                    >
                      <IonIcon
                        color="dark"
                        slot="start"
                        size="large"
                        icon={refreshOutline}
                      />
                      <IonLabel className="text-button" color="dark">
                        <p className="text-button">Rotation</p>
                      </IonLabel>
                    </IonButton>
                  </IonCol>
                  <IonCol
                    size="4"
                    class="ion-text-center"
                    style={{ height: "65px" }}
                    size-md="3"
                  >
                    <IonFab
                      activated={true}
                      vertical="center"
                      horizontal="center"
                    >
                      <IonFabButton mode="ios" color="dark">
                        {/*<IonIcon color='light' icon={squareMode?squareOutline:portraitMode?tabletPortraitOutline:phoneLandscapeOutline} />*/}
                        <IonText style={{ display: "block" }} color="light">
                          Format
                        </IonText>
                      </IonFabButton>
                      <IonFabList side="top">
                        {isOldEiffageFormat && (
                          <IonButton
                            mode="ios"
                            fill={"solid"}
                            onClick={startSquareModeHandler}
                            style={{ width: "190px" }}
                            color={squareMode ? "dark" : "light"}
                          >
                            <IonIcon icon={squareOutline} />
                            <IonLabel>&nbsp;Carré 13x13cm</IonLabel>
                          </IonButton>
                        )}
                        <IonButton
                          mode="ios"
                          fill={"solid"}
                          onClick={startPortraitModeHandler}
                          style={{ width: "190px" }}
                          color={portraitMode ? "dark" : "light"}
                        >
                          <IonIcon icon={tabletPortraitOutline} />
                          <IonLabel>
                            &nbsp;Portrait{" "}
                            {isOldEiffageFormat ? "10x15cm" : "17,25x23cm"}
                          </IonLabel>
                        </IonButton>
                        <IonButton
                          mode="ios"
                          fill={"solid"}
                          onClick={startLandscapeModeHandler}
                          style={{ width: "190px" }}
                          color={landscapeMode ? "dark" : "light"}
                        >
                          <IonIcon size="large" icon={tabletLandscapeOutline} />
                          <IonLabel>
                            &nbsp;Paysage{" "}
                            {isOldEiffageFormat ? "15x10cm" : "23x17,25cm"}
                          </IonLabel>
                        </IonButton>
                      </IonFabList>
                    </IonFab>
                  </IonCol>
                  <IonCol
                    size="4"
                    class="ion-text-center"
                    //style={{ height: "8%" }}
                    size-md="3"
                  >
                    <IonButton
                      onClick={startBwModeHandler}
                      fill={bwMode ? "outline" : "clear"}
                      color="dark"
                      mode="ios"
                    >
                      <IonIcon
                        color="dark"
                        slot="start"
                        size="large"
                        icon={contrastOutline}
                      />
                      <IonLabel className="text-button" color="dark">
                        <p className="text-button">N&B</p>
                      </IonLabel>
                    </IonButton>
                  </IonCol>
                </React.Fragment>
              )}
            {fCtx.imageUrl !== "" &&
              fCtx.articleId !== "" &&
              fCtx.articleId !== undefined && (
                <React.Fragment>
                  <IonCol
                    size="12"
                    className="ion-text-center "
                    //style={{ height: "13%" }}
                  >
                    <IonText color="dark">
                      <h1>Votre photo chargée</h1>
                      {/*<h4>C’est pas obligé, c’est comme vous voulez.</h4>*/}
                    </IonText>
                  </IonCol>
                  <IonCol
                    className={
                      isPlatform("desktop")
                        ? "ion-text-center equal-sized-item-desktop"
                        : "ion-text-center equal-sized-item"
                    }
                    size="12"
                  >
                    {/*<IonFab vertical="bottom" horizontal="center" edge>
                      <IonFabButton color="light" onClick={deleteFrameHandler}>
                        <IonIcon icon={closeOutline} />
                      </IonFabButton>
                  </IonFab>{" "}*/}
                    <img
                      style={{ maxHeight: "100%" }}
                      src={
                        fCtx.imageUrl.includes("uploads/images")
                          ? process.env.REACT_APP_BACKEND_URL + fCtx.imageUrl
                          : fCtx.imageUrl
                      }
                    />
                  </IonCol>
                </React.Fragment>
              )}
            {isPlatform("desktop") &&
              ((fCtx.imageUrl && fCtx.imageUrl !== "local") ||
                (fCtx.imageUrl === "local" && previewUrl)) && (
                <React.Fragment>
                  <IonCol
                    size="6"
                    className="ion-text-center  ion-align-self-start"
                    //style={{ height: "7%" }}
                  >
                    <IonButton
                      size="large"
                      color="dark"
                      fill="clear"
                      mode="ios"
                      shape="round"
                      onClick={deleteFrameHandler}
                      disabled={isLoading ? true : false}
                      style={{
                        "--padding-start": "48px",
                        "--padding-end": "48px",
                      }}
                    >
                      {fCtx.imageUrl === "local" && (
                        <IonIcon icon={arrowBackOutline} />
                      )}
                      <IonText>
                        <p
                          className="text-button"
                          //style={{textDecoration:'underline'}}
                        >
                          {fCtx.imageUrl === "local"
                            ? "RETOUR"
                            : "CHANGER D'IMAGE"}
                        </p>
                      </IonText>
                    </IonButton>
                  </IonCol>
                  <IonCol
                    size="6"
                    className="ion-text-center  ion-align-self-start"
                    //style={{ height: "7%" }}
                  >
                    <IonButton
                      size="large"
                      color="dark"
                      fill="solid"
                      mode="ios"
                      shape="round"
                      onClick={
                        fCtx.imageUrl === "local"
                          ? fileInputHandler
                          : continueFunnelHandler
                      }
                      disabled={isLoading ? true : false}
                      style={{
                        "--padding-start": "48px",
                        "--padding-end": "48px",
                      }}
                    >
                      {isLoading && (
                        <React.Fragment>
                          <IonText className="text-button ion-text-wrap">
                            <p className="text-button">ENVOI EN COURS</p>
                          </IonText>
                          <br />
                          <IonProgressBar
                            color="primary"
                            type="indeterminate"
                          ></IonProgressBar>
                          <br />
                        </React.Fragment>
                      )}
                      {!isLoading && (
                        <React.Fragment>
                          <IonText>
                            <p className="text-button">SUIVANT</p>
                          </IonText>
                          <IonIcon icon={arrowForwardOutline} />
                        </React.Fragment>
                      )}
                    </IonButton>
                  </IonCol>
                </React.Fragment>
              )}
          </IonRow>
        </IonGrid>
        <IonLoading
          //cssClass='my-custom-class'
          isOpen={isLoading}
          message={"Analyse de l'image... \nCela peut prendre jusqu'à 1 minute"}
        />
      </IonContent>
      {isPlatform("mobile") &&
        ((fCtx.imageUrl && fCtx.imageUrl !== "local") ||
          (fCtx.imageUrl === "local" && previewUrl)) && (
          <IonFooter color="light" class="ion-text-center">
            <IonButton
              strong
              size="large"
              color="dark"
              expand="full"
              class="ion-no-margin"
              onClick={
                fCtx.imageUrl === "local"
                  ? fileInputHandler
                  : continueFunnelHandler
              }
              disabled={isLoading ? true : false}
            >
              {isLoading && (
                <React.Fragment>
                  <IonText>
                    <p className="text-button">ENVOI EN COURS</p>
                  </IonText>
                  <br />
                  <IonProgressBar
                    color="primary"
                    type="indeterminate"
                  ></IonProgressBar>
                  <br />
                </React.Fragment>
              )}
              {!isLoading && (
                <IonText>
                  <p className="text-button">SUIVANT</p>
                </IonText>
              )}
            </IonButton>
          </IonFooter>
        )}
    </IonPage>
  );
};

export default BuildDigital;
