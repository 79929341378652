import React, { useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonImg,
  IonText,
  IonItem,
  IonInput,
  IonIcon,

} from "@ionic/react";
import { useHttpClient } from "../../hooks/http-hook";
import { closeOutline } from "ionicons/icons";

const AddCartModal: React.FC<{
  show: boolean;
  onPassOrder: (code:string) => void;
  onDismiss: ()=>void;
}> = (props) => {

  const [code, setCode] = useState<string>('');
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const checkDiscountHandler = async ()=>{
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}api/shop/order/checkPrivilegeDiscount`,
        "POST",
        JSON.stringify({ discount_code: code }),
        {
          "Content-Type": "application/json",
        }
      );
props.onPassOrder(responseData.code._id);
        
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <IonModal isOpen={props.show} backdropDismiss={false}>
      <IonHeader className="ion-no-border">
        <IonToolbar color="undefined" className="">
        <IonButtons slot="end"><IonButton size='large' fill='clear' onClick={e=>props.onDismiss()}><IonIcon icon={closeOutline}/></IonButton></IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="undefined">
        <IonGrid>
          <IonRow class="ion-text-center">
            <IonCol size="12" className="ion-padding-bottom">
              <IonImg
                src="assets/img/lama/hang.png"
                style={{width:"130px" , margin:'auto'}}
                alt="Encadrement sur mesure Lama Factory accroché au mur"
              />
            </IonCol>
            <IonCol size="12" className="ion-padding-bottom">
              <IonLabel color="dark">
                <h1 style={{ fontWeight: "bold", paddingBottom:'8px' }}>
                  Veuillez à nouveau rentrer votre code privilège
                </h1>
              </IonLabel>
              <IonItem color='undefined' lines='full'>
              <IonLabel
                          color="medium shade"
                          className="ion-text-wrap "
                          position="floating"
                        >
                          Votre code privilège
                        </IonLabel>
                      <IonInput
                        type="tel"
                        size={300}
                        maxlength={12}
                        //onIonChange={sizeInputHandler}
                        pattern="\d*"
                        clearOnEdit={false}
                        inputmode="numeric"
                        required={true}
                        autofocus={true}
                        value={code}
                        onIonChange={e=>setCode(e.detail.value!)}
                      ></IonInput>
                      </IonItem>
                      {error && <h3>{error}</h3>}
            </IonCol>
            <IonCol size="12" className='ion-text-center'>
              <IonButton
                size="large"
                mode="ios"
                fill='outline'
                color="dark"
                shape='round'
                onClick={checkDiscountHandler}
                className="xl-button"
              >
                <IonText className="ion-text-wrap">
                  <p className='text-button'>PASSER COMMANDE</p>
                </IonText>
              </IonButton>
            </IonCol>
            <IonCol size="12" className='ion-text-center'>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default AddCartModal;
