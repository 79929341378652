import React, { useState, useContext } from "react";
import {
  IonItem,
  IonText,
  IonCol,
  IonIcon,
  IonButtons,
  IonButton,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCard,
  IonSlide,
  IonImg,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
import fContext from "../../context/f-context";
import FullScreenImage from "./FullScreenImage";
import IncludedList from "./IncludedList";
import s3 from "../../data/s3";
import ProgressiveImage from "react-progressive-graceful-image";
import CustomDescription from "../shared/CustomDescription";

const ProductDetailsDescription: React.FC<{
  moulding_name: string;
  moulding_style: string;
  moulding_description: string;
  art_by: string;
  out_w_cm: string;
  out_h_cm: string;
  scrollToDetails: () => void;
  showCustomPanel: () => void;
}> = (props) => {
  const [showDescription, setShowDescription] = useState<boolean>(
    isPlatform("desktop") ? true : false
  );
  const [showWhatsIncluded, setShowWhatsIncluded] = useState<boolean>(false);
  const fCtx = useContext(fContext);

  const [showFullScreenImage, setShowFullScreenImage] = useState<boolean>(
    false
  );
  const [imageFullScreenSrc, setImageFullScreenSrc] = useState<string>("");
  const [img1Spinner, setImg1Spinner] = useState<boolean>(true);
  const [img2Spinner, setImg2Spinner] = useState<boolean>(true);
  const [img3Spinner, setImg3Spinner] = useState<boolean>(true);
  const [img4Spinner, setImg4Spinner] = useState<boolean>(true);

  const showDescriptionHandler = () => {
    if (!showDescription) {
      props.scrollToDetails();
    }
    setShowDescription(!showDescription);
  };

  const showWhatsIncludedHandler = () => {
    if (showDescription && !showWhatsIncluded) {
      setShowDescription(!showDescription);
    }
    setShowWhatsIncluded(!showWhatsIncluded);
  };

  const showFullScreenImageHandler = (image_src: string) => {
    setImageFullScreenSrc(image_src);
    setShowFullScreenImage(true);
    return true;
  };

  const cancelShowFullScreenImage = () => {
    setShowFullScreenImage(false);
  };

  const scrollToBottom = () => {
    let list = document.querySelector("ion-content");
    return list && list.scrollByPoint(0, 100, 2);
  };

  return (
    <React.Fragment>
      <FullScreenImage
        image_src={imageFullScreenSrc}
        onCancel={cancelShowFullScreenImage}
        show={showFullScreenImage}
      />
      <IonText color="dark" className="ion-text-center">
        <h2 style={{ fontWeight: "bold", marginBottom: "2px" }}>
          {props.moulding_name}
        </h2>
        <p style={{ display: "block" }}>{props.moulding_style}</p>
      </IonText>
      {props.out_w_cm !== "" && props.out_h_cm !== "" ? (
        <IonText className="ion-text-center">
          <p style={{ color: "var(--ion-color-medium)", marginTop: "0px" }}>
            {props.out_w_cm}x{props.out_h_cm}cm
          </p>
        </IonText>
      ) : (
        <p className="ion-padding-bottom"></p>
      )}
      <IonItem
        button
        detail={false}
        color={"undefined"}
        lines="none"
        onClick={showDescriptionHandler}
        style={{
          borderBottom: "solid 1px var(--ion-color-light)",
          borderTop: "solid 1px var(--ion-color-light-shade)",
        }}
      >
        <IonText style={{ color: "var(--ion-color-medium)" }}>
          <p style={{ letterSpacing: "0.25rem" }}>DÉTAILS</p>
        </IonText>
        <IonButtons slot="end">
          <IonButton>
            <IonIcon icon={showDescription ? removeOutline : addOutline} />
          </IonButton>
        </IonButtons>
      </IonItem>
      <div className={showDescription ? "visible-item" : "non-visible-item"}>
        <IonItem color="light" lines="none">
          <IonText
            style={{ fontSize: "0.875rem", paddingTop: "16px", whiteSpace: "pre-line"  }}
            color="dark"
          >
            <p>{props.moulding_description}</p>
          </IonText>
        </IonItem>
        <IonGrid
          style={{ background: "var(--ion-color-light)" }}
          className="ion-no-padding"
        >
          <IonRow color="light">
            <IonCol size="12" color="light">
              {fCtx.articleId !== "" ? (
                <IonCard
                  mode="md"
                  style={{ background: "var(--ion-color-dark-contrast)" }}
                  className="card-no-shadow"
                >
                  <IonCardContent style={{ boxShadow: "none !important" }}>
                    <IonGrid className="ion-no-padding">
                      <IonRow>
                        <CustomDescription
                          artType={fCtx.artType}
                          mount={fCtx.mount}
                          matColor={fCtx.matColor}
                          matShape={fCtx.matShape}
                          matSize={Number(fCtx.matSize)}
                          out_h_cm={Number(props.out_h_cm)}
                          out_w_cm={Number(props.out_w_cm)}
                          wSelected={Number(fCtx.wSelected)}
                          hSelected={Number(fCtx.hSelected)}
                          showCustomButton={true}
                          onShowCustomPanelHandler={props.showCustomPanel}
                        />
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              ) : (
                <IonGrid className="ion-no-padding">
                  <IonRow style={{ paddingBottom: "16px" }}>
                    <IonCol size="12" className="ion-text-center">
                      <IonText color="primary">
                        <p className="text-stage">
                          QU'EST CE QU'ON ENCADRE AUJOURD'HUI?
                        </p>
                      </IonText>
                    </IonCol>
                    <IonCol size="4">
                      {" "}
                      <IonCard
                        className="ion-text-center"
                        button
                        //onClick={e=>selectArtTypeHandler('digital_photo')}
                        routerLink="/what-we-frame-today?art_type=digital_photo"
                        routerDirection="forward"
                        mode="md"
                      >
                        <IonImg
                          src="assets/img/home/digital_photo.png"
                          style={{
                            maxWidth: "90px",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                        <IonCardContent style={{ paddingTop: "0px" }}>
                          <IonText>
                            <p className="text-home-card">
                              PHOTO <br />
                              DIGITALE
                            </p>
                          </IonText>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size="4">
                      <IonCard
                        className="ion-text-center"
                        button
                        routerLink="/what-we-frame-today?art_type=art_on_paper"
                        routerDirection="forward"
                        mode="md"
                      >
                        <IonImg
                          src="assets/img/home/art_on_paper.png"
                          style={{
                            maxWidth: "90px",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                        <IonCardContent style={{ paddingTop: "0px" }}>
                          <IonText className="text-home-card">
                            <p className="text-home-card">
                              ART SUR <br /> PAPIER
                            </p>
                          </IonText>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size="4">
                      <IonCard
                        className="ion-text-center"
                        button
                        routerLink="/what-we-frame-today?art_type=document"
                        routerDirection="forward"
                        mode="md"
                      >
                        <IonImg
                          src="assets/img/home/document.png"
                          style={{
                            maxWidth: "90px",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                        <IonCardContent style={{ paddingTop: "0px" }}>
                          <IonText className="text-home-card">
                            <p className="text-home-card">
                              DOCUMENT
                              <br />& PHOTO
                            </p>
                          </IonText>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size="4">
                      <IonCard
                        className="ion-text-center"
                        button
                        routerLink="/what-we-frame-today?art_type=textil"
                        routerDirection="forward"
                        mode="md"
                      >
                        <IonImg
                          src="assets/img/home/jersey.png"
                          style={{
                            maxWidth: "90px",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                        <IonCardContent style={{ paddingTop: "0px" }}>
                          <IonText className="text-home-card">
                            <p className="text-home-card">
                              TEXTILE & <br />
                              MAILLOT
                            </p>
                          </IonText>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size="4">
                      {" "}
                      <IonCard
                        className="ion-text-center"
                        button
                        routerLink="/what-we-frame-today?art_type=canvas"
                        routerDirection="forward"
                        mode="md"
                      >
                        <IonImg
                          src="assets/img/home/canvas.png"
                          style={{
                            maxWidth: "90px",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                        <IonCardContent style={{ paddingTop: "0px" }}>
                          <IonText className="text-home-card">
                            <p className="text-home-card">
                              TABLEAU & <br />
                              TOILE
                            </p>
                          </IonText>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size="4">
                      <IonCard
                        className="ion-text-center"
                        button
                        routerLink="/what-we-frame-today?art_type=object"
                        routerDirection="forward"
                        mode="md"
                      >
                        <IonImg
                          src="assets/img/home/object.png"
                          style={{
                            maxWidth: "90px",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                        <IonCardContent style={{ paddingTop: "0px" }}>
                          <IonText className="text-home-card">
                            <p className="text-home-card">
                              OBJET EN <br />& CO.
                            </p>
                          </IonText>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      {isPlatform("mobile") && (
        <React.Fragment>
          <IonSlide
            className="ion-padding-bottom"
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "1.jpg"
              )
            }
          >
            {/*<IonImg
              src={
                s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "1.jpg"
              }
              onIonImgDidLoad={(e) => setImg1Spinner(false)}
            />
            {img1Spinner && <IonSpinner name="crescent" />}*/}
            <ProgressiveImage
              src={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "1.jpg"
              )}
              placeholder={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "1_tn.jpg"
              )}
            >
              {(src: string | undefined, loading: any) => (
                <img
                  style={{ width: "100%", filter: loading ? "blur(7px)" : "" }}
                  src={src}
                  alt="an image"
                />
              )}
            </ProgressiveImage>
          </IonSlide>
          <IonSlide
            className="ion-padding-bottom"
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "2.jpg"
              )
            }
          >
            {/*<IonImg
              src={
                s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "2.jpg"
              }
              onIonImgDidLoad={(e) => setImg2Spinner(false)}
            />
            {img2Spinner && <IonSpinner name="crescent" />}*/}
            <ProgressiveImage
              src={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "2.jpg"
              )}
              placeholder={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "2_tn.jpg"
              )}
            >
              {(src: string | undefined, loading: any) => (
                <img
                  style={{ width: "100%", filter: loading ? "blur(7px)" : "" }}
                  src={src}
                  alt="an image"
                />
              )}
            </ProgressiveImage>
          </IonSlide>
          <IonSlide
            className="ion-padding-bottom"
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "3.jpg"
              )
            }
          >
            {/*<IonImg
              src={
                s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "3.jpg"
              }
              onIonImgDidLoad={(e) => setImg3Spinner(false)}
            />
            {img3Spinner && <IonSpinner name="crescent" />}*/}
            <ProgressiveImage
              src={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "3.jpg"
              )}
              placeholder={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "3_tn.jpg"
              )}
            >
              {(src: string | undefined, loading: any) => (
                <img
                  style={{ width: "100%", filter: loading ? "blur(7px)" : "" }}
                  src={src}
                  alt="an image"
                />
              )}
            </ProgressiveImage>
          </IonSlide>
          <IonSlide
            className="ion-padding-bottom"
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "4.jpg"
              )
            }
          >
            {/*<IonImg
              src={
                s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "4.jpg"
              }
              onIonImgDidLoad={(e) => setImg4Spinner(false)}
            />
            {img4Spinner && <IonSpinner name="crescent" />}*/}
            <ProgressiveImage
              src={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "4.jpg"
              )}
              placeholder={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "4_tn.jpg"
              )}
            >
              {(src: string | undefined, loading: any) => (
                <img
                  style={{ width: "100%", filter: loading ? "blur(7px)" : "" }}
                  src={src}
                  alt="an image"
                />
              )}
            </ProgressiveImage>
          </IonSlide>
          <IonText className="ion-text-center">
            <p className="ion-padding">
              <i>{props.art_by}</i>
            </p>
          </IonText>
        </React.Fragment>
      )}
      {isPlatform("desktop") && (
        <React.Fragment>
          <IonImg
            src={
              s3 +
              "public/assets/img/" +
              props.moulding_name +
              "/" +
              props.moulding_name +
              "1.jpg"
            }
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "1.jpg"
              )
            }
            className="ion-padding-bottom"
            onIonImgDidLoad={(e) => setImg1Spinner(false)}
          />
          {img1Spinner && <IonSpinner name="crescent" />}
          <IonImg
            className="ion-padding-bottom"
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "2.jpg"
              )
            }
            src={
              s3 +
              "public/assets/img/" +
              props.moulding_name +
              "/" +
              props.moulding_name +
              "2.jpg"
            }
            onIonImgDidLoad={(e) => setImg2Spinner(false)}
          />
          {img2Spinner && <IonSpinner name="crescent" />}
          <IonImg
            className="ion-padding-bottom"
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "3.jpg"
              )
            }
            src={
              s3 +
              "public/assets/img/" +
              props.moulding_name +
              "/" +
              props.moulding_name +
              "3.jpg"
            }
            onIonImgDidLoad={(e) => setImg3Spinner(false)}
          />
          {img3Spinner && <IonSpinner name="crescent" />}
          <IonImg
            className="ion-padding-bottom"
            onClick={(e) =>
              showFullScreenImageHandler(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "4.jpg"
              )
            }
            src={
              s3 +
              "public/assets/img/" +
              props.moulding_name +
              "/" +
              props.moulding_name +
              "4.jpg"
            }
            onIonImgDidLoad={(e) => setImg4Spinner(false)}
          />
          {img4Spinner && <IonSpinner name="crescent" />}
        </React.Fragment>
      )}
      <IncludedList />
      {/*</div>*/}
    </React.Fragment>
  );
};

export default ProductDetailsDescription;
