import React, { useContext } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonIcon,
  IonButtons,
  IonGrid,
  IonRow,
  IonImg,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import fContext from "../../context/f-context";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const FullScreenImage: React.FC<{
  show: boolean;
  image_src: string;
  onCancel: () => void;
}> = props => {
  const fCtx = useContext(fContext);
  return (
    <IonModal isOpen={props.show} cssClass={/*"transparent"*/''}>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar color="undefined">
          <IonButtons slot="end">
            <IonButton onClick={props.onCancel}>
              <IonIcon size="large" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={/*"transparent"*/''} onClick={props.onCancel}>
        <IonGrid className="ion-full-height">
          <IonRow class="ion-align-items-center ion-full-height">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              <TransformComponent>
                <IonImg style={{margin:'auto'}} src={props.image_src}/>
              </TransformComponent>
            </TransformWrapper>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default FullScreenImage;
