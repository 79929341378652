import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonRouterLink,
} from "@ionic/react";
import ProgressiveImage from "react-progressive-graceful-image";

const FrameDisplay: React.FC<{onChange: () => {}}> = props => {
  const gallery_title = "Les cadres galeries – simplicité et modernité";
  const gallery_p1 =
    "Plébiscités par les galeristes et artistes, nos cadres galeries allient des profils carrés minimalistes avec des couleurs sobres pour un rendu toujours au top. Disponibles en noir mat, blanc mat et bois massifs. Vous pouvez y aller les yeux fermés.";
  const gallery_p2 =
    "";
  const raw_title = "Les essences bruts - chaleur et \nnaturel";
  const raw_p1 = "Nos cadres en bois massif sont des incontournables de notre collection alliant esthétisme, modernisme et naturel. Nous vous proposons plusieurs types d’essences naturelles toutes issues de filières écologiques : chêne, cerisier et noyer. Pour rien ne vous cacher, nous on les adore.";
  const raw_p2 = "";
  const vintage_title = "Les vintages - pour un style rétro et chic assumé";
  const vintage_p1 = "Une sélection de cadres rétros indémodables qui ont su traverser le temps pour un effet trendy et chic dans votre intérieur. Dorures, ornements, patines, bois de loupe et tutti quanti, c’est par ici.";
  const vintage_p2 = "";
  const canvas_title = "Les caisses américaines - esthétisme et minimalisme";
  const canvas_p1 = "Une sélection de caisses américaines aux styles et profils variés (caisse en U, en L, en escalier etc.), pour des encadrements minimalistes toujours réussis de vos toiles, photos ou lithographies.";
  const canvas_p2 = " ";
  const shiny_title = "Les brillants - en quête de \nscintillement ";
  const shiny_p1 = "Découvrez notre sélection de cadres brillants et aux formes variées qui trouveront leur place dans tous les intérieurs. Il y en a pour tous les goûts.";
  const shiny_p2 = "";
  const collection_title = "Les Lamas Collections - pour faire bande à part";
  const collection_p1 = "Les Lama Collections sont une sélection de cadres ayant bénéficié d’un travail et de finitions exceptionnels. Ils sont disponibles à un coût supplémentaire.";
  const collection_p2 = "";

  const [titleMount, setTitleMount] = useState<string>(gallery_title);
  const [p1Mount, setP1Mount] = useState<string>(gallery_p1);
  const [p2Mount, setP2Mount] = useState<string>(gallery_p1);
  const [style, setStyle] = useState<string>("gallery");

  const changeMountHandler = (mountType: string) => {
    switch (mountType) {
      case "gallery": {
        setStyle("gallery");
        setTitleMount(gallery_title);
        setP1Mount(gallery_p1);
        setP2Mount(gallery_p2);
        break;
      }
      case "raw": {
        setStyle("raw");
        setTitleMount(raw_title);
        setP1Mount(raw_p1);
        setP2Mount(raw_p2);
        break;
      }
      case "vintage": {
        setStyle("vintage");
        setTitleMount(vintage_title);
        setP1Mount(vintage_p1);
        setP2Mount(vintage_p2);
        break;
      }
      case "canvas": {
        setStyle("canvas");
        setTitleMount(canvas_title);
        setP1Mount(canvas_p1);
        setP2Mount(canvas_p2);
        break;
      }
      case "shiny": {
        setStyle("shiny");
        setTitleMount(shiny_title);
        setP1Mount(shiny_p1);
        setP2Mount(shiny_p2);
        break;
      }
      case "collection": {
        setStyle("collection");
        setTitleMount(collection_title);
        setP1Mount(collection_p1);
        setP2Mount(collection_p2);
        break;
      }
      default: {
        setTitleMount(gallery_title);
        setP1Mount(gallery_p1);
        setP2Mount(gallery_p2);
        break;
      }
    }
    props.onChange();
  };
  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
      <ProgressiveImage
      delay={350}
          src={"assets/img/style/" + style + ".jpg"}
          placeholder={"assets/img/style/" + style + "_tn.jpg"}
        >
          {(src: string | undefined, loading: any) => (
            <IonCol
              size="12"
              size-md="0"
              className="y-align image-cover"
              style={{
                minHeight: "300px",
                backgroundImage: "url('" + src + "')",
                filter: loading ? "blur(15px)" : "",
              }}
            ></IonCol>
          )}
        </ProgressiveImage>
        <IonCol size='12' size-md="5.5" className='ion-padding-bottom'>
          <div style={{minHeight:'210px'}}>
          <IonText>
            <h2 style={{ display: "block", whiteSpace: "pre-line" }}>{titleMount}</h2>
            <p style={{ display: "block" }}>{p1Mount}</p>
            <p style={{ display: "block" }}>Pour découvrir tous nos cadres, c’est par <IonRouterLink routerLink='/gallery' routerDirection='forward'>ici.</IonRouterLink></p>
          </IonText>
          </div>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={style === "gallery" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("gallery")}
          >
            <p className="text-guide-button">LES GALLERIES</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={style === "raw" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("raw")}
          >
            <p className="text-guide-button">LES ESSENCES BRUTES</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={style === "vintage" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("vintage")}
          >
            <p className="text-guide-button">LES VINTAGES</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={style === "shiny" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("shiny")}
          >
            <p className="text-guide-button">LES BRILLANTS</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={style === "collection" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("collection")}
          >
            <p className="text-guide-button">LES COLLECTIONS</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={style === "canvas" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changeMountHandler("canvas")}
          >
            <p className="text-guide-button">LES CAISSES AMÉRICAINES</p>
          </IonButton>
        </IonCol>
        <ProgressiveImage
        delay={350}
          src={"assets/img/style/" + style + ".jpg"}
          placeholder={"assets/img/style/" + style + "_tn.jpg"}
        >
          {(src: string | undefined, loading: any) => (
            <IonCol
              size="0"
              size-md="5.5"
              offset-md='1'
              className="y-align image-cover"
              style={{
                minHeight: "480px",
                backgroundImage: "url('" + src + "')",
                filter: loading ? "blur(15px)" : "",
              }}
            ></IonCol>
          )}
        </ProgressiveImage>
      </IonRow>
    </IonGrid>
  );
};

export default FrameDisplay;
