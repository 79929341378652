import React, { useContext, useRef, useState } from "react";
import {
  IonPage,
  IonButton,
  IonContent,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonList,
  IonLabel,
  IonInput,
} from "@ionic/react";
import AuthContext from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import FooterRows from "../../components/shared/FooterRows";

// set the default values for the controls
let initialValues = {
  name: "",
  email: "",
  password: "",
};
let renderCount = 0;

const Register: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const emailInput = useRef<HTMLIonInputElement>(null);
  const nameInput = useRef<HTMLIonInputElement>(null);
  const passwordInput = useRef<HTMLIonInputElement>(null);

  const { control, handleSubmit, formState, reset, errors } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });

  const [data, setData] = useState();
  renderCount++;

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    alert(JSON.stringify(data, null, 2));
    setData(data);
  };

  const authSubmitHandler = async (data: any) => {
    //event.preventDefault();
    try {
      const formData = new FormData();
      /*const manualData = JSON.stringify({
        email: emailInput.current!.value!.toString(),
        password: passwordInput.current!.value!.toString(),
        name: nameInput.current!.value!.toString()
      });*/

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL+"api/users/signup",
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
        }
      );

      authCtx.login(responseData.userId, responseData.token);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <DesktopToolbar />
      </IonHeader>
      <IonContent scrollY={true} fullscreen={true}>
        <IonGrid className="ion-no-padding">
          <form onSubmit={handleSubmit((data) => authSubmitHandler(data))}>
            <IonRow className="ion-align-items-start" style={{minHeight:'calc(100vh - 150px)'}}>
              <IonCol
                size="12"
                offset-md="3"
                size-md="6"
                offset-xl="4"
                size-xl="4"
                class="ion-text-left ion-padding-start"
              >
                <IonText color="dark">
                  <h1>
                    <strong>Mon compte</strong>
                  </h1>
                </IonText>
              </IonCol>
              <IonCol
                size="12"
                offset-md="3"
                size-md="6"
                offset-xl="4"
                size-xl="4"
              >
                <IonList>
                  <IonItem>
                    <IonLabel className="ion-text-wrap" position="floating">
                      Votre nom
                    </IonLabel>
                    {/*<IonInput
                      type="text"
                      name="name"
                      id="name"
                      onIonChange={e => {}}
                      inputmode="text"
                      required={true}
                      color="dark"
                      autocomplete="on"
                      autoCorrect="on"
                      ref={nameInput}
                    ></IonInput>*/}
                    <Controller
                      render={({ onChange, onBlur, value }) => (
                        <IonInput
                          type="text"
                          inputmode="text"
                          autocomplete="on"
                          autocorrect="on"
                          onIonChange={onChange}
                        />
                      )}
                      control={control}
                      name="name"
                      rules={{
                        required: true,
                        minLength: { value: 2, message: "nom trop court" },
                        maxLength: { value: 50, message: "nom trop long" },
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      as={<div style={{ color: "red" }} />}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel className="ion-text-wrap" position="floating">
                      Votre adresse e-mail
                    </IonLabel>
                    {/*<IonInput
                    type="email"
                    name="email"
                    id="email"
                    ref={emailInput}
                    onIonChange={e => {}}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    inputmode="email"
                    required={true}
                    color="dark"
                    autocomplete="on"
                    autoCorrect="on"
                  ></IonInput>
                  */}
                    <Controller
                      render={({ onChange, onBlur, value }) => (
                        <IonInput
                          type="email"
                          inputmode="email"
                          autocomplete="on"
                          autocorrect="on"
                          onIonChange={onChange}
                        />
                      )}
                      control={control}
                      name="email"
                      rules={{
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "adresse e-mail invalide",
                        },
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      as={<div style={{ color: "red" }} />}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel className="ion-text-wrap" position="floating">
                      Votre mot de passe
                    </IonLabel>
                    {/*<IonInput
                      type="password"
                      name="password"
                      id="password"
                      onIonChange={e => {}}
                      inputmode="text"
                      required={true}
                      color="dark"
                      autocomplete="on"
                      autoCorrect="on"
                      ref={passwordInput}
                    ></IonInput>*/}
                    <Controller
                      render={({ onChange, onBlur, value }) => (
                        <IonInput
                          type="password"
                          inputmode="text"
                          autocomplete="on"
                          autocorrect="on"
                          onIonChange={onChange}
                        />
                      )}
                      control={control}
                      name="password"
                      rules={{
                        required: true,
                        minLength: {
                          value: 6,
                          message: "6 caracteres minimum",
                        },
                        maxLength: {
                          value: 50,
                          message: "mot de passe trop long trop long",
                        },
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="password"
                      as={<div style={{ color: "red" }} />}
                    />
                  </IonItem>
                </IonList>
              </IonCol>
              <IonCol
                size="12"
                offset-md="3"
                size-md="6"
                offset-xl="4"
                size-xl="4"
                className="ion-padding-top"
              >
                <IonButton type="submit" mode="ios" expand="full" color="dark">
                  <IonLabel>S'inscrire</IonLabel>
                </IonButton>
              </IonCol>
            </IonRow>
            <FooterRows/>
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;
