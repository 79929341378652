import React, { useContext, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  isPlatform,
  IonImg,
  IonIcon,
} from "@ionic/react";
import fContext from "../../context/f-context";
import PriceContext from "../../context/price-context";
import AuthContext from "../../context/auth-context";
import s3 from "../../data/s3";
import {
  addOutline,
  informationCircleOutline,
  removeOutline,
} from "ionicons/icons";

const CustomPanel: React.FC<{
  loadedMoulding: any;
  products: any;
  selectedProductName: string;
  changeMouldingHandler: (moulding_name: string | undefined) => void;
  showCustomPanelHandler: () => void;
  showInformartionModalHandler: () => void;
}> = (props) => {
  const fCtx = useContext(fContext);
  const priceCtx = useContext(PriceContext);
  const authCtx = useContext(AuthContext);
  const isOldEiffageFormat = authCtx.code
    ?.split("")
    .every(
      (character: string) => !"AZERTYUIOPQSDFGHJKLMWXCVBN".includes(character)
    );
  const [showMouldings, setShowMouldings] = useState<boolean>(
    !isOldEiffageFormat
  );

  const changeMatStyleHandler = (mat_style: string | undefined) => {
    fCtx.selectSelectedStyle(mat_style ? mat_style : "standard");
    if (mat_style === "nomat") {
      fCtx.selectMatStyle("standard");
      fCtx.selectMatShape("nomat");
      fCtx.selectMatSize(0);
    } else if (mat_style === "thin") {
      fCtx.selectMatStyle("standard");
      fCtx.selectMatShape("thin");
      fCtx.selectMatSize(4);
    } else if (mat_style === "standard") {
      fCtx.selectMatStyle("standard");
      fCtx.selectMatShape("standard");
      fCtx.selectMatSize(8);
    } else if (mat_style === "oversized") {
      fCtx.selectMatStyle("standard");
      fCtx.selectMatShape("oversized");
      fCtx.selectMatSize(16);
    } else if (mat_style === "italiano") {
      fCtx.selectMatStyle("italiano");
      fCtx.selectMatShape("italiano");
      fCtx.selectMatSize(8);
    }
  };

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        {fCtx.mount !== "canvas" && isOldEiffageFormat && (
          <React.Fragment>
            <IonCol size="12" class="ion-text-center y-align">
              {/*<IonListHeader className="ion-no-padding" mode="ios">*/}
              <IonText className="text-custom-section">
                <p style={{ display: "inline-block" }}>MONTAGE</p>
              </IonText>
              <div
                style={{
                  position: "absolute",
                  display: "inline-block",
                  marginBottom: "0.75rem",
                  marginLeft: "7rem",
                }}
              >
                <IonButton
                  className="ion-no-margin"
                  mode="ios"
                  size="large"
                  fill="clear"
                  color="primary"
                  slot="icon-only"
                  onClick={props.showInformartionModalHandler}
                  style={{ display: "inline-block" }}
                >
                  <IonIcon icon={informationCircleOutline} />
                </IonButton>
              </div>
              {/*</IonListHeader>*/}
            </IonCol>
            <IonCol offset="1.5" size="9" class="ion-text-center">
              <IonSegment
                scrollable={true}
                style={{
                  width: "100%",
                  "justify-content": "center",
                  "overflow-x": "visible",
                }}
                mode="ios"
                value={fCtx.mount}
                color="dark"
                onIonChange={(e) =>
                  fCtx.selectMount(e.detail.value ? e.detail.value : "simple")
                }
                //disabled={!showBuy}
              >
                <IonSegmentButton value="simple">
                  <IonLabel>
                    <p style={{ fontWeight: "normal" }}>Classique</p>
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  disabled={props.loadedMoulding.floating_mount === false}
                  value="floating"
                >
                  <IonLabel
                    className="ion-text-wrap"
                    style={{ fontWeight: "normal" }}
                  >
                    Flottant
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  disabled={props.loadedMoulding.floating_mount === false}
                  value="box"
                >
                  <IonLabel
                    className="ion-text-wrap"
                    style={{ fontWeight: "normal" }}
                  >
                    Profond
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
            <IonCol
              size="12"
              className="ion-padding-top ion-text-center"
              style={{ paddingTop: "32px" }}
            >
              <IonText className="text-custom-section">
                <p>PASSE-PARTOUT</p>
              </IonText>
            </IonCol>
            <IonCol size="11" offset="0.5" class="ion-text-center">
              <IonSegment
                scrollable={true}
                mode="ios"
                value={fCtx.selectedStyle}
                color="light"
                onIonChange={(e) => changeMatStyleHandler(e.detail.value)}
                className="ion-padding-start ion-padding-end transparent"
                style={{
                  width: "100%",
                  "justify-content": "center",
                  "overflow-x": "visible",
                }}
                //disabled={!showBuy}
              >
                {(fCtx.mount === "simple" || fCtx.mount === "box") && (
                  <IonSegmentButton value="nomat">
                    <IonImg
                      style={{ margin: "auto" }}
                      src="assets/img/mat/nomat.png"
                      alt="No mat mounting"
                    />
                    <IonLabel style={{ fontWeight: "normal" }}>Aucun</IonLabel>
                  </IonSegmentButton>
                )}
                <IonSegmentButton value="thin">
                  <IonImg
                    style={{ margin: "auto" }}
                    src="assets/img/mat/thin.png"
                    alt="Thin mat mounting"
                  />
                  <IonLabel style={{ fontWeight: "normal" }}>Fin</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="standard">
                  <IonImg
                    style={{ margin: "auto" }}
                    src="assets/img/mat/standard.png"
                    alt="Standard mat mounting"
                  />
                  <IonLabel style={{ fontWeight: "normal" }}>Standard</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="oversized">
                  <IonImg
                    style={{ margin: "auto" }}
                    src="assets/img/mat/oversized.png"
                    alt="Oversized mat mounting"
                  />
                  <IonLabel
                    style={{ fontWeight: "normal" }}
                    className="ion-text-wrap"
                  >
                    Large{" "}
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="italiano">
                  <IonImg
                    style={{ margin: "auto" }}
                    src="assets/img/mat/italian.png"
                    alt="Italiano mat mounting"
                  />
                  <IonLabel
                    style={{ fontWeight: "normal" }}
                    className="ion-text-wrap"
                  >
                    Italiano{" "}
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
            {fCtx.matSize != 0 && (
              <React.Fragment>
                <IonCol
                  size="12"
                  className="ion-padding-top ion-text-center"
                  style={{ paddingTop: "32px" }}
                >
                  <IonText className="text-custom-section">
                    <p>COULEUR</p>
                  </IonText>
                </IonCol>
                <IonCol size="2" class="ion-text-center  ">
                  <label className="extra-white">
                    <input
                      type="radio"
                      name="color"
                      value="white"
                      id="white"
                      onClick={(event) => fCtx.selectMatColor("#FEFEFE")}
                      checked={fCtx.matColor === "#FEFEFE" ? true : false}
                    />
                    <div className="buttonC">
                      <span></span>
                    </div>
                  </label>
                </IonCol>
                <IonCol size="2" class="ion-text-center">
                  <label className="night-black">
                    <input
                      type="radio"
                      name="color"
                      value="black"
                      id="black"
                      onClick={(event) => fCtx.selectMatColor("#16160D")}
                      checked={fCtx.matColor === "#16160D" ? true : false}
                    />
                    <div className="buttonC">
                      <span></span>
                    </div>
                  </label>
                </IonCol>
                <IonCol size="2" class="ion-text-center">
                  <label className="cream-beige">
                    <input
                      type="radio"
                      name="color"
                      value="beige"
                      id="beige"
                      onClick={(event) => fCtx.selectMatColor("#FFFDE0")}
                      checked={fCtx.matColor === "#FFFDE0" ? true : false}
                    />
                    <div className="buttonC">
                      <span></span>
                    </div>
                  </label>
                </IonCol>
                <IonCol size="2" class="ion-text-center">
                  <label className="sky-blue">
                    <input
                      type="radio"
                      name="color"
                      value="blue"
                      id="blue"
                      onClick={(event) => fCtx.selectMatColor("#CBE1E8")}
                      checked={fCtx.matColor === "#CBE1E8" ? true : false}
                    />
                    <div className="buttonC">
                      <span></span>
                    </div>
                  </label>
                </IonCol>
                <IonCol size="2" class="ion-text-center">
                  <label className="midnight-blue">
                    <input
                      type="radio"
                      name="color"
                      value="blue"
                      id="blue"
                      onClick={(event) => fCtx.selectMatColor("#293562")}
                      checked={fCtx.matColor === "#293562" ? true : false}
                    />
                    <div className="buttonC">
                      <span></span>
                    </div>
                  </label>
                </IonCol>
                <IonCol size="2" class="ion-text-center">
                  <label className="nymph-pink">
                    <input
                      type="radio"
                      name="color"
                      value="pink"
                      id="pink"
                      onClick={(event) => fCtx.selectMatColor("#F9E6E5")}
                      checked={fCtx.matColor === "#F9E6E5" ? true : false}
                    />
                    <div className="buttonC">
                      <span></span>
                    </div>
                  </label>
                </IonCol>
                <IonCol
                  size="2"
                  class="ion-text-center"
                  //style={{ paddingBottom: "55px" }}
                >
                  <label className="amand-green">
                    <input
                      type="radio"
                      name="color"
                      value="amand-green"
                      id="yellow"
                      onClick={(event) => fCtx.selectMatColor("#BDCEA2")}
                      checked={fCtx.matColor === "#BDCEA2" ? true : false}
                    />
                    <div className="buttonC">
                      <span></span>
                    </div>
                  </label>
                </IonCol>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <IonCol size="12" className="ion-padding-top ion-text-center">
          <IonButton
            color="dark"
            mode="ios"
            fill="clear"
            onClick={(e) => setShowMouldings(!showMouldings)}
          >
            <IonText>
              <p style={{ display: "inline-block" }} className="">
                CHANGER MON CADRE &nbsp;
              </p>
              <IonIcon
                color="dark"
                style={{ verticalAlign: "text-bottom" }}
                size="small"
                icon={showMouldings ? removeOutline : addOutline}
              />
            </IonText>
          </IonButton>
        </IonCol>
        {showMouldings && (
          <React.Fragment>
            {isPlatform("mobile") ? (
              <IonCol
                size="12"
                className=" ion-padding-bottom"
                //style={{ paddingBottom: "55px" }}
              >
                <IonSegment
                  scrollable={true}
                  mode="ios"
                  value={props.selectedProductName}
                  color="light"
                  onIonChange={(e) =>
                    props.changeMouldingHandler(e.detail.value)
                  }
                  className="ion-padding-start ion-padding-end transparent"
                  //disabled={!showBuy}
                >
                  {props.products.map(
                    (product: {
                      name: string | undefined | undefined;
                      collection_moulding: any;
                    }) => (
                      <IonSegmentButton
                        style={{
                          height: "140px",
                          width: "100%",
                        }}
                        value={product.name}
                        className=" ion-padding-end"
                      >
                        <div
                          style={{
                            height: "75px",
                            width: "75px",
                            borderRadius: "100%",
                            marginBottom: "12px",
                            marginTop: "12px",
                          }}
                        >
                          <IonImg
                            className="rotate"
                            style={{
                              position: "relative",
                              top: "40%",
                              margin: "auto",
                            }}
                            src={
                              s3 + "public/assets/img/" + product.name + ".jpg"
                            }
                          />
                        </div>
                        <IonLabel
                          style={{
                            lineHeight: "1.1",
                            fontWeight: "normal",
                          }}
                          className="ion-text-wrap"
                        >
                          {product.name}
                        </IonLabel>
                        {product.collection_moulding && (
                          <IonLabel
                            className="ion-text-wrap"
                            color="warning"
                            style={{
                              display: "block",
                              paddingTop: "5px",
                              fontSize: "10px",
                            }}
                          >
                            COLLECTION{" "}
                          </IonLabel>
                        )}
                      </IonSegmentButton>
                    )
                  )}
                </IonSegment>
              </IonCol>
            ) : (
              <IonCol
                size="12"
                className=" ion-padding-bottom"
                //style={{ paddingBottom: "55px" }}
                style={{
                  borderBottom: "solid 1px var(--ion-color-light-shade)",
                }}
              >
                <IonSegment
                  scrollable={true}
                  mode="ios"
                  value={props.selectedProductName}
                  color="light"
                  onIonChange={(e) =>
                    props.changeMouldingHandler(e.detail.value)
                  }
                  className="ion-padding-start ion-padding-end transparent"
                  //disabled={!showBuy}
                >
                  <IonGrid className="ion-no-padding">
                    <IonRow>
                      {props.products.map(
                        (product: {
                          name: string | undefined;
                          collection_moulding: any;
                        }) => (
                          <IonCol size="3" className="ion-padding-bottom">
                            <IonSegmentButton
                              style={{
                                height: "140px",
                                width: "100%",
                              }}
                              value={product.name}
                            >
                              <div
                                style={{
                                  height: "75px",
                                  width: "75px",
                                  marginBottom: "12px",
                                  marginTop: "12px",
                                  borderRadius: "100%",
                                  background:
                                    "url('" +
                                    s3 +
                                    "public/assets/img/" +
                                    product.name +
                                    ".jpg" +
                                    "') no-repeat 52% center/cover",
                                  backgroundSize: "cover",
                                }}
                              >
                                <IonImg
                                  hidden
                                  className="rotate"
                                  style={{
                                    position: "relative",
                                    top: "40%",
                                    margin: "auto",
                                  }}
                                  src={
                                    s3 +
                                    "public/assets/img/" +
                                    product.name +
                                    ".jpg"
                                  }
                                />
                              </div>
                              <IonLabel
                                style={{
                                  lineHeight: "1.1",
                                  fontWeight: "normal",
                                }}
                                className="ion-text-wrap"
                              >
                                {product.name}
                              </IonLabel>
                              {product.collection_moulding && (
                                <IonLabel
                                  className="ion-text-wrap"
                                  color="warning"
                                  style={{
                                    display: "block",
                                    paddingTop: "5px",
                                    fontSize: "11px",
                                  }}
                                >
                                  COLLECTION{" "}
                                </IonLabel>
                              )}
                            </IonSegmentButton>
                          </IonCol>
                        )
                      )}
                    </IonRow>
                  </IonGrid>
                </IonSegment>
              </IonCol>
            )}
          </React.Fragment>
        )}

        {/*isPlatform("mobile") ? (
          <IonCol
            size="12"
            className="ion-no-padding ion-padding-bottom"
            style={{ paddingBottom: "64px" }}
          >
            <IonButton
              mode="ios"
              onClick={props.showCustomPanelHandler}
              expand="full"
              size="large"
              color="dark"
              className="ion-no-margin"
              shape="round"
            >
              <IonText>
                <p className="text-button">FERMER</p>
              </IonText>
            </IonButton>
          </IonCol>
        ) : (
          <IonCol
            className="ion-text-center ion-padding-bottom ion-padding-top ion-margin-top"
            size="12"
          >
            <IonButton
              mode="ios"
              onClick={props.showCustomPanelHandler}
              style={{ width: "350px" }}
              color="dark"
              fill="outline"
              className="text-button"
            >
              FERMER
            </IonButton>
          </IonCol>
        )*/}
      </IonRow>
    </IonGrid>
  );
};

export default CustomPanel;
