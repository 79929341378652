import React from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonIcon,
} from "@ionic/react";

import ProgressiveImage from "react-progressive-graceful-image";
import { closeOutline } from "ionicons/icons";

const MountInformationModal: React.FC<{
  show: boolean;
  onDismiss: () => void;
}> = (props) => {
  return (
    <IonModal isOpen={props.show} backdropDismiss={false}>
      <IonHeader className="ion-no-border">
        <IonToolbar color="undefined" className="">
          <IonButtons slot="end"><IonButton size='large' fill='clear' onClick={e=>props.onDismiss()}><IonIcon icon={closeOutline}/></IonButton></IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="undefined">
        <IonGrid>
          <IonRow>
            <IonCol size="12" className="">
              <IonText color="dark">
                <h2>Le montage classique - une valeur sûre</h2>
                <p>
                  Votre œuvre sera soit séparée du verre de présentation par un
                  passe-partout soit directement à son contact si vous optez
                  pour un montage sans passe-partout. Sans surprise, c’est le
                  montage le plus connu du public.{" "}
                </p>
              </IonText>
              <ProgressiveImage
                      src={"assets/img/mount-info/classic.jpg"}
                      placeholder={
                        "assets/img/mount-info/classic_tn.jpg"
                      }
                    >
                      {(src: string | undefined, loading: any) => (
                        <img
                          style={{
                            width: "1000px",
                            maxWidth: "100% !important",
                            filter: loading ? "blur(7px)" : "",
                          }}
                          src={src}
                          alt="an image"
                        />
                      )}
                    </ProgressiveImage>
            </IonCol>
            <IonCol size="12" className="">
              <IonText color="dark">
                <h2>Le montage flottant - saisissant et sophistiqué</h2>
                <p>
                Votre œuvre est ici surélevée de quelques minimètres au-dessus du carton de fond de manière à créer un espace entre le verre, l’œuvre et le fond de votre cadre. Effet waouh garanti.
                </p>
              </IonText>
              <ProgressiveImage
                      src={"assets/img/mount-info/floating.jpg"}
                      placeholder={
                        "assets/img/mount-info/floating_tn.jpg"
                      }
                    >
                      {(src: string | undefined, loading: any) => (
                        <img
                          style={{
                            width: "1000px",
                            maxWidth: "100% !important",
                            filter: loading ? "blur(7px)" : "",
                          }}
                          src={src}
                          alt="an image"
                        />
                      )}
                    </ProgressiveImage>
            </IonCol>
            <IonCol size="12" className="">
              <IonText color="dark">
                <h2>Le montage profond – moderne et épuré</h2>
                <p>
                Nous éloignons ici votre oeuvre de la vitre de présentation afin de donner une sensation de profondeur intense à votre encadrement. C’est le montage le plus plébiscité par les galeries.
                </p>
              </IonText>
              <ProgressiveImage
                      src={"assets/img/mount-info/box.jpg"}
                      placeholder={
                        "assets/img/mount-info/box_tn.jpg"
                      }
                    >
                      {(src: string | undefined, loading: any) => (
                        <img
                          style={{
                            width: "1000px",
                            maxWidth: "100% !important",
                            filter: loading ? "blur(7px)" : "",
                          }}
                          src={src}
                          alt="an image"
                        />
                      )}
                    </ProgressiveImage>
            </IonCol>
            <IonCol size="12" className="ion-text-center">
              <IonButton
                size="large"
                mode="ios"
                fill="outline"
                color="dark"
                shape="round"
                onClick={e=>props.onDismiss()}
                className="xl-button"
              >
                <IonText className="ion-text-wrap">
                  <p className="text-button">FERMER</p>
                </IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default MountInformationModal;
