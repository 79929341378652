import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonButton,
  IonRouterLink,
} from "@ionic/react";
import { getDivMulti } from "../../util/frame";
import FramePreview from "../shared/FramePreview";

const MatDisplay: React.FC<{ onChange: () => {} }> = (props) => {
  const standard_title = "Le standard - toujours efficace";
  const standard_p1 =
    "Idéal si vous êtes à la recherche d'une disposition classique et harmonieuse sans prendre de risques. Cette solution conviendra à la majorité des sujets et nous appliquerons une marge d'environ 8cm sur les quatre marges de votre passe-partout. ";
  const standard_p2 = "";
  const standard_artwork = "Tous types d'œuvres";
  const standard_dim = "Toutes dimensions";
  const thin_title = "Le fin - pour une disposition épurée";
  const thin_p1 =
    "Ce style ravira les puristes en quête d'un style plus minimaliste. Nous conseillons tout particulièrement ce passe-partout pour les affiches, posters et tirages photos de grandes tailles. ";
  const thin_p2 = "";
  const thin_artwork = "Photos, Affiches et Posters";
  const thin_dim = "Œuvres de grandes dimensions";
  const oversized_title = "L'extra-large - notre chouchou";
  const oversized_p1 =
    "Ultra tendance et très prisé des décorateurs d'intérieur, le passe-partout extra-large est idéal si vous souhaitez donner à votre Lama un style moderne et sophistiqué. Il est particulièrement bien adapté aux œuvres carrées et pour les sujets de petites tailles. Essayez-le vous ne serez pas déçu(e).";
  const oversized_p2 = "";
  const oversized_artwork = "Œuvres sur papier et photos";
  const oversized_dim = "Petites et moyennes dimensions”";
  const italiano_title = "L'italiano - pour un effet galerie à la maison";
  const italiano_p1 =
    "Le style très plébiscité pour les expositions de photos dans les galeries. Le passe-partout italiano donne une valeur toute particulière au sujet, avec une marge inférieure plus large que les autres plaçant ainsi le sujet à la hauteur du regard. Habile.";
  const italiano_p2 = "";
  const italiano_artwork = "Tirage photo";
  const italiano_dim = "Toutes dimensions";
  const nomat_title = "L'absent - ou aucun passe-partout";
  const nomat_p1 =
    "Ce style est tout particulièrement adapté aux sujets de grandes tailles ou bien pour les sujets possédant déjà une marge tels que les lithographies, les gravures, les estampes etc.";
  const nomat_p2 = "";
  const nomat_artwork = "Œuvres sur papier et photo";
  const nomat_dim = "Œuvres de grandes dimensions";

  const [titlemat, setTitlemat] = useState<string>(standard_title);
  const [p1mat, setP1mat] = useState<string>(standard_p1);
  const [p2mat, setP2mat] = useState<string>(standard_p2);
  const [mat, setmat] = useState<string>("standard");
  const [matStyle, setMatStyle] = useState<string>("standard");
  const [matSize, setMatSize] = useState<number>(8);
  const [artType, setArtType] = useState<string>(standard_artwork);
  const [dim, setDim] = useState<string>(standard_dim);

  const changematHandler = (matType: string) => {
    switch (matType) {
      case "standard": {
        setmat("standard");
        setMatStyle("standard");
        setMatSize(8);
        setTitlemat(standard_title);
        setP1mat(standard_p1);
        setP2mat(standard_p2);
        setArtType(standard_artwork);
        setDim(standard_dim);
        break;
      }
      case "thin": {
        setmat("thin");
        setMatStyle("standard");
        setMatSize(4);
        setTitlemat(thin_title);
        setP1mat(thin_p1);
        setP2mat(thin_p2);
        setArtType(thin_artwork);
        setDim(thin_dim);
        break;
      }
      case "oversized": {
        setmat("oversized");
        setMatStyle("standard");
        setMatSize(16);
        setTitlemat(oversized_title);
        setP1mat(oversized_p1);
        setP2mat(oversized_p2);
        setArtType(oversized_artwork);
        setDim(oversized_dim);
        break;
      }
      case "italiano": {
        setmat("italiano");
        setMatStyle("italiano");
        setMatSize(8);
        setTitlemat(italiano_title);
        setP1mat(italiano_p1);
        setP2mat(italiano_p2);
        setArtType(italiano_artwork);
        setDim(italiano_dim);
        break;
      }
      case "nomat": {
        setmat("nomat");
        setMatStyle("standard");
        setMatSize(0);
        setTitlemat(nomat_title);
        setP1mat(nomat_p1);
        setP2mat(nomat_p2);
        setArtType(nomat_artwork);
        setDim(nomat_dim);
        break;
      }
      default: {
        setmat("standard");
        setMatStyle("standard");
        setMatSize(8);
        setTitlemat(standard_title);
        setP1mat(standard_p1);
        setP2mat(standard_p2);
        setArtType(standard_artwork);
        setDim(standard_dim);
        break;
      }
    }
    props.onChange();
  };

  var art_w_cm = Number(35); //width art in cm
  var art_w = Number(3942); //width art in px
  var art_h = Number(2958); //height art in px
  var biseau_w_cm = 0.18; // biseau width cm

  var mouldingWidth = 4.2; //moulding width in cm

  var scaling =
    (Number(20) + 2 * matSize + 2 * Number(mouldingWidth)) /
    (1.1 * (Number(20) + 2 * 16 + 2 * Number(mouldingWidth)));

  const div = getDivMulti(
    art_w,
    art_h,
    matStyle,
    matSize,
    art_w_cm,
    mouldingWidth,
    biseau_w_cm
  );
  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol size="12" size-md="5.5">
          <FramePreview
            art_bottom={div.art_bottom.toString()}
            art_left={div.art_left.toString()}
            art_top={div.art_top.toString()}
            frameToMatPct={div.frameToMatPct.toString()}
            img_name={"Obey (chene).jpg".toString()}
            mouldingHeight={div.mouldingHeight.toString()}
            padding_bottom={div.padding_bottom.toString()}
            art_path={"assets/img/mat/default.jpg"}
            mat_color={"white"}
            scaling={scaling}
            mount={"simple"}
            //isCustomMode={showCustomPanel}
            hovering={false}
          />
        </IonCol>
        <IonCol size="12" offset-md="1" size-md="5.5">
          <IonText>
            <h2 style={{ display: "block" }}>{titlemat}</h2>
            <p style={{ display: "block" }}>{p1mat}</p>
            <p style={{ display: "block" }}>{p2mat}</p>
          </IonText>
          <div
            style={{ width: "100%" }}
            className="ion-padding-top ion-padding-bottom ion-text-center"
          >
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol size="6" className="ion-text-center ion-padding-start">
                <div style={{ minHeight: "100px" }}>
                  <IonImg
                    style={{ display: "inline-block", width: "50px" }}
                    src="assets/img/mount/artwork.png"
                  />
                  <IonText
                    className="ion-text-wrap"
                    style={{ display: "block" }}
                  >
                    <p>{artType}</p>
                  </IonText>
                  </div>
                </IonCol>
                <IonCol
                  size="6"
                  className="ion-text-center ion-padding-start ion-padding-end"
                >
                  <div style={{ minHeight: "100px" }}>
                  <IonImg
                    style={{ display: "inline-block", width: "50px" }}
                    src="assets/img/mount/size.png"
                  />
                  <IonText
                    className="ion-text-wrap"
                    style={{ display: "block" }}
                  >
                    <p>{dim}</p>
                  </IonText>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mat === "standard" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changematHandler("standard")}
          >
            <p className="text-guide-button">LE STANDARD</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mat === "thin" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changematHandler("thin")}
          >
            <p className="text-guide-button">LE FIN</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mat === "oversized" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changematHandler("oversized")}
          >
            L<p className="text-guide-button">'EXTRA-LARGE</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mat === "italiano" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changematHandler("italiano")}
          >
            <p className="text-guide-button">L'ITALIANO</p>
          </IonButton>
          <IonButton
            className="text-button ion-margin-top ion-margin-start ion-margin-end"
            fill={mat === "nomat" ? "solid" : "outline"}
            color="dark"
            mode="ios"
            shape="round"
            size="small"
            onClick={(e) => changematHandler("nomat")}
          >
            <p className="text-guide-button">L'ABSENT</p>
          </IonButton>
          <IonText style={{ display: "block" }}>
            <p className="ion-padding-top">
              Voir nos couleurs et textures disponibles{" "}
              <IonRouterLink routerLink="/gallery" routerDirection="forward">
                {" "}
                ici.
              </IonRouterLink>{" "}
            </p>
          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MatDisplay;
