import React, { useContext, useEffect, useState } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  IonButtons,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonBadge,
} from "@ionic/react";
import {
  logoFacebook,
  logoInstagram,
  logoPinterest,
  basketOutline,
} from "ionicons/icons";
import AuthContext from "../../context/auth-context";
import cContext from "../../context/cart-context";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory } from "react-router";


const SideDrawer: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(cContext);

  const [loadedItems, setLoadedItems] = useState<Array<any>>([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  useEffect(() => {
    const fetchCart = async () => {
      try {
        let article_ids = [];
        if (cartCtx && cartCtx.cartItems) {
          for (var j = 0; j < cartCtx.cartItems?.length; j++) {
            article_ids.push(cartCtx.cartItems[j].article_id);
          }
          const data = JSON.stringify({
            article_ids: article_ids,
          });
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "api/shop/order/getCart",
            "POST",
            data,
            {
              "Content-Type": "application/json",
            }
          );
          console.log(responseData.items);
          setLoadedItems(responseData.items);
          //console.log(responseData.products);
        }
      } catch (err) {}
    };
    fetchCart();
  }, [sendRequest, cartCtx.cartItems]);

  const myOrderHandler = () => {
    history.push("/account/orders");
  };

  return (
    <IonMenu contentId="main" swipeGesture={false} side='end'>
      <IonHeader color="light">
        <IonToolbar className="ion-text-center">
          <div className="ion-text-center">
            <IonButton
              routerLink="/"
              routerDirection="back"
              mode="ios"
              fill="clear"
            >
              <img
                style={{ maxHeight: "40px" }}
                src="assets/img/logo_black.png"
              />
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonMenuToggle key="home">
          {/*<IonItem
              lines="none"
              routerDirection="forward"
              routerLink="/what-we-frame-today"
              className="ion-text-center"
              text-center
              detail={false}
            >
              <IonButton
                color="dark"
                fill={"solid"}
                mode="ios"
                shape="round"
                className="text-button"
                size="large"
              >
                JE CRÉE MON LAMA
              </IonButton>
          </IonItem>*/}
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink="/gallery"
              detail={false}
            >
              <IonText>
                <h2>NOTRE COLLECTION</h2>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink="/how-it-works"
              detail={false}
            >
              <IonText>
                <h2>LE CONCEPT</h2>
              </IonText>
            </IonItem>
            <IonItem detail={false} lines="none" routerDirection="forward" routerLink="/guide">
              <IonText>
                <h3>GUIDE PRATIQUE</h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink="/know-how"
              detail={false}
            >
              <IonText>
                <h3>SAVOIR-FAIRE</h3>
              </IonText>
            </IonItem>
            <IonItem
            detail={false}
              lines="none"
              routerDirection="forward"
              routerLink="/our-mission"
            >
              <IonText>
                <h3>NOTRE MISSION</h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="full"
              routerDirection="forward"
              routerLink="/pricing"
              detail={false}
            >
              <IonText>
                <h3>PRIX</h3>
              </IonText>
            </IonItem>
            <IonItem lines='none'>
              <IonButton routerLink='/cart' routerDirection='forward' mode='ios' fill="clear" color="dark" slot="start">
                <IonIcon size="large" icon={basketOutline} />
                <IonText> <h3>&nbsp; &nbsp; &nbsp;PANIER</h3></IonText>
              </IonButton>
              {cartCtx.cartItems != null && cartCtx.cartItems.length > 0 && (<IonBadge slot='end' color='warning'>{cartCtx.cartItems.length}</IonBadge>)}
            </IonItem>
            <IonItem
              lines="full"
              routerDirection="forward"
              routerLink={authCtx.isLoggedIn ? "/account/orders" :"/account/login" }
              detail={false}
            >
              <IonText color="dark">
                <h3>MON COMPTE</h3>
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              routerDirection="forward"
              routerLink="/services"
              detail={false}
            >
              <IonText color="dark">
                <h3>NOS SERVICES</h3>
              </IonText>
            </IonItem>
            <IonItem lines="none" routerDirection="forward" routerLink="/faq" detail={false}>
              <IonText color="dark">
                <h3>FAQ</h3>
              </IonText>
            </IonItem>
            <IonItem lines="none" href="mailto:hello@lamafactory.com" detail={false}>
              <IonText color="dark">
                <h3>NOUS CONTACTER</h3>
              </IonText>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle key="social">
            <IonItem lines="none">
              <IonButtons slot="start">
                <IonButton fill="clear" color="dark">
                  <IonIcon slot="icon-only" icon={logoFacebook} />
                </IonButton>
                <IonButton
                  fill="clear"
                  color="dark"
                  href="https://www.instagram.com/lama.factory_design/"
                >
                  <IonIcon slot="icon-only" icon={logoInstagram} />
                </IonButton>
                <IonButton fill="clear" color="dark">
                  <IonIcon slot="icon-only" icon={logoPinterest} />
                </IonButton>
              </IonButtons>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle key="language" className='ion-padding-bottom'>
            <IonItem lines="none">
              <IonSegment
                mode="ios"
                value={"FR"}
                color="dark"
                onIonChange={(e) => console.log(e.detail.value)}
              >
                <IonSegmentButton value="FR">
                  <IonLabel>Français</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="EN">
                  <IonLabel>English</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default SideDrawer;
