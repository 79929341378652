import React from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonButton,
  IonText,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const BuildSizeModal: React.FC<{
  show: boolean;
  onDismiss: () => void;
}> = (props) => {
  return (
    <IonModal isOpen={props.show} backdropDismiss={false}>
      <IonHeader className="ion-no-border">
        <IonToolbar color="undefined" className="">
          <IonButtons slot="end" color='dark'><IonButton color='dark' onClick={props.onDismiss} size='large' slot='icon-only'><IonIcon icon={closeOutline}/></IonButton></IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="undefined">
        <IonGrid>
          <IonRow className='ion-padding ion-align-items-center'>
            <IonCol size="12" className='ion-padding'>
              <IonText color='dark'>
              <h2>100% sur mesure, même dans l’impression</h2>
              <p className="justify-text ion-padding-top ion-padding-bottom">
                Ici pas de formats d’impression standards. Vous décidez de la
                taille de votre tirage au centimètre près. Les dimensions
                proposées sont calculées en fonction de la résolution de votre
                fichier ainsi que du ratio souhaité, pour vous assurer d’un
                rendu de la plus grande qualité, toujours.
              </p>
              <h2>Le cadre est indicatif</h2>
              <p className="justify-text ion-padding-top ion-padding-bottom">
                Le cadre présenté ici est purement indicatif. Il vous aide à
                percevoir la taille finale de votre encadrement. Les dimensions
                indiquées sont bien celle de votre tirage et non celle du cadre.
                Le choix du cadre, sa personnalisation et donc sa taille finale,
                se décide à l’étape suivante.
              </p>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default BuildSizeModal;
