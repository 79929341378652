import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { IonAlert } from "@ionic/react";
import fContext from "../../context/f-context";

const DeleteLamAlert: React.FC<{
  show: boolean;
  onCancel: () => void;
  onDelete: () => void;
}> = props => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const fCtx = useContext(fContext);

  const deleteLamaHandler = () => {
    fCtx.deleteFrame();
    props.onCancel();
    history.push("/");
  };

  return (
    <IonAlert
      isOpen={props.show}
      onDidDismiss={() => setShowAlert(false)}
      //cssClass="my-custom-class"
      header={"LamAlert"}
      message={"Voulez-vous abandonner ce Lama pour en commencer un autre?"}
      buttons={[
        {
          text: "Continuer celui-la",
          role: "cancel",
          //cssClass: "secondary",
          handler: () => {
              props.onCancel();
          }
        },
        {
          text: "Commencer un nouveau",
          handler: () => {
            deleteLamaHandler();
          }
        }
      ]}
    />
  );
};

export default DeleteLamAlert;
