
import React from "react";

interface Context {
    priceList: Array<object>|null;
    populatePriceList: (price_list:Array<any>)=>void;
    getPricePerDim: (dim_cm:Number) => any;
}

const PriceContext = React.createContext<Context>({
    priceList: null,
    populatePriceList: ()=>{},
    getPricePerDim: () => {}
});

export default PriceContext;