const cleanArtType: { [id: string]: string } = {
    digital_photo: "Photo digitale",
    document: "Documents & Photos",
    art_on_paper: "Art sur papier",
    unmounted_canvas: "Toile à monter",
    mounted_canvas: "Toile montée",
    object: "Objet",
    textil: "Textile/Maillot",
  };

  export default cleanArtType;
 