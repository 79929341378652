import React, { useContext, useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonButton,
  IonCol,
  IonRow,
  IonText,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
} from "@ionic/react";

import DesktopToolbar from "../../components/shared/DesktopToolbar";
import FooterRows from "../../components/shared/FooterRows";
import { useHidingHeader } from "../../hooks/hidding-header-hook";
import HidingHeader from "../../components/shared/HidingHeader";
import ProgressiveImage from "react-progressive-graceful-image";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory } from "react-router";
import fContext from "../../context/f-context";
import AuthContext from "../../context/auth-context";

const Home: React.FC = () => {
  const [hideDecimal, setScrollYCurrent] = useHidingHeader(50);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [code, setCode] = useState<string>("");
  const history = useHistory();
  const fCtx = useContext(fContext);
  const authCtx = useContext(AuthContext);

  const checkCodeHandler = async () => {
    console.log(process.env);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}api/shop/order/checkPrivilegeDiscount`,
        "POST",
        JSON.stringify({ discount_code: code }),
        {
          "Content-Type": "application/json",
        }
      );
      fCtx.selectArtType("digital_photo");
      fCtx.selectMatColor("#FEFEFE");
      fCtx.selectMatStyle("standard");
      fCtx.selectMatShape("standard");
      fCtx.selectMatSize(8);
      fCtx.selectMount("simple");
      fCtx.selectSelectedStyle("standard");
      authCtx.setCode(code);
      history.push("/how-it-works");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IonPage>
      <HidingHeader hideDecimal={hideDecimal}>
        <DesktopToolbar />
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <IonGrid class="ion-no-padding">
          <IonRow style={{ minHeight: "calc(100vh - 300px)" }}>
            <ProgressiveImage
              src={"assets/img/home/xmas.jpg"}
              placeholder={"assets/img/walls/xmas_tn.jpg"}
            >
              {(src: string | undefined, loading: any) => (
                <IonCol
                  size="12"
                  size-md="6"
                  className="handmade-section"
                  style={{
                    backgroundImage: "url('" + src + "')",
                    filter: loading ? "blur(15px)" : "",
                    background: "no-repeat 52% center/cover",
                  }}
                ></IonCol>
              )}
            </ProgressiveImage>
            <IonCol
              className="ion-padding y-align"
              size="12"
              offset-md="0.2"
              size-md="5.6"
            >
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="12">
                    <IonText>
                      <h1>
                        Votre cadre photo sur mesure de qualité artisan en 5min
                      </h1>
                      <p
                        className="justify-text"
                        style={{ paddingTop: "32px" }}
                      >
                        Eiffage Immobilier est heureux de vous offrir un cadre
                        photo sur mesure de chez Lama Factory.{" "}
                      </p>
                      <p
                        className="justify-text"
                        style={{ paddingBottom: "16px" }}
                      >
                        Renseignez ci-dessous votre code privilège afin de
                        commencer la création de votre cadre et recevez-le
                        directement dans votre nouveau chez vous ! 👇{" "}
                      </p>
                    </IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonGrid className="ion-no-padding">
                      <IonRow>
                        <IonCol size="7">
                          <IonItem>
                            <IonLabel position="stacked">
                              Votre code privilège
                            </IonLabel>
                            <IonInput
                              type="text"
                              name="code"
                              onIonChange={(e) =>
                                setCode(e.detail.value ? e.detail.value : "")
                              }
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                              inputmode="text"
                              required={true}
                              color="dark"
                              autocomplete="on"
                              autoCorrect="on"
                            ></IonInput>
                          </IonItem>
                          {error && <h3>{error}</h3>}
                        </IonCol>
                        <IonCol size="5" className="ion-padding-start y-align">
                          <IonButton
                            size="default"
                            mode="ios"
                            expand="full"
                            color="dark"
                            style={{ width: "100%" }}
                            onClick={checkCodeHandler}
                            disabled={isLoading === true ? true : false}
                          >
                            <IonLabel className="ion-text-wrap">
                              <p>Valider</p>
                            </IonLabel>
                            {isLoading && (
                              <IonSpinner name="crescent" color="warning" />
                            )}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                  <IonCol size="12" style={{ paddingTop: "32px" }}>
                    <img src="assets/img/eiffageimmo_logo.png" width="150px" />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          <FooterRows />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
