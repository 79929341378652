import { isPlatform } from "@ionic/react";
import React from "react";
import s3 from '../../data/s3';

const LivingPreview: React.FC<{
  img_name: string;
  padding_bottom: string;
  art_top: string;
  art_left: string;
  mouldingHeight: string;
  frameToMatPct: string;
  art_bottom: string;
  art_path: string;
  living_room_img: string;
  living_room_width: number;
  selected_w: number;
  mat_color: string;
  mat_size:number;
  moulding_width: number;
  mount: string;
  fullscreen: boolean;
}> = props => {
  const frameCStyle = {
    paddingBottom: props.padding_bottom + "%"
  } as React.CSSProperties;

  const matStyleUS = {
    background: props.mat_color,
    top: props.mouldingHeight + "%",
    bottom: props.mouldingHeight + "%",
    left: props.frameToMatPct + "%",
    right: props.frameToMatPct + "%",
    zIndex: 24,
    boxShadow: '2px 5px 8px 4px rgba(0, 0, 0, 0.2)'
  } as React.CSSProperties;

  const matStyle = {
    background: props.mat_color,
    top: props.mouldingHeight + "%",
    bottom: props.mouldingHeight + "%",
    left: props.frameToMatPct + "%",
    right: props.frameToMatPct + "%"
  } as React.CSSProperties;

  const artStyle = {
    top: props.art_top + "%",
    bottom: props.art_bottom + "%",
    left: props.art_left + "%",
    right: props.art_left + "%"
  } as React.CSSProperties;

  const artStyleFloat = {
    top: props.art_top + "%",
    bottom: props.art_bottom + "%",
    left: props.art_left + "%",
    right: props.art_left + "%",
    zIndex: 23,
    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.2)'
  } as React.CSSProperties;

  const artImgStyleSimple = {  border: '1px solid',
    borderBottomColor: '#ffe',
    borderLeftColor: '#eed',
    borderRightColor: '#eed',
    borderTopColor: '#ccb'
  } as React.CSSProperties;

  const artImgStyle = {  border: '',
  borderBottomColor: '',
  borderLeftColor: '',
  borderRightColor: '',
  borderTopColor: ''
} as React.CSSProperties;

  const livingRoomStyle = {
    backgroundImage: "url('" + props.living_room_img + "')"
  } as React.CSSProperties;

  const livingFrameZone = {
    WebkitTransform: "scale(" +((props.selected_w+ 2* props.mat_size+ 2*props.moulding_width) / props.living_room_width).toString() + ")",
    transform: "scale(" +((props.selected_w+ 2* props.mat_size+ 2*props.moulding_width) / props.living_room_width).toString() + ")",
    paddingBottom: props.padding_bottom + "%"
  } as React.CSSProperties;

  const frameShadowStyle = {
    top: "21px",
    right: "21px",
    bottom: "20px",
    left: "21px"
  } as React.CSSProperties;

  const mouldingLeftStyle = {
    backgroundImage: "url(\""+s3+"public/assets/img/" + props.img_name + "\")",
    height: props.mouldingHeight + "%",
    width: props.padding_bottom + "%"
  } as React.CSSProperties;

  const mouldingRightStyle = {
    backgroundImage: "url(\""+s3+"public/assets/img/" + props.img_name + "\")",
    height: props.mouldingHeight + "%",
    width: props.padding_bottom + "%"
  } as React.CSSProperties;

  const frameEdgeTopStyle = {
    backgroundImage: "url(\""+s3+"public/assets/img/" + props.img_name + "\")",
    height: props.mouldingHeight + "%",
    WebkitClipPath:
    "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
      props.frameToMatPct +
      "%) 100%, " +
      props.frameToMatPct +
      "% 100%)",
    clipPath:
      "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
      props.frameToMatPct +
      "%) 100%, " +
      props.frameToMatPct +
      "% 100%)"
  } as React.CSSProperties;

  const frameEdgeBottomStyle = {
    backgroundImage: "url(\""+s3+"public/assets/img/" + props.img_name + "\")",
    height: props.mouldingHeight + "%",
    WebkitClipPath:
    "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
      props.frameToMatPct +
      "%) 100%, " +
      props.frameToMatPct +
      "% 100%)",
    clipPath:
      "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
      props.frameToMatPct +
      "%) 100%, " +
      props.frameToMatPct +
      "% 100%)"
  } as React.CSSProperties;

  const livingWallStyle = {
    paddingBottom:(isPlatform('mobile')|| !props.fullscreen)?'100%':'63.9%'
  } as React.CSSProperties;


  return (
    <div className="living-wall" style={livingWallStyle}>
      <div className="living-room" style={livingRoomStyle}>
        <div className="living-frame-zone" style={livingFrameZone}>
          <div className="frame-c" style={frameCStyle}>
            <div
              id="frame-shadow"
              data-v-2fafbb69=""
              style={frameShadowStyle}
            ></div>
            <div
              className="moulding moulding-left"
              id="left"
              style={mouldingLeftStyle}
            ></div>
            <div
              className="moulding moulding-right"
              id="right"
              style={mouldingRightStyle}
            ></div>
            <div
              className="frame-edge"
              id="top"
              style={frameEdgeTopStyle}
            ></div>
            <div
              className="frame-edge"
              id="bottom"
              style={frameEdgeBottomStyle}
            ></div>
              <div className="mat" id="matDiv" style={props.mount ==='canvas' ?  matStyleUS:matStyle}>
                {" "}
                <div className="art" id="art" style={props.mount === 'floating' ? artStyleFloat :artStyle}>
                  <img id="art2" style={props.mount === 'simple'? artImgStyleSimple: artImgStyle} src={props.art_path} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivingPreview;
