import React, { useContext, useRef } from "react";
import { IonCol, IonRange, IonIcon, IonText, IonCard, IonButtons, IonButton } from "@ionic/react";
import { addCircleOutline, removeCircleOutline } from "ionicons/icons";
import fContext from "../../context/f-context";

const SizeRange: React.FC<{
  calculatePriceHandler: (size_index: Number) => void;
  showToast:()=>void;
  dismissToast:()=>void;
}> = (props) => {
  const fCtx = useContext(fContext);
  const sizeInput = useRef<HTMLIonRangeElement>(null);

  const decreaseSizeHandler=()=>{
    if (sizeInput.current != null) {
      const sizeSelected = sizeInput.current!.value;
      if(Number(sizeSelected) === Number(sizeInput.current!.min)){

      }else{
        fCtx.selectSize(
          Number(fCtx.wList[Number(sizeSelected) - 2]),
          Number(fCtx.hList[Number(sizeSelected) - 2]),
          Number(sizeSelected) - 2
        );
        props.calculatePriceHandler(Number(sizeSelected) - 2);
      }
    }
  }

  const increaseSizeHandler=()=>{
    if (sizeInput.current != null) {
      const sizeSelected = sizeInput.current!.value;
      if(Number(sizeSelected) === Number(sizeInput.current!.max)){

      }else{
        fCtx.selectSize(
          Number(fCtx.wList[Number(sizeSelected)]),
          Number(fCtx.hList[Number(sizeSelected)]),
          Number(sizeSelected)
        );
        props.calculatePriceHandler(Number(sizeSelected));
      }
    }
  }

  const sizeRangeHandler = () => {
    if (sizeInput.current != null) {
      const sizeSelected = sizeInput.current!.value;
      fCtx.selectSize(
        Number(fCtx.wList[Number(sizeSelected) - 1]),
        Number(fCtx.hList[Number(sizeSelected) - 1]),
        Number(sizeSelected) - 1
      );
      props.calculatePriceHandler(Number(sizeSelected) - 1);

      if(Number(sizeSelected) === Number(sizeInput.current!.max)){
        props.showToast();
      }else{
        props.dismissToast();
      }
    }
  };

  return (
    <React.Fragment>
      {" "}
      <IonCol  size="12" size-md="6" size-xl='4' offset-xl='4' offset-md="3" class="ion-text-center">
        <IonCard className='no-shadow' style={{backgroundColor:'white', maxWidth:'200px', margin:'auto'}}> 
        <IonText color="dark" >
          <h2 style={{marginTop:'18px', marginBottom:'18px'}}>
            {fCtx.wSelected} x {fCtx.hSelected} cm
          </h2>
        </IonText>
        </IonCard>
      </IonCol>
      <IonCol size="12" size-md="6" offset-md="3"  class="ion-text-center">
        <IonRange
          ref={sizeInput}
          name="size"
          min={1}
          max={fCtx.wList.length}
          step={1}
          snaps={true}
          ticks={false}
          //pin={true}
          onIonChange={sizeRangeHandler}
          value={Number(fCtx.indexSelected) + 1}
          mode="ios"
          style={{ paddingTop: "0px" }}
        >
          <IonButtons slot='start'><IonButton size='large' color='dark' fill='clear' onClick={decreaseSizeHandler}><IonIcon size="large" slot="start" icon={removeCircleOutline} /></IonButton></IonButtons>
          
          <IonButtons slot='end'><IonButton color='dark' fill='clear' onClick={increaseSizeHandler}><IonIcon size="large" slot="start" icon={addCircleOutline} /></IonButton></IonButtons>
        </IonRange>
      </IonCol>
    </React.Fragment>
  );
};

export default SizeRange;
