import React, { useState, useContext, useEffect, useRef } from "react";
import { getDivMulti, getOutsideDimensions } from "../../util/frame";
import {
  IonPage,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
  IonText,
  IonSlide,
  IonSegment,
  IonSegmentButton,
  IonItem,
  IonImg,
  IonSpinner,
  IonFooter,
  IonFab,
  IonFabButton,
  isPlatform,
  IonCardContent,
  IonCard,
  IonRouterLink,
} from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons";
import FramePreview from "../../components/shared/FramePreview";
import DeleteLamAlert from "../../components/shared/DeleteLamAlert";
import { useParams, useHistory } from "react-router";
import fContext from "../../context/f-context";
import LivingPreview from "../../components/shared/LivingPreview";
import { useHttpClient } from "../../hooks/http-hook";
import FullScreenFrame from "../../components/shop/FullScreenFrame";
import "../../theme/product-details.css";
import FullScreenImage from "../../components/shop/FullScreenImage";
import ProductDetailsDescription from "../../components/shop/ProductDetailsDescription";
import cContext from "../../context/cart-context";
import AddCartModal from "../../components/shop/AddCartModal";
import PriceContext from "../../context/price-context";
import { Vibration } from "@ionic-native/vibration";
import FramePreviewFix from "../../components/shared/FramePreviewFix";
import LivingPreviewFix from "../../components/shared/LivingPreview copy";
import ProductDetailsDescriptionDesktop from "../../components/shop/ProductDetailsDescriptionDesktop";
import FR from "../../language/fr";
import CustomPanel from "../../components/shop/CustomPanel";
import FooterRows from "../../components/shared/FooterRows";
import IncludedList from "../../components/shop/IncludedList";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import { useHidingHeader } from "../../hooks/hidding-header-hook";
import HidingHeader from "../../components/shared/HidingHeader";
import s3 from "../../data/s3";
import CustomDescription from "../../components/shared/CustomDescription";
import ProgressiveImage from "react-progressive-graceful-image";
import MountInformationModal from "../../components/shop/MountInformationModal";
import cleanArtType from "../../data/cleanType";
import DeliveryInfoModal from "../../components/shop/DeliveryInfoModal";
import AuthContext from "../../context/auth-context";

const ProductDetailsTech2: React.FC = () => {
  const fCtx = useContext(fContext);
  const cartCtx = useContext(cContext);
  const priceCtx = useContext(PriceContext);
  let selectedProductName = useParams<{ productName: string }>().productName;
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loadedMoulding, setLoadedMoulding] = useState<any>({});
  const [loadedProducts, setLoadedProducts] = useState<any[]>([]);
  const [showFullScreenFrame, setShowFullScreenFrame] =
    useState<boolean>(false);
  const [showFullScreenImage, setShowFullScreenImage] =
    useState<boolean>(false);
  const [imageFullScreenSrc, setImageFullScreenSrc] = useState<string>("");
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const [showBuy, setShowBuy] = useState<boolean>(true);
  const [showAddCartModal, setShowAddCartModal] = useState<boolean>(false);
  const [showCustomPanel, setShowCustomPanel] = useState<boolean>(false);
  const [projectionView, setProjectionView] = useState<string | undefined>(
    "plain"
  );
  const [hideDecimal, setScrollYCurrent] = useHidingHeader(50);
  const [showInformationModal, setShowInformationModal] =
    useState<boolean>(false);
  const [showDeliveryInfoModal, setShowDeliveryInfoModal] =
    useState<boolean>(false);
  const authCtx = useContext(AuthContext);
  const isOldEiffageFormat = authCtx.code
    ?.split("")
    .every(
      (character: string) => !"AZERTYUIOPQSDFGHJKLMWXCVBN".includes(character)
    );

  /////////FETCH section /////////////////////////////////////
  //LOAD SELECTED MOULDING DETAILS
  useEffect(() => {
    const fetchMoulding = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            "api/shop/product/" +
            selectedProductName
        );
        setLoadedMoulding(responseData.product);
        console.log(responseData.product);
      } catch (err) {}
    };
    fetchMoulding();
  }, [sendRequest, selectedProductName]);

  //LOAD MOUDLINGS SLIDER
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/products/" + fCtx.mount
        );
        if (isOldEiffageFormat) {
          setLoadedProducts(responseData.products);
        } else {
          setLoadedProducts(
            responseData.products.filter((moulding: any) =>
              ["Mambo (blanc)", "Mambo (noir)", "Glama", "Coya"].includes(
                moulding.name
              )
            )
          );
        }
        //console.log(responseData.products);
      } catch (err) {}
    };
    fetchProducts();
  }, [sendRequest, fCtx.mount]);

  ////////  HANDLER SECTION  /////////////////////////////////

  const addToCartHandler = async (code: string) => {
    try {
      const data = JSON.stringify({
        article_id: fCtx.articleId,
        product_id: loadedMoulding._id,
        art_w_cm_selected: fCtx.wSelected,
        art_h_cm_selected: fCtx.hSelected,
        mat_size_selected: fCtx.matSize,
        mat_mode_selected: fCtx.matStyle,
        mat_shape_selected: fCtx.matShape,
        mat_color_selected: fCtx.matColor,
        mount_selected: fCtx.mount,
        art_type: fCtx.artType,
        frame_w_cm: out_dim.out_w_cm,
        frame_h_cm: out_dim.out_h_cm,
        div: {
          art_bottom: moulding.div.art_bottom.toString(),
          art_left: moulding.div.art_left.toString(),
          art_top: moulding.div.art_top.toString(),
          frameToMatPct: moulding.div.frameToMatPct.toString(),
          mouldingHeight: moulding.div.mouldingHeight.toString(),
          padding_bottom: moulding.div.padding_bottom.toString(),
          scaling: scaling,
        },
      });

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/order/addToCart",
        "POST",
        data,
        {
          "Content-Type": "application/json",
        }
      );
      /*const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/order/addToCart",
        "POST",
        formData
      );*/

      //cartCtx.addItem(fCtx.articleId, 1);
      //setShowAddCartModal(true);
      //history.push("/cart");

      try {
        let article_ids = [];
        article_ids.push(fCtx.articleId);
        const data = JSON.stringify({
          article_ids: article_ids,
          discount_code_id: code,
        });
        let responseData;
        responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/order/create",
          "POST",
          data,
          {
            "Content-Type": "application/json",
          }
        );
        history.push(`/order/${responseData.order_id}/info`);
      } catch (err) {}
    } catch (err) {
      console.log(err);
    }
  };

  const onMakeOrder = (code: string) => {
    addToCartHandler(code);
    setShowAddCartModal(false);
  };

  const onDismiss = () => {
    setShowAddCartModal(false);
  };

  const cancelShowAlertHandler = () => {
    setShowAlert(false);
  };

  const showFullScreenFrameHandler = () => {
    setShowFullScreenFrame(true);
  };
  const cancelShowFullScreenFrame = () => {
    setShowFullScreenFrame(false);
  };

  const cancelShowFullScreenImage = () => {
    setShowFullScreenImage(false);
  };

  const changeMouldingHandler = (moulding_name: string | undefined) => {
    if (moulding_name) {
      selectedProductName = moulding_name;
    }
    history.replace("/product/" + moulding_name);
    contentRef.current && contentRef.current.scrollToTop(700);
    //let content = document.querySelector("ion-content");
    //content!.scrollToTop(700);
  };

  const deleteLamaHandler = () => {
    fCtx.deleteFrame();
    setShowAlert(false);
    history.push("/what-we-frame-today");
  };

  const showCustomPanelHandler = () => {
    //let content = document.querySelector("ion-content");
    //let content2 = document.getElementById("page-el");
    //content2?.scrollTo(0,0);
    //content!.scrollToTop(700);
    contentRef.current && contentRef.current.scrollToTop(700);
    setShowCustomPanel(!showCustomPanel);
    return true;
  };

  const showAddToCartButtonHandler = (scrollTop: number) => {
    setScrollYCurrent(scrollTop);
    if (scrollTop > 220) {
      setShowBuy(false);
    } else {
      setShowBuy(true);
    }
  };

  const scrollToDetailsHandler = () => {
    //let content = document.querySelector("ion-content");
    //content!.scrollToPoint(0, 400);
    contentRef.current && contentRef.current.scrollToPoint(0, 400, 500);
    //console.log(content);
  };

  const showInformationModalHandler = () => {
    setShowInformationModal(true);
  };

  const dismissInformationModalHandler = () => {
    setShowInformationModal(false);
  };

  const showDeliveryInfoModalHandler = () => {
    setShowDeliveryInfoModal(true);
  };

  const dismissDeliveryInfoModalHandler = () => {
    setShowDeliveryInfoModal(false);
  };

  var art_w_cm = Number(fCtx.wSelected); //width art in cm
  var art_w = Number(fCtx.imageWidth); //width art in px
  var art_h = Number(fCtx.imageHeight); //height art in px
  var biseau_w_cm = 0.18; // biseau width cm

  var mode = fCtx.matStyle; //mode 'italiano' or 'standard'
  var mouldingWidth = 0; //moulding width in cm
  var mat = Number(fCtx.matSize); //mat width in cm

  if (fCtx.mount === "canvas") {
    var scaling = 0.9;
  } else {
    var scaling =
      (Number(fCtx.wSelected) + 2 * mat + 2 * Number(loadedMoulding.width)) /
      (1.1 *
        (Number(fCtx.wSelected) + 2 * 16 + 2 * Number(loadedMoulding.width)));
  }

  const div = getDivMulti(
    art_w,
    art_h,
    mode,
    mat,
    art_w_cm,
    Number(loadedMoulding.width),
    biseau_w_cm
  );

  const moulding = {
    name: selectedProductName,
    width_cm: loadedMoulding.width,
    style: loadedMoulding.style,
    description: loadedMoulding.description,
    div: div,
    collection_moulding: loadedMoulding.collection_moulding,
    art_by: loadedMoulding.art_by,
  };

  const out_dim = getOutsideDimensions(
    fCtx.wSelected,
    fCtx.hSelected,
    fCtx.matStyle,
    fCtx.matSize,
    moulding.width_cm,
    fCtx.mount,
    fCtx.artType
  );

  const div_list = loadedProducts.map((product) =>
    getDivMulti(
      art_w,
      art_h,
      mode,
      mat,
      art_w_cm,
      Number(product.width),
      biseau_w_cm
    )
  );

  const products = loadedProducts.map((product, index) => ({
    name: product.name,
    width_cm: product.width,
    style: product.style,
    div: div_list[index],
    collection_moulding: product.collection_moulding,
  }));

  return (
    <React.Fragment>
      <DeliveryInfoModal
        show={showDeliveryInfoModal}
        onDismiss={dismissDeliveryInfoModalHandler}
      />
      <MountInformationModal
        show={showInformationModal}
        onDismiss={dismissInformationModalHandler}
      />
      <AddCartModal
        show={showAddCartModal}
        onPassOrder={onMakeOrder}
        onDismiss={onDismiss}
      />
      <DeleteLamAlert
        show={showAlert}
        onCancel={cancelShowAlertHandler}
        onDelete={deleteLamaHandler}
      />
      <FullScreenFrame
        show={showFullScreenFrame}
        onCancel={cancelShowFullScreenFrame}
        moulding={moulding}
        scaling={scaling}
      />
      <FullScreenImage
        image_src={imageFullScreenSrc}
        onCancel={cancelShowFullScreenImage}
        show={showFullScreenImage}
      />
      <IonPage id="page-el">
        {(!isPlatform("mobile") ||
          (!showCustomPanel && isPlatform("mobile"))) && (
          <HidingHeader hideDecimal={hideDecimal}>
            <DesktopToolbar />
          </HidingHeader>
        )}
        {isPlatform("mobile") && (
          <IonContent
            ref={contentRef}
            id="details-content"
            scrollEvents={true}
            class=""
            scrollY={showCustomPanel ? false : true}
            scrollX={false}
            //onIonScrollStart={(e) => setShowBuy(false)}
            //onIonScrollEnd={(e) => setShowBuy(true)}
            //onIonScroll={(e) => showAddToCartButtonHandler(e.detail.scrollTop)}
            fullscreen={true}
            onIonScroll={(e) => showAddToCartButtonHandler(e.detail.scrollTop)}
          >
            <div
              className={
                fCtx.articleId === ""
                  ? ""
                  : showCustomPanel
                  ? "upper-vh"
                  : "upper-vh-mid"
              }
            >
              <IonGrid
                className={
                  fCtx.articleId === ""
                    ? "ion-no-padding"
                    : showCustomPanel
                    ? " ion-no-padding upper-vh"
                    : "ion-no-padding upper-vh-mid"
                }
              >
                <IonRow
                  className={
                    fCtx.articleId === ""
                      ? ""
                      : showCustomPanel
                      ? "upper-vh"
                      : "upper-vh-mid"
                  }
                >
                  <IonCol
                    className={
                      fCtx.articleId === ""
                        ? "ion-text-center ion-no-padding"
                        : showCustomPanel
                        ? "upper-vh ion-text-center ion-no-padding"
                        : "ion-text-center ion-no-padding upper-vh-mid"
                    }
                    size="12"
                    style={{
                      borderBottom: "1px solid var(--ion-color-light-shade) ",
                    }}
                  >
                    <IonFab
                      vertical="center"
                      horizontal="end"
                      style={{ width: "30px", marginTop: "-63px" }}
                    >
                      <IonSegment
                        value={projectionView}
                        color="dark"
                        onIonChange={(e) => setProjectionView(e.detail.value)}
                        className="ion-padding-top ion-padding-bottom ion-text-center"
                        style={{ display: "block" }}
                        mode="ios"
                      >
                        <IonSegmentButton
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            minWidth: "22px",
                          }}
                          value="plain"
                        >
                          <div className="viewSwitchProduct">
                            <IonImg
                              src="assets/img/frame.png"
                              alt="Vue encadrement sur mesure de face"
                            />
                          </div>
                        </IonSegmentButton>
                        <IonSegmentButton
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            minWidth: "22px",
                          }}
                          value="tablet"
                        >
                          <div className="viewSwitchProduct">
                            <IonImg
                              src="assets/img/tablet.png"
                              alt="Vue encadrement sur mesure tablette"
                            />
                          </div>
                        </IonSegmentButton>
                      </IonSegment>
                    </IonFab>
                    {projectionView === "plain" && (
                      <React.Fragment>
                        {fCtx.articleId === "" ? (
                          <ProgressiveImage
                            src={
                              s3 +
                              "public/assets/img/" +
                              moulding.name +
                              "/" +
                              moulding.name +
                              "1.jpg"
                            }
                            placeholder={
                              s3 +
                              "public/assets/img/" +
                              moulding.name +
                              "/" +
                              moulding.name +
                              "_tn1.jpg"
                            }
                          >
                            {(src: string | undefined, loading: any) => (
                              <img
                                style={{
                                  width: "1000px",
                                  maxWidth: "100% !important",
                                  minHeight: "300px",
                                  filter: loading ? "blur(7px)" : "",
                                }}
                                src={src}
                                alt="Vue encadrement sur mesure face"
                              />
                            )}
                          </ProgressiveImage>
                        ) : (
                          <IonSlide
                            className={
                              showCustomPanel ? "upper-vh" : "upper-vh-mid"
                            }
                            onClick={showFullScreenFrameHandler}
                          >
                            <FramePreviewFix
                              art_bottom={moulding.div.art_bottom.toString()}
                              art_left={moulding.div.art_left.toString()}
                              art_top={moulding.div.art_top.toString()}
                              frameToMatPct={moulding.div.frameToMatPct.toString()}
                              img_name={moulding.name + ".jpg"}
                              mouldingHeight={moulding.div.mouldingHeight.toString()}
                              padding_bottom={moulding.div.padding_bottom.toString()}
                              art_path={
                                fCtx.imageUrl.includes("uploads/images")
                                  ? process.env.REACT_APP_BACKEND_URL +
                                    fCtx.imageUrl
                                  : fCtx.imageUrl
                              }
                              mat_color={fCtx.matColor}
                              scaling={scaling}
                              mount={fCtx.mount}
                              isCustomMode={showCustomPanel}
                            />
                          </IonSlide>
                        )}
                      </React.Fragment>
                    )}
                    {projectionView === "tablet" && (
                      <React.Fragment>
                        {fCtx.articleId === "" ? (
                          <ProgressiveImage
                            src={
                              s3 +
                              "public/assets/img/" +
                              moulding.name +
                              "/" +
                              moulding.name +
                              "4.jpg"
                            }
                            placeholder={
                              s3 +
                              "public/assets/img/" +
                              moulding.name +
                              "/" +
                              moulding.name +
                              "_tn4.jpg"
                            }
                          >
                            {(src: string | undefined, loading: any) => (
                              <img
                                style={{
                                  width: "1000px",
                                  maxWidth: "100% !important",
                                  minHeight: "300px",
                                  filter: loading ? "blur(7px)" : "",
                                }}
                                src={src}
                                alt="Vue encadrement sur mesure tablette"
                              />
                            )}
                          </ProgressiveImage>
                        ) : (
                          <IonSlide className="upper-vh">
                            <LivingPreviewFix
                              art_bottom={moulding.div.art_bottom.toString()}
                              art_left={moulding.div.art_left.toString()}
                              art_top={moulding.div.art_top.toString()}
                              frameToMatPct={moulding.div.frameToMatPct.toString()}
                              img_name={moulding.name + ".jpg"}
                              mouldingHeight={moulding.div.mouldingHeight.toString()}
                              padding_bottom={moulding.div.padding_bottom.toString()}
                              art_path={
                                fCtx.imageUrl.includes("uploads/images")
                                  ? process.env.REACT_APP_BACKEND_URL +
                                    fCtx.imageUrl
                                  : fCtx.imageUrl
                              }
                              //living_room_img={"assets/img/living_room.jpg"}
                              //living_room_img={"assets/img/living_cropped.jpg"}
                              //living_room_width={310}
                              //living_room_width={246}
                              living_room_img={"assets/img/proj_desktop.jpg"}
                              living_room_width={226}
                              selected_w={Number(fCtx.wSelected)}
                              mat_color={fCtx.matColor}
                              mat_size={Number(fCtx.matSize)}
                              moulding_width={Number(moulding.width_cm)}
                              mount={fCtx.mount}
                            />
                          </IonSlide>
                        )}
                      </React.Fragment>
                    )}
                    {fCtx.articleId !== "" && (
                      <IonFab
                        edge
                        horizontal={"center"}
                        vertical="bottom"
                        color="tertiary"
                      >
                        <IonFabButton
                          activated={showCustomPanel}
                          onClick={showCustomPanelHandler}
                          color={showCustomPanel ? "undefined" : "primary"}
                        >
                          <IonText className="text-fab-button">
                            <div style={{ display: "block" }}>MO</div>
                            <div style={{ display: "block" }}>DI</div>
                            <div style={{ display: "block" }}>FIER</div>
                          </IonText>
                        </IonFabButton>
                      </IonFab>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div
              className={
                fCtx.articleId === ""
                  ? ""
                  : showCustomPanel
                  ? "lower-vh scrollY"
                  : ""
              }
            >
              <IonGrid color="light" className="ion-no-padding ion-padding-top">
                <IonRow className="ion-align-items-start">
                  {!showCustomPanel && (
                    <React.Fragment>
                      <IonCol size="12" className="ion-text-center">
                        <ProductDetailsDescription
                          moulding_description={moulding.description}
                          moulding_name={moulding.name}
                          moulding_style={moulding.style}
                          out_w_cm={
                            out_dim.out_w_cm ? out_dim.out_w_cm.toString() : ""
                          }
                          out_h_cm={
                            out_dim.out_h_cm ? out_dim.out_h_cm.toString() : ""
                          }
                          scrollToDetails={scrollToDetailsHandler}
                          showCustomPanel={showCustomPanelHandler}
                          art_by={moulding.art_by}
                        />
                        <IonRouterLink
                          color="dark"
                          onClick={showDeliveryInfoModalHandler}
                        >
                          <p
                            className="text-delivery-price-mobile disable-select ion-padding-bottom"
                            style={{ marginTop: "0px" }}
                          >
                            + frais de port
                          </p>
                        </IonRouterLink>
                      </IonCol>
                      <IonCol size="12" size-lg="6" offset-lg="6">
                        {fCtx.articleId === "" ? (
                          <IonButton
                            className="ion-no-margin"
                            mode="ios"
                            expand="full"
                            color="primary"
                            size="large"
                            routerLink="/what-we-frame-today"
                            routerDirection="forward"
                          >
                            <p className="text-button">
                              COMMENCER MON ENCADREMENT
                            </p>
                          </IonButton>
                        ) : (
                          <IonButton
                            className="ion-no-margin"
                            disabled={isLoading ? true : false}
                            mode="ios"
                            expand="full"
                            color="dark"
                            size="large"
                            onClick={(e) => setShowAddCartModal(true)}
                          >
                            <IonText
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                                fontWeight: "600",
                              }}
                            >
                              {Number(
                                priceCtx.getPricePerDim(
                                  Number(fCtx.hSelected) +
                                    Number(fCtx.wSelected)
                                ).base_price
                              ) !== -1 && (
                                <React.Fragment>
                                  <p style={{ display: "inline-block" }}>
                                    Passer commande &nbsp; &nbsp;
                                  </p>
                                </React.Fragment>
                              )}
                            </IonText>
                            {isLoading && (
                              <IonSpinner name="crescent" color="warning" />
                            )}
                          </IonButton>
                        )}
                      </IonCol>
                    </React.Fragment>
                  )}
                  {showCustomPanel && (
                    <IonCol size="12" size-lg="6">
                      <CustomPanel
                        loadedMoulding={loadedMoulding}
                        products={products}
                        changeMouldingHandler={changeMouldingHandler}
                        selectedProductName={selectedProductName}
                        showCustomPanelHandler={showCustomPanelHandler}
                        showInformartionModalHandler={
                          showInformationModalHandler
                        }
                      />
                    </IonCol>
                  )}
                </IonRow>
                {/*!showCustomPanel && <FooterRows/>*/}
              </IonGrid>
            </div>
          </IonContent>
        )}

        {/*/////////////////////////////////////////////////////////////////////////////////DESKTOP*/}
        {isPlatform("desktop") && (
          <IonContent
            id="details-content"
            class=""
            scrollY={true}
            scrollX={false}
            fullscreen={true}
            scrollEvents={true}
            onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          >
            <IonGrid className={"ion-no-padding"}>
              <IonRow className="ion-padding-bottom">
                <IonCol
                  style={{ background: "var(--ion-color-light)" }}
                  className={"ion-text-center ion-padding-top"}
                  size="6"
                >
                  <div className="ion-text-left">
                    <IonButton
                      routerLink="/gallery"
                      routerDirection="back"
                      size="small"
                      mode="ios"
                      fill="solid"
                      shape="round"
                      color="light"
                    >
                      <IonIcon
                        icon={arrowBackOutline}
                        style={{ display: "inline-block" }}
                      />
                      <IonText color="dark">
                        <p style={{ display: "inline-block" }}>
                          {" "}
                          &nbsp; notre collection
                        </p>
                      </IonText>
                    </IonButton>
                  </div>
                  <IonGrid className="ion-padding-top">
                    <IonRow>
                      <IonCol size="4" offset="4">
                        <IonSegment
                          value={projectionView}
                          color="dark"
                          onIonChange={(e) => setProjectionView(e.detail.value)}
                          className="ion-padding-start ion-padding-end ion-text-center"
                          mode="ios"
                        >
                          <IonSegmentButton value="plain">
                            <div className="viewSwitch">
                              <IonImg
                                src="assets/img/frame.png"
                                alt="Vue encadrement sur mesure face"
                              />
                            </div>
                          </IonSegmentButton>
                          <IonSegmentButton value="tablet">
                            <div className="viewSwitch">
                              <IonImg
                                src="assets/img/tablet.png"
                                alt="Vue encadrement sur mesure tablette"
                              />
                            </div>
                          </IonSegmentButton>
                        </IonSegment>
                      </IonCol>
                      <IonCol
                        style={
                          fCtx.articleId === ""
                            ? { marginTop: "16px" }
                            : projectionView === "tablet"
                            ? { backgroundColor: "white", marginTop: "16px" }
                            : { marginTop: "16px" }
                        }
                        size="10"
                        offset="1"
                        size-xl="8"
                        offset-xl="2"
                      >
                        {fCtx.articleId === "" ? (
                          <ProgressiveImage
                            src={
                              s3 +
                              "public/assets/img/" +
                              moulding.name +
                              "/" +
                              moulding.name +
                              (projectionView === "plain" ? "1" : "4") +
                              ".jpg"
                            }
                            placeholder={
                              s3 +
                              "public/assets/img/" +
                              moulding.name +
                              "/" +
                              moulding.name +
                              (projectionView === "plain" ? "1" : "4") +
                              "_tn.jpg"
                            }
                          >
                            {(src: string | undefined, loading: any) => (
                              <img
                                style={{
                                  width: "1000px",
                                  maxWidth: "100% !important",
                                  filter: loading ? "blur(7px)" : "",
                                }}
                                src={src}
                                alt="Vue encadrement produit sur mesure"
                              />
                            )}
                          </ProgressiveImage>
                        ) : (
                          <React.Fragment>
                            {projectionView === "plain" && (
                              <div onClick={showFullScreenFrameHandler}>
                                <FramePreview
                                  art_bottom={moulding.div.art_bottom.toString()}
                                  art_left={moulding.div.art_left.toString()}
                                  art_top={moulding.div.art_top.toString()}
                                  frameToMatPct={moulding.div.frameToMatPct.toString()}
                                  img_name={moulding.name + ".jpg"}
                                  mouldingHeight={moulding.div.mouldingHeight.toString()}
                                  padding_bottom={moulding.div.padding_bottom.toString()}
                                  art_path={
                                    fCtx.imageUrl.includes("uploads/images")
                                      ? process.env.REACT_APP_BACKEND_URL +
                                        fCtx.imageUrl
                                      : fCtx.imageUrl
                                  }
                                  mat_color={fCtx.matColor}
                                  scaling={scaling}
                                  mount={fCtx.mount}
                                  //isCustomMode={showCustomPanel}
                                  hovering={false}
                                />
                              </div>
                            )}
                            {projectionView === "tablet" && (
                              <div
                                onClick={showFullScreenFrameHandler}
                                style={{ paddingTop: "96px" }}
                              >
                                <LivingPreview
                                  art_bottom={moulding.div.art_bottom.toString()}
                                  art_left={moulding.div.art_left.toString()}
                                  art_top={moulding.div.art_top.toString()}
                                  frameToMatPct={moulding.div.frameToMatPct.toString()}
                                  img_name={moulding.name + ".jpg"}
                                  mouldingHeight={moulding.div.mouldingHeight.toString()}
                                  padding_bottom={moulding.div.padding_bottom.toString()}
                                  art_path={
                                    fCtx.imageUrl.includes("uploads/images")
                                      ? process.env.REACT_APP_BACKEND_URL +
                                        fCtx.imageUrl
                                      : fCtx.imageUrl
                                  }
                                  //living_room_img={"assets/img/living_room.jpg"}
                                  living_room_img={
                                    "assets/img/proj_desktop.jpg"
                                  }
                                  //living_room_width={310}
                                  living_room_width={226}
                                  selected_w={Number(fCtx.wSelected)}
                                  mat_color={fCtx.matColor}
                                  mat_size={Number(fCtx.matSize)}
                                  moulding_width={Number(moulding.width_cm)}
                                  mount={fCtx.mount}
                                  fullscreen={true}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </IonCol>
                      <IonCol size="10" offset="1" size-xl="8" offset-xl="2">
                        <IonItem color="undefined" className="" lines="none">
                          <p
                            className="ion-text-center text-product-long-des"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {moulding.description}
                          </p>
                        </IonItem>
                        {out_dim.out_w_cm && out_dim.out_h_cm && (
                          <React.Fragment>
                            <IonText color="medium shade">
                              <p
                                className="text-arttype-title"
                                style={{
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                              >
                                OEUVRE :{" "}
                              </p>
                            </IonText>
                            <IonText color="dark">
                              <p
                                className="text-arttype"
                                style={{ display: "block", marginTop: "0px" }}
                              >
                                {cleanArtType[fCtx.artType]} {fCtx.wSelected} x{" "}
                                {fCtx.hSelected} cm
                              </p>
                            </IonText>
                            <IonText color="medium shade">
                              <p
                                className="text-arttype-title"
                                style={{
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                              >
                                TAILLE DU CADRE :{" "}
                              </p>
                            </IonText>
                            <IonText color="dark">
                              <p
                                className="text-arttype ion-padding-bottom"
                                style={{ display: "block", marginTop: "0px" }}
                              >
                                {Math.round(Number(out_dim.out_w_cm))} x{" "}
                                {Math.round(Number(out_dim.out_h_cm))} cm
                              </p>
                            </IonText>
                          </React.Fragment>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <IonCol size="6" className="ion-padding-top">
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        size="10"
                        offset="1"
                        size-xl="8"
                        offset-xl="2"
                        style={{
                          borderBottom:
                            "solid 1px var(--ion-color-light-shade)",
                        }}
                        className=" ion-padding-bottom ion-padding-top"
                      >
                        <IonText
                          style={{ fontWeight: "500" }}
                          color="dark"
                          className="ion-text-center"
                        >
                          <h2 style={{ marginBottom: "2px" }}>
                            {moulding.name}
                          </h2>
                          <p style={{ fontWeight: 400, display: "block" }}>
                            {moulding.style}
                          </p>
                        </IonText>
                        {/*out_dim.out_w_cm && out_dim.out_h_cm && (
                          <IonText className="ion-text-center">
                            <p
                              style={{
                                color: "var(--ion-color-medium)",
                                marginTop: "0px",
                                marginBottom: "2rem",
                              }}
                            >
                              {Math.round(Number(out_dim.out_w_cm))} x{" "}
                              {Math.round(Number(out_dim.out_h_cm))} cm
                            </p>
                          </IonText>
                            )*/}
                        {/*fCtx.articleId !== "" && (
                          <IonFab
                            horizontal={"center"}
                            vertical="bottom"
                            color="tertiary"
                            edge
                          >
                            <IonFabButton
                              //activated={showCustomPanel}
                              activated={false}
                              onClick={showCustomPanelHandler}
                              color={showCustomPanel ? "dark" : "primary"}
                            >
                              <IonText className="text-fab-button">
                                <div style={{ display: "block" }}>MO</div>
                                <div style={{ display: "block" }}>DI</div>
                                <div style={{ display: "block" }}>FIER</div>
                              </IonText>
                            </IonFabButton>
                          </IonFab>
                        )*/}
                      </IonCol>
                      <IonCol
                        size="10"
                        offset="1"
                        size-xl="8"
                        offset-xl="2"
                        //className="ion-padding-top"
                      >
                        {fCtx.articleId !== "" ? (
                          <React.Fragment>
                            {/*{!showCustomPanel && (*/}
                            {true && (
                              <React.Fragment>
                                <IonCardContent
                                  style={{
                                    borderBottom:
                                      "solid 1px var(--ion-color-light-shade)",
                                  }}
                                  className=""
                                >
                                  <IonGrid className="ion-no-padding">
                                    <IonRow>
                                      {/*} <CustomDescription
                                        artType={fCtx.artType}
                                        mount={fCtx.mount}
                                        matColor={fCtx.matColor}
                                        matShape={fCtx.matShape}
                                        matSize={Number(fCtx.matSize)}
                                        out_h_cm={Number(out_dim.out_h_cm)}
                                        out_w_cm={Number(out_dim.out_w_cm)}
                                        wSelected={Number(fCtx.wSelected)}
                                        hSelected={Number(fCtx.hSelected)}
                                        showCustomButton={true}
                                        onShowCustomPanelHandler={
                                          showCustomPanelHandler
                                        }
                                      />*/}
                                      <CustomPanel
                                        loadedMoulding={loadedMoulding}
                                        products={products}
                                        changeMouldingHandler={
                                          changeMouldingHandler
                                        }
                                        selectedProductName={
                                          selectedProductName
                                        }
                                        showCustomPanelHandler={
                                          showCustomPanelHandler
                                        }
                                        showInformartionModalHandler={
                                          showInformationModalHandler
                                        }
                                      />
                                    </IonRow>
                                  </IonGrid>
                                </IonCardContent>
                                <IonGrid className="ion-padding-top">
                                  <IonRow>
                                    <IonCol
                                      size="4"
                                      className="ion-text-center"
                                    >
                                      <IonText
                                        style={{
                                          textAlign: "center",
                                          display: "inline-block",
                                          fontSize: "2rem",
                                          fontWeight: "600",
                                        }}
                                      ></IonText>
                                    </IonCol>
                                    <IonCol size="12" className="y-align">
                                      {Number(
                                        priceCtx.getPricePerDim(
                                          Number(fCtx.hSelected) +
                                            Number(fCtx.wSelected)
                                        ).base_price
                                      ) !== -1 ? (
                                        <IonButton
                                          //className="ion-margin"
                                          disabled={isLoading ? true : false}
                                          mode="ios"
                                          expand="full"
                                          color="dark"
                                          onClick={(e) =>
                                            setShowAddCartModal(true)
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <IonLabel className="ion-padding">
                                            {"Passer commande"}
                                          </IonLabel>
                                          {isLoading && (
                                            <IonSpinner
                                              name="crescent"
                                              color="warning"
                                            />
                                          )}
                                        </IonButton>
                                      ) : (
                                        <IonButton
                                          //className="ion-margin"
                                          disabled={isLoading ? true : false}
                                          mode="ios"
                                          expand="full"
                                          color="dark"
                                          //onClick={addToCartHandler}
                                          style={{ width: "100%" }}
                                          href={`mailto:hello@lamafactory.com?subject=Oversized%20Lama&body=Hello%20l%27%C3%A9quipe%20Lama%20%F0%9F%91%8B%0A%0AJe%20souhaiterais%20obtenir%20un%20devis%20pour%20mon%20Lama%20hors%20cat%C3%A9gorie.%0AVoici%20ce%20qui%20m%27int%C3%A9resserait%20%3A%0A%0AR%C3%A9f%C3%A9rence%20article%20%3A%20${fCtx.articleId}%0AType%20d%E2%80%99oeuvre%20%3A%20${fCtx.artType}%0ATaille%20de%20l%E2%80%99oeuvre%20%3A%20${fCtx.wSelected}x${fCtx.hSelected}cm%0ABaguette%20%3A%20${fCtx.mouldingName}%0APersonnalisation%20%3A%20${fCtx.matShape}, ${fCtx.matColor}, ${fCtx.mount} %0A%0AEt%20enfin%20voici%20mon%20adresse%20%3A%0A%0A%3Cvotre%20adresse%20ligne%201%3E%0A%3Cvotre%20code%20postal%3E%20%3Cvotre%20ville%3E%0A%3Cvotre%20pays%3E%0A%0ABisous%20bisous%20%20%F0%9F%98%98%0A%3Cvotre%20nom%3E`}
                                        >
                                          <IonLabel className="ion-padding">
                                            {"Demander mon devis"}
                                          </IonLabel>
                                          {isLoading && (
                                            <IonSpinner
                                              name="crescent"
                                              color="warning"
                                            />
                                          )}
                                        </IonButton>
                                      )}
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </React.Fragment>
                            )}
                            {
                              /*showCustomPanel && (*/
                              false && (
                                <CustomPanel
                                  loadedMoulding={loadedMoulding}
                                  products={products}
                                  changeMouldingHandler={changeMouldingHandler}
                                  selectedProductName={selectedProductName}
                                  showCustomPanelHandler={
                                    showCustomPanelHandler
                                  }
                                  showInformartionModalHandler={
                                    showInformationModalHandler
                                  }
                                />
                              )
                            }
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <IonGrid className="ion-no-padding">
                              <IonRow style={{ paddingBottom: "16px" }}>
                                <IonCol size="12" className="ion-text-center">
                                  <IonText color="primary">
                                    <p className="text-stage">
                                      QU'EST CE QU'ON ENCADRE AUJOURD'HUI?
                                    </p>
                                  </IonText>
                                </IonCol>
                                <IonCol size="4" offset="4">
                                  {" "}
                                  <IonCard
                                    className="ion-text-center"
                                    button
                                    //onClick={e=>selectArtTypeHandler('digital_photo')}
                                    routerLink="/"
                                    routerDirection="forward"
                                    mode="md"
                                  >
                                    <IonImg
                                      src="assets/img/home/digital_photo.png"
                                      style={{
                                        maxWidth: "90px",
                                        display: "block",
                                        margin: "auto",
                                      }}
                                    />
                                    <IonCardContent
                                      style={{ paddingTop: "0px" }}
                                    >
                                      <IonText>
                                        <p className="text-home-card">
                                          PHOTO <br />
                                          DIGITALE
                                        </p>
                                      </IonText>
                                    </IonCardContent>
                                  </IonCard>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </React.Fragment>
                        )}
                      </IonCol>
                      {/*<IonCol size="10" offset="1" size-xl="8" offset-xl="2">
                        <IncludedList />
                                    </IonCol>*/}
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <ProductDetailsDescriptionDesktop
                  moulding_description={moulding.description}
                  moulding_name={moulding.name}
                  moulding_style={moulding.style}
                  out_w_cm={out_dim.out_w_cm ? out_dim.out_w_cm.toString() : ""}
                  out_h_cm={out_dim.out_h_cm ? out_dim.out_h_cm.toString() : ""}
                  scrollToDetails={scrollToDetailsHandler}
                  art_by={moulding.art_by}
                />
              </IonRow>
              <IonRow
                style={{
                  backgroundColor: "var(--ion-color-secondary)",
                  paddingTop: "64px",
                  paddingBottom: "64px",
                }}
              >
                <IonCol
                  size="10"
                  offset="1"
                  size-md="6"
                  offset-md="3"
                  size-lg="4"
                  offset-lg="4"
                  style={{ backgroundColor: "white" }}
                  className="ion-padding"
                >
                  <IncludedList />
                </IonCol>
                <IonCol
                  size="10"
                  offset="1"
                  size-md="6"
                  offset-md="3"
                  size-lg="4"
                  offset-lg="4"
                  style={{ paddingTop: "32px" }}
                  className="ion-padding ion-text-center"
                >
                  {fCtx.articleId !== "" ? (
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" className="ion-text-center">
                          <IonText
                            style={{
                              textAlign: "center",
                              display: "inline-block",
                              fontSize: "2rem",
                              fontWeight: "600",
                            }}
                          ></IonText>
                        </IonCol>
                        <IonCol size="12" className="y-align">
                          {Number(
                            priceCtx.getPricePerDim(
                              Number(fCtx.hSelected) + Number(fCtx.wSelected)
                            ).base_price
                          ) !== -1 ? (
                            <IonButton
                              //className="ion-margin"
                              disabled={isLoading ? true : false}
                              mode="ios"
                              expand="full"
                              color="dark"
                              onClick={(e) => setShowAddCartModal(true)}
                              style={{ width: "100%" }}
                            >
                              <IonLabel className="ion-padding">
                                {"Passer commande"}
                              </IonLabel>
                              {isLoading && (
                                <IonSpinner name="crescent" color="warning" />
                              )}
                            </IonButton>
                          ) : (
                            <IonButton
                              //className="ion-margin"
                              disabled={isLoading ? true : false}
                              mode="ios"
                              expand="full"
                              color="dark"
                              //onClick={addToCartHandler}
                              href="mailto:hello@lamafactory.com?subject=Oversized%20Lama&body=Hello%20l%27%C3%A9quipe%20Lama%20%F0%9F%91%8B%0A%0AJe%20souhaiterais%20obtenir%20un%20devis%20pour%20mon%20Lama%20hors%20cat%C3%A9gorie.%0AVoici%20ce%20qui%20m%27int%C3%A9resserait%20%3A%0A%0AR%C3%A9f%C3%A9rence%20article%20%3A%20article_id%0AType%20d%E2%80%99oeuvre%20%3A%20art_type%0ATaille%20de%20l%E2%80%99oeuvre%20%3A%20wxhcm%0ABaguette%20%3A%20moulding_name%0APersonnalisation%20%3A%20-----%0A%0AEt%20enfin%20voici%20mon%20adresse%20%3A%0A%0A%3Cvotre%20adresse%20ligne%201%3E%0A%3Cvotre%20code%20postal%3E%20%3Cvotre%20ville%3E%0A%3Cvotre%20pays%3E%0A%0ABisous%20bisous%20%20%F0%9F%98%98%0A%3Cvotre%20nom%3E"
                              style={{ width: "100%" }}
                            >
                              <IonLabel className="ion-padding">
                                {"Demander mon devis"}
                              </IonLabel>
                              {isLoading && (
                                <IonSpinner name="crescent" color="warning" />
                              )}
                            </IonButton>
                          )}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  ) : (
                    <IonButton
                      size="large"
                      color="dark"
                      mode="ios"
                      shape="round"
                      class="ion-no-margin"
                      routerLink="/what-we-frame-today"
                      routerDirection="forward"
                      style={{
                        "--padding-start": "48px",
                        "--padding-end": "48px",
                      }}
                    >
                      <IonText>
                        <p className="text-button">
                          JE CRÉE MON ENCADREMENT &nbsp;
                        </p>
                      </IonText>
                      <IonIcon icon={arrowForwardOutline} />
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
              <FooterRows />
            </IonGrid>
          </IonContent>
        )}
        {isPlatform("mobile") && showBuy && !showCustomPanel && (
          <IonFooter className="ion-no-padding ion-no-border">
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol size="12" size-lg="6" offset-lg="6">
                  {fCtx.articleId === "" ? (
                    <IonButton
                      className="ion-no-margin"
                      mode="ios"
                      expand="full"
                      color="primary"
                      size="large"
                      routerLink="/what-we-frame-today"
                      routerDirection="forward"
                    >
                      <p className="text-button">COMMENCER MON ENCADREMENT</p>
                    </IonButton>
                  ) : Number(
                      priceCtx.getPricePerDim(
                        Number(fCtx.hSelected) + Number(fCtx.wSelected)
                      ).base_price
                    ) !== -1 ? (
                    <IonButton
                      className="ion-no-margin"
                      disabled={isLoading ? true : false}
                      mode="ios"
                      expand="full"
                      color="dark"
                      size="large"
                      onClick={(e) => setShowAddCartModal(true)}
                    >
                      <IonText
                        style={{
                          textAlign: "center",
                          display: "inline-block",
                          fontWeight: "600",
                        }}
                      >
                        <React.Fragment>
                          <p style={{ display: "inline-block" }}>
                            Passer commande &nbsp; &nbsp;
                          </p>
                        </React.Fragment>
                      </IonText>
                      {isLoading && (
                        <IonSpinner name="crescent" color="warning" />
                      )}
                    </IonButton>
                  ) : (
                    <IonButton
                      className="ion-no-margin"
                      disabled={isLoading ? true : false}
                      mode="ios"
                      expand="full"
                      color="dark"
                      size="large"
                      //onClick={addToCartHandler}
                      href="mailto:hello@lamafactory.com?subject=Oversized%20Lama&body=Hello%20l%27%C3%A9quipe%20Lama%20%F0%9F%91%8B%0A%0AJe%20souhaiterais%20obtenir%20un%20devis%20pour%20mon%20Lama%20hors%20cat%C3%A9gorie.%0AVoici%20ce%20qui%20m%27int%C3%A9resserait%20%3A%0A%0AR%C3%A9f%C3%A9rence%20article%20%3A%20article_id%0AType%20d%E2%80%99oeuvre%20%3A%20art_type%0ATaille%20de%20l%E2%80%99oeuvre%20%3A%20wxhcm%0ABaguette%20%3A%20moulding_name%0APersonnalisation%20%3A%20-----%0A%0AEt%20enfin%20voici%20mon%20adresse%20%3A%0A%0A%3Cvotre%20adresse%20ligne%201%3E%0A%3Cvotre%20code%20postal%3E%20%3Cvotre%20ville%3E%0A%3Cvotre%20pays%3E%0A%0ABisous%20bisous%20%20%F0%9F%98%98%0A%3Cvotre%20nom%3E"
                    >
                      <IonText
                        style={{
                          textAlign: "center",
                          display: "inline-block",
                          fontWeight: "600",
                        }}
                      >
                        <p>Demander mon devis</p>
                      </IonText>
                      {isLoading && (
                        <IonSpinner name="crescent" color="warning" />
                      )}
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonFooter>
        )}
      </IonPage>
    </React.Fragment>
  );
};
export default ProductDetailsTech2;
