import React from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonIcon,
} from "@ionic/react";

import { closeOutline } from "ionicons/icons";


const ResetPasswordModal: React.FC<{
  show: boolean;
  email: string;
  onDismiss: () => {};
}> = (props) => {
  return (
    <IonModal isOpen={props.show} backdropDismiss={false}>
      <IonHeader className="ion-no-border">
        <IonToolbar color="undefined" className="">
          <IonButtons slot="end">
            <IonButton size='large' color='dark' onClick={props.onDismiss} fill="clear">
              <IonIcon size='large' icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="undefined">
        <IonGrid>
          <IonRow class="ion-text-center">
            <IonCol size="12" className="ion-padding-bottom">
              <img
                src="assets/img/lama/lamail.png"
                width="120px"
                alt="Cadre sur mesure Lama Factory commandé"
              />
            </IonCol>
            <IonCol size="12" className="ion-padding ion-text-center">
              <IonLabel color="dark">
                <h1 style={{ fontWeight: "bold" }}>
                  Un e-mail de ré-initialisation vous a été envoyée
                </h1>
              </IonLabel>
              <IonLabel color="dark">
                <h2>
                  <br />
                  à
                  <br />
                  <br />
                </h2>
                <h2><strong>{props.email}</strong></h2>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default ResetPasswordModal;
