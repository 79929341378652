import React, { useContext } from "react";
import {
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonThumbnail,
  isPlatform,
  IonIcon,
  IonImg,
} from "@ionic/react";
import fContext from "../../context/f-context";
import { useHistory } from "react-router";
import { chevronForwardOutline } from "ionicons/icons";
import cleanArtType from '../../data/cleanType'

const WizardLama: React.FC = () => {
  const fCtx = useContext(fContext);
  const history = useHistory();

  const deleteLamaHandler = () => {
    history.push("/build-digital");
    fCtx.deleteFrame();
    fCtx.selectArtType("digital_photo");
    fCtx.selectMatColor("#FEFEFE");
    fCtx.selectMatStyle("standard");
    fCtx.selectMatShape("standard");
    fCtx.selectMatSize(8);
    fCtx.selectMount("simple");
    fCtx.selectSelectedStyle("standard");
  };


  return (
    <IonRow style={{ padding: "8px", backgroundColor: isPlatform('mobile')?"var(--ion-color-light)":"white" }}>
      <IonCol
        size="12"
        size-md="10"
        className={isPlatform('desktop')?"ion-align-self-center ion-padding-start":"ion-align-self-center "}
      >
        {isPlatform("desktop") && (
          <IonText color='dark' className='ion-text-wrap hidden-md-down'>
            <p
              className="text-wizard"
              style={{ paddingRight: "8px", display: "inline-block" }}
            >
              VOTRE LAMA EN COURS :
            </p>
          </IonText>
        )}
          <IonText color='dark' className='ion-text-wrap hidden-lg-up'>
            <p
              className="text-wizard-mobile"
            >
              VOTRE LAMA EN COURS :
            </p>
          </IonText>
        {fCtx.artType && fCtx.artType !== "" && (
          <IonButton
            style={{
              display: "inline-block",
              paddingRight: "16px",
            }}
            size="small"
            mode="ios"
            shape="round"
            color="dark"
            fill="outline"
          >
            <IonText className="text-product-tag">
              {cleanArtType[fCtx.artType]}
            </IonText>
          </IonButton>
        )}
        {fCtx.imageUrl !== "" && fCtx.imageUrl !== "local" && (
          <React.Fragment>
          <IonText>
            <p style={{ verticalAlign: "sub", display: "inline-block", paddingRight: "16px", }}>
              <IonIcon icon={chevronForwardOutline} />
            </p>
          </IonText>
            <IonButton
              style={{
                display: "inline-block",
                paddingRight: "16px",
              }}
              size="small"
              mode="ios"
              shape="round"
              color="dark"
              fill="outline"
              routerDirection="back"
              routerLink="/build-digital"
            >
              <IonThumbnail
                slot="start"
                style={{
                  width: "16px",
                  height: "16px",
                  "--border-radius": "100%",
                }}
              >
                <IonImg style={{margin:'auto'}} src={fCtx.imageUrl.includes("uploads/images")? process.env.REACT_APP_BACKEND_URL + fCtx.imageUrl:fCtx.imageUrl} />
              </IonThumbnail>
              <IonText className="text-product-tag">
                &nbsp; &nbsp;Éditer image
              </IonText>
            </IonButton>
          </React.Fragment>
        )}
        {fCtx.wSelected !== 0 && fCtx.hSelected !== 0 && (
          <React.Fragment>
            <IonText>
              <p style={{ verticalAlign: "sub", display: "inline-block", paddingRight: "16px", }}>
                <IonIcon icon={chevronForwardOutline} />
              </p>
            </IonText>
            <IonButton
              style={{
                display: "inline-block",
                paddingRight: "16px",
              }}
              size="small"
              mode="ios"
              shape="round"
              color="dark"
              fill="outline"
              routerDirection="back"
              routerLink="/build-digital"
            >
              <IonText className="text-product-tag">
                {fCtx.wSelected} x {fCtx.hSelected} cm
              </IonText>
            </IonButton>
          </React.Fragment>
        )}
      </IonCol>
      <IonCol size="12" size-md="2" className="ion-text-right">
        <IonButton
          onClick={deleteLamaHandler}
          size="small"
          fill="clear"
          mode="ios"
          color="primary"
        >
          <IonText>
            <p className={isPlatform('desktop')?"text-button":"text-wizard-redo-mobile"} >RECOMMENCEZ</p>
          </IonText>
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default WizardLama;
