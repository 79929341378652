import React, { useRef, useState, useEffect } from "react";
import { phonePortraitOutline, laptopOutline } from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonText,
  IonSpinner,
  isPlatform,
  IonCard,
  IonCardContent,
} from "@ionic/react";

const ImageUpload: React.FC<{
  id: string;
  onInput: (id: string, file: File | undefined, isValid: boolean) => {};
  onFileRead: (previewUrl: string) => {};
}> = (props) => {
  const filePickerRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<Blob | undefined>();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [isValid, setIsValid] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!file) {
      return;
    }
    setIsImageLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result!.toString());
      props.onFileRead(fileReader.result!.toString());
    };
    fileReader.readAsDataURL(file);
    setIsImageLoading(false);
  }, [file]);

  const pickImageHandler = () => {
    filePickerRef.current!.click();
  };
  const pickedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    //check file size here as well
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target!.files![0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = isValid;
    } else {
      setIsValid(false);
      fileIsValid = isValid;
    }
    props.onInput(props.id, pickedFile, fileIsValid);
  };
  return (
    <React.Fragment>
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept=".jpg, .png, .jpeg"
        onChange={pickedHandler}
        className='ion-text-center'
      />
      {/*previewUrl && <img src={previewUrl} alt="preview" />*/}
      {(previewUrl || isImageLoading) && (
        <React.Fragment>
          <IonText className="ion-padding-bottom ion-text-center" color="dark">
            Chargement
          </IonText>
          <IonSpinner
            className="ion-padding-top"
            color="primary"
            name="crescent"
          />
        </React.Fragment>
      )}
      {!previewUrl && (
        <React.Fragment>
          {" "}
          {
            <IonCard disabled={isImageLoading} onClick={pickImageHandler} className="ion-text-center" button color={"undefined"}>
              <IonIcon
                className="iconSize ion-padding-top ion-margin-top"
                icon={
                  isPlatform("mobile") ? phonePortraitOutline : laptopOutline
                }
              />
              <IonCardContent style={{ paddingTop: "8px" }}>
                <IonText className="text-home-card">
                  <p className="ion-padding-bottom text-home-card">
                    {isPlatform("mobile") ? "Importer depuis mon Téléphone" : "Importer depuis mon Ordinateur"}
                  </p>
                </IonText>
              </IonCardContent>
            </IonCard>
          }
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ImageUpload;
