import React from "react";
import {
  IonCol,
  IonSkeletonText,
  IonText,
  IonCard,
  IonGrid,
  IonRow,
} from "@ionic/react";

const GallerySkeleton: React.FC = () => {
  return (
    <IonCol size="12" size-sm="6" size-md="4" size-lg="4">
      <IonCard mode="md">
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol size="12">
              <div className="ion-padding-start ion-text-center ion-padding-end">
                <IonSkeletonText style={{ height: "200px" }} animated />
              </div>
              <div className="ion-text-start ion-padding-start ion-padding-end">
                <IonText color="dark">
                  <h2 style={{ fontWeight: "bold" }}>
                    <IonSkeletonText animated />
                  </h2>
                </IonText>
                <IonText color="dark">
                  <IonSkeletonText animated />
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    </IonCol>
  );
};

export default GallerySkeleton;
