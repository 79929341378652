import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  isPlatform,
  IonLoading,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/home.css";
import "./theme/variables.css";
import "./theme/theme.css";

/*Context & Hooks loading */
import FContextProvider from "./context/fContextProvider";
import AuthContextProvider from "./context/authContextProvider";
import AuthContext from "./context/auth-context";
import CContextProvider from "./context/cartContextProvider";
import PriceContextProvider from "./context/priceContextProvider";
import { useAuth } from "./hooks/auth-hook";

/* Shop app pages*/
import SideDrawer from "./components/shared/SideDrawer";
import Home from "./pages/shop/home";
import WhatWeFrameFrameToday from "./pages/shop/what-we-frame-today";
import BuildDigital from "./pages/shop/build-digital";
import BuildSize from "./pages/shop/build-size";
import Gallery from "./pages/shop/gallery";
import ProductDetailsTech2 from "./pages/shop/product-details-tech2";

/* Account pages */
import Login from "./pages/account/login";
import Register from "./pages/account/register";

/*Order pages */
import Cart from "./pages/order/cart";
//import UserInfo from "./pages/order/user-info";
//import UserSignup from "./pages/order/user-signup";
//import UserLogin from "./pages/order/user-login";
//import ShippingAddress from "./pages/order/shipping-address";
//import CollectionMethod from "./pages/order/collection-method";
//import ShippingMethod from "./pages/order/shipping-method";
//import PaymentMethod from "./pages/order/payment";


/* Description pages*/
import Guide from "./pages/shop/guide";
//import KnowHow from "./pages/shop/know-how";
//import Services from "./pages/shop/services";
import Pricing from "./pages/shop/pricing";
//import OurMission from "./pages/shop/our-mission";
//import HowItWorks from "./pages/shop/how-it-works";
//import Reset from "./pages/account/reset";
import CookieModal from "./components/shared/CookieModal";

const UserInfo = React.lazy(()=>import("./pages/order/user-info"));
const UserSignup = React.lazy(()=>import("./pages/order/user-signup"));
const UserLogin = React.lazy(()=>import("./pages/order/user-login"));
const ShippingAddress = React.lazy(()=>import("./pages/order/shipping-address"));
const CollectionMethod = React.lazy(()=>import("./pages/order/collection-method"));
const ShippingMethod = React.lazy(()=>import("./pages/order/shipping-method"));
const PaymentMethod = React.lazy(()=>import("./pages/order/payment"));


const Trade = React.lazy(() => import("./pages/shop/trade"));
const FAQ = React.lazy(() => import("./pages/shop/faq"));
const Legal = React.lazy(() => import("./pages/shop/legal"));
const Orders = React.lazy(() => import("./pages/account/orders"));

const Reset = React.lazy(() => import("./pages/account/reset"));
const HowItWorks = React.lazy(() => import("./pages/shop/how-it-works"));
const OurMission = React.lazy(() => import("./pages/shop/our-mission"));
const Services = React.lazy(() => import("./pages/shop/services"));
const KnowHow = React.lazy(() => import("./pages/shop/know-how"));

const App: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const { token, login, logout, userId } = useAuth();
  let routes;

  useEffect(() => {
    if (isPlatform("mobile")) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }, []);

  if (token) {
    routes = (
      <IonRouterOutlet id="main">
         <Route path="/what-we-frame-today" exact>
          <WhatWeFrameFrameToday />
        </Route>
        <Route path="/legal" exact>
          <Legal />
        </Route>
        <Route path="/trade" exact>
          <Trade />
        </Route>
        <Route path="/faq" exact>
          <FAQ />
        </Route>
        <Route path="/how-it-works" exact>
          <HowItWorks />
        </Route>
        <Route path="/our-mission" exact>
          <OurMission />
        </Route>
        <Route path="/pricing" exact>
          <Pricing />
        </Route>
        <Route path="/services" exact>
          <Services />
        </Route>
        <Route path="/guide" exact>
          <Guide />
        </Route>
        <Route path="/know-how" exact>
          <KnowHow />
        </Route>
        <Route path="/build-digital" exact>
          <BuildDigital />
        </Route>
        <Route path="/build-size" exact>
          <BuildSize />
        </Route>
        <Route path="/gallery" exact>
          <Gallery />
        </Route>
        <Route path="/order/:orderId/collectionMethod">
          <CollectionMethod />
        </Route>
        <Route path="/order/:orderId/login">
          <UserLogin />
        </Route>
        <Route path="/product/:productName">
          <ProductDetailsTech2 />
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/order/:orderId/info">
          <UserInfo />
        </Route>
        <Route path="/order/:orderId/shippingAddress">
          <ShippingAddress />
        </Route>
        <Route path="/order/:orderId/signup">
          <UserSignup />
        </Route>
        <Route path="/order/:orderId/shippingMethod">
          <ShippingMethod />
        </Route>
        <Route path="/order/:orderId/payment">
          <PaymentMethod />
        </Route>
        <Route path="/account/reset/:token">
          <Reset />
        </Route>
        <Route path="/account/orders">
          <Orders />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </IonRouterOutlet>
    );
  } else {
    routes = (
      <IonRouterOutlet id="main" mode='ios'>
        <Route path="/what-we-frame-today" exact>
          <WhatWeFrameFrameToday />
        </Route>
        <Route path="/legal" exact>
          <Legal />
        </Route>
        <Route path="/trade" exact>
          <Trade />
        </Route>
        <Route path="/faq" exact>
          <FAQ />
        </Route>
        <Route path="/how-it-works" exact>
          <HowItWorks />
        </Route>
        <Route path="/our-mission" exact>
          <OurMission />
        </Route>
        <Route path="/pricing" exact>
          <Pricing />
        </Route>
        <Route path="/services" exact>
          <Services />
        </Route>
        <Route path="/guide" exact>
          <Guide />
        </Route>
        <Route path="/know-how" exact>
          <KnowHow />
        </Route>
        <Route path="/build-digital" exact>
          <BuildDigital />
        </Route>
        <Route path="/build-size" exact>
          <BuildSize />
        </Route>
        <Route path="/gallery" exact>
          <Gallery />
        </Route>
        <Route path="/order/:orderId/collectionMethod">
          <CollectionMethod />
        </Route>
        <Route path="/order/:orderId/login">
          <UserLogin />
        </Route>
        <Route path="/product/:productName">
          <ProductDetailsTech2 />
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/order/:orderId/info">
          <UserInfo />
        </Route>
        <Route path="/order/:orderId/shippingAddress">
          <ShippingAddress />
        </Route>
        <Route path="/order/:orderId/signup">
          <UserSignup />
        </Route>
        <Route path="/order/:orderId/shippingMethod">
          <ShippingMethod />
        </Route>
        <Route path="/order/:orderId/payment">
          <PaymentMethod />
        </Route>
        <Route path="/account/reset/:token">
          <Reset />
        </Route>
        <Route path="/account/orders">
          <Orders />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </IonRouterOutlet>
    );
  }

  return (
    <IonApp>
      <IonReactRouter>
        <AuthContextProvider>
          <PriceContextProvider>
            <CContextProvider>
              <FContextProvider>
                <React.Suspense
                  fallback={
                    <IonLoading
                      //cssClass='my-custom-class'
                      isOpen={true}
                      message={"Chargement..."}
                    />
                  }
                >
                  <SideDrawer />
                  <CookieModal/>
                  <main>{routes}</main>
                </React.Suspense>
              </FContextProvider>
            </CContextProvider>
          </PriceContextProvider>
        </AuthContextProvider>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
