import React from "react";

interface Context {
  isLoggedIn: boolean;
  userId: string | null;
  token: string | null;
  code: string | null;
  tokenExpirationDate: Date | null;
  cookieAgreement: boolean;
  setCode: (code: string) => void;
  login: (uid: string, token: string, expirationDate?: Date) => void;
  logout: () => void;
  acceptCookieAgreement: () => void;
}

const AuthContext = React.createContext<Context>({
  isLoggedIn: false,
  userId: null,
  token: null,
  code: null,
  tokenExpirationDate: null,
  cookieAgreement: false,
  setCode: () => {},
  login: () => {},
  logout: () => {},
  acceptCookieAgreement: () => {},
});

export default AuthContext;
