import React, { useState, useRef, useContext, useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonCard,
  IonImg,
  IonCardContent,
  IonButton,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import FooterRows from "../../components/shared/FooterRows";
import fContext from "../../context/f-context";
import { useHistory, useLocation } from "react-router";
import ProgressiveImage from "react-progressive-graceful-image";

const WhatWeFrameFrameToday: React.FC = () => {
  const digital_photo_title = "Imprimez gratuitement, encadrez librement ";
  const digital_photo_p1 =
    "Nous réalisons les tirages de vos plus beaux souvenirs en qualité galerie et nous les encadrons.";
  const digital_photo_p2 = "";
  const digital_photo_p3 = "Alors, comment ça marche ?";

  const art_on_paper_title = "Encadrez une oeuvre sur papier";
  const art_on_paper_p1 =
    "Nous collectons votre oeuvre gratuitement par voie postale (tube ou plis) et nos artisans réalisent son encadrement dans notre atelier. Le tout à la main et à partir de matériaux durables pour un Lama unique.";
  const art_on_paper_p2 = "";
  const art_on_paper_p3 =
    "Ci-dessous une liste non exhaustive des oeuvres sur papier que nous encadrons:";

  const document_title = "Encadrez un document";
  const document_p1 = "";
  const document_p2 =
    "Nous collectons votre document ou votre photo originale gratuitement par voie postale (boîte de transport adaptée). Puis nos artisans réalisent son encadrement dans notre atelier comme vous l'avez imaginé.";
  const document_p3 =
    "Ci-dessous une liste non exhaustive des documents que nous avons encadrés à date : ";

  const textil_title = "Encadrez du textile";
  const textil_p1 =
    "Oui oui, encadrer du textile c'est possible. Nos artisans réalisent un encadrement adapté, la plupart du temps en cousant délicatement le textile à la main sur le carton de fond.";
  const textil_p2 = "";
  const textil_p3 =
    "Ci-dessous une liste non exhaustive des textiles que l’on a eu la chance d’encadrer: ";

  const canvas_title = "Encadrer une toile/canevas";
  const canvas_p1 =
    "Créer en quelques clics des encadrements sur mesure uniques pour tous vos toiles et canevas.";
  const canvas_p2 =
    "Nous collectons votre oeuvre gratuitement par voie postale (boîte de transport adaptée) et nos artisans réalisent l’encadrement adaptée à votre oeuvre, le tout à la main et à partir de matériaux durables pour un rendu toujours au top.";
  const canvas_p3 =
    "Si votre toile n’est pas encore tendue, nous réalisons même la tension sur un châssis en pin massif";

  const object_title = "Encadrez un objet";
  const object_p1 =
    "Oui nous faisons cela aussi. Tous les objets jusqu'à 5cm d'épaisseur, nous pouvons les encadrer. Nous collectons gratuitement par voie postale votre sujet original puis nous réalisons un encadrement adapté dans notre atelier, et il n'y a plus qu'à accrocher !";
  const object_p2 = "";
  const object_p3 =
    "Ci-dessous une liste non exhaustive des objets que l’on a eu l’honneur d’encadrer: ";

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const fCtx = useContext(fContext);
  const history = useHistory();
  const query = useQuery();
  const query_type = query.get("art_type");
  const query_show_card = query.get("show_card");
  const artSliderRef = useRef<HTMLIonSlidesElement>(null);
  const artRowRef = useRef<HTMLIonColElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [artType, setArtType] = useState<string>(
    query_type ? query_type : "digital_photo"
  );
  const [showCard, setShowCard] = useState<string>(
    query_show_card ? query_show_card : "true"
  );
  const [canvasMount, setCanvasMount] = useState<string>("mounted_canvas");
  const [artTitle, setArtTitle] = useState<string>(digital_photo_title);
  const [p1, setP1] = useState<string>(digital_photo_p1);
  const [p2, setP2] = useState<string>(digital_photo_p2);
  const [p3, setP3] = useState<string>(digital_photo_p3);

  const changeWallHandler = (art_type: string) => {
    setArtType(art_type);
    let y = artRowRef.current ? artRowRef.current.offsetTop : 300;
    contentRef.current && contentRef.current.scrollToPoint(0, y, 500);
  };

  const selectArtTypeHandler = (art_type_selected: string) => {
    if (art_type_selected === "canvas") {
      fCtx.selectArtType(canvasMount);
    } else {
      fCtx.selectArtType(art_type_selected);
    }
    if (art_type_selected === "canvas") {
      fCtx.selectMount("canvas");
      fCtx.selectMatStyle("standard");
      fCtx.selectMatSize(-2);
    } else {
      fCtx.selectMatColor("#FEFEFE");
      fCtx.selectMatStyle("standard");
      fCtx.selectMatShape("standard");
      fCtx.selectMatSize(8);
      fCtx.selectMount("simple");
      fCtx.selectSelectedStyle("standard");
    }
    history.push("/build-digital");
    return true;
  };

  const artSlideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    speed: 400,
    loop: true,
  };

  const nextArtHandler = () => {
    artSliderRef.current!.slideNext(300);
  };

  const previousArtHandler = () => {
    artSliderRef.current!.slidePrev(300);
  };

  const scrollToDescriptionHandler = () => {
    let y = artRowRef.current ? artRowRef.current.offsetTop : 300;
    if (contentRef.current !== null) {
      contentRef.current.scrollToPoint(0, y, 500);
    }
  };

  useEffect(() => {
    if (query_type && query_type !== "") {
      setArtType(query_type);
    }
  }, [query_type]);

  useEffect(() => {
    if (query_show_card && query_show_card !== "") {
      setShowCard(query_show_card);
    }
  }, [query_show_card]);

  useEffect(() => {
    switch (artType) {
      case "digital_photo": {
        setArtTitle(digital_photo_title);
        setP1(digital_photo_p1);
        setP2(digital_photo_p2);
        setP3(digital_photo_p3);
        //slider change
        break;
      }
      case "art_on_paper": {
        setArtTitle(art_on_paper_title);
        setP1(art_on_paper_p1);
        setP2(art_on_paper_p2);
        setP3(art_on_paper_p3);
        //slider change
        break;
      }
      case "document": {
        setArtTitle(document_title);
        setP1(document_p1);
        setP2(document_p2);
        setP3(document_p3);
        //slider change
        break;
      }
      case "canvas": {
        setArtTitle(canvas_title);
        setP1(canvas_p1);
        setP2(canvas_p2);
        setP3(canvas_p3);
        //slider change
        break;
      }
      case "textil": {
        setArtTitle(textil_title);
        setP1(textil_p1);
        setP2(textil_p2);
        setP3(textil_p3);
        //slider change
        break;
      }
      case "object": {
        setArtTitle(object_title);
        setP1(object_p1);
        setP2(object_p2);
        setP3(object_p3);
        //slider change
        break;
      }
      default: {
        setArtTitle(digital_photo_title);
        setP1(digital_photo_p1);
        setP2(digital_photo_p2);
        setP3(digital_photo_p3);
        //slider change
        break;
      }
    }
  }, [artType]);

  return (
    <IonPage>
      <IonHeader>
        <DesktopToolbar />
      </IonHeader>
      <IonContent ref={contentRef}>
        <IonGrid className="ion-no-padding">
          {showCard === "true" && (
            <IonRow style={{ background: "white" }}>
              <IonCol size="12" className="ion-text-center">
                <IonText color="primary">
                  <p
                    style={{ paddingTop: "24px", marginBottom: "0px" }}
                    className="text-stage"
                  >
                    QU'EST CE QU'ON ENCADRE AUJOURD'HUI?
                  </p>
                </IonText>
              </IonCol>
              <IonCol
                size="4"
                size-md="2"
                offset-lg="0.9"
                size-lg="1.7"
                offset-xl="1.5"
                size-xl="1.5"
              >
                <IonCard
                  //onMouseEnter={(e) => changeWallHandler("digital_photo")}
                  onClick={(e) => changeWallHandler("digital_photo")}
                  className="ion-text-center"
                  button
                  color={artType === "digital_photo" ? "dark" : "undefined"}
                  mode="md"
                >
                  <IonImg
                    src="assets/img/home/digital_photo.png"
                    style={{
                      maxWidth: "130px",
                      display: "block",
                      margin: "auto",
                    }}
                    className={artType === "digital_photo" ? "img-invert" : ""}
                  />
                  <IonCardContent style={{ paddingTop: "0px" }}>
                    <IonText>
                      <p className="text-home-card">
                        PHOTO <br />
                        DIGITALE
                      </p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol
                color="light"
                size="4"
                size-md="2"
                size-lg="1.7"
                size-xl="1.5"
              >
                <IonCard
                  //onMouseEnter={(e) => changeWallHandler("art_on_paper")}
                  onClick={(e) => changeWallHandler("art_on_paper")}
                  className="ion-text-center"
                  button
                  color={artType === "art_on_paper" ? "dark" : "undefined"}
                  mode="md"
                >
                  <IonImg
                    src="assets/img/home/art_on_paper.png"
                    style={{
                      maxWidth: "130px",
                      display: "block",
                      margin: "auto",
                    }}
                    className={artType === "art_on_paper" ? "img-invert" : ""}
                  />
                  <IonCardContent style={{ paddingTop: "0px" }}>
                    <IonText className="text-home-card">
                      <p className="text-home-card">
                        ART SUR <br /> PAPIER
                      </p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol
                color="light"
                size="4"
                size-md="2"
                size-lg="1.7"
                size-xl="1.5"
              >
                <IonCard
                  //onMouseEnter={(e) => changeWallHandler("document")}
                  onClick={(e) => changeWallHandler("document")}
                  className="ion-text-center"
                  button
                  color={artType === "document" ? "dark" : "undefined"}
                  mode="md"
                >
                  <IonImg
                    src="assets/img/home/document.png"
                    style={{
                      maxWidth: "130px",
                      display: "block",
                      margin: "auto",
                    }}
                    className={artType === "document" ? "img-invert" : ""}
                  />
                  <IonCardContent style={{ paddingTop: "0px" }}>
                    <IonText className="text-home-card">
                      <p className="text-home-card">
                        DOCUMENT
                        <br />& PHOTO
                      </p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol
                color="light"
                size="4"
                size-md="2"
                size-lg="1.7"
                size-xl="1.5"
              >
                <IonCard
                  //onMouseEnter={(e) => changeWallHandler("textil")}
                  onClick={(e) => changeWallHandler("textil")}
                  className="ion-text-center"
                  button
                  color={artType === "textil" ? "dark" : "undefined"}
                  mode="md"
                >
                  <IonImg
                    src="assets/img/home/jersey.png"
                    style={{
                      maxWidth: "130px",
                      display: "block",
                      margin: "auto",
                    }}
                    className={artType === "textil" ? "img-invert" : ""}
                  />
                  <IonCardContent style={{ paddingTop: "0px" }}>
                    <IonText className="text-home-card">
                      <p className="text-home-card">
                        TEXTILE & <br />
                        MAILLOT
                      </p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol
                color="light"
                size="4"
                size-md="2"
                size-lg="1.7"
                size-xl="1.5"
              >
                <IonCard
                  //onMouseEnter={(e) => changeWallHandler("canvas")}
                  onClick={(e) => changeWallHandler("canvas")}
                  className="ion-text-center"
                  button
                  color={artType === "canvas" ? "dark" : "undefined"}
                  mode="md"
                >
                  <IonImg
                    src="assets/img/home/canvas.png"
                    style={{
                      maxWidth: "130px",
                      display: "block",
                      margin: "auto",
                    }}
                    className={artType === "canvas" ? "img-invert" : ""}
                  />
                  <IonCardContent style={{ paddingTop: "0px" }}>
                    <IonText className="text-home-card">
                      <p className="text-home-card">
                        TOILE & <br />
                        CANEVAS
                      </p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol
                color="light"
                size="4"
                size-md="2"
                size-lg="1.7"
                size-xl="1.5"
              >
                <IonCard
                  //onMouseEnter={(e) => changeWallHandler("object")}
                  onClick={(e) => changeWallHandler("object")}
                  className="ion-text-center"
                  button
                  color={artType === "object" ? "dark" : "undefined"}
                  mode="md"
                >
                  <IonImg
                    src="assets/img/home/object.png"
                    style={{
                      maxWidth: "130px",
                      display: "block",
                      margin: "auto",
                    }}
                    className={artType === "object" ? "img-invert" : ""}
                  />
                  <IonCardContent style={{ paddingTop: "0px" }}>
                    <IonText className="text-home-card">
                      <p className="text-home-card">
                        OBJET & <br />
                        CO.
                      </p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          )}
          <IonRow style={{ paddingTop: "32px", paddingBottom: "64px" }}>
            <IonCol ref={artRowRef} size="12" size-md="9" offset-md="1.5">
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <ProgressiveImage
                      src={"assets/img/what-we-frame-today/" + artType + ".jpg"}
                      placeholder={
                        "assets/img/what-we-frame-today/" + artType + "_tn.jpg"
                      }
                    >
                      {(src: string | undefined, loading: any) => (
                        <img
                          style={{
                            width: "1000px",
                            maxWidth: "100% !important",
                            filter: loading ? "blur(7px)" : "",
                          }}
                          src={src}
                          alt="an image"
                        />
                      )}
                    </ProgressiveImage>
                  </IonCol>
                  <IonCol
                    className="ion-padding-start ion-padding-end y-align"
                    offset='0.2'
                    size="11.8"
                    size-md="5.8"
                  >
                    <IonText>
                      <h1 className="ion-padding-bottom ion-margin-bottom">
                        {artTitle}
                      </h1>
                      <p className="ion-padding-bottom">{p1}</p>
                      {p2 !== "" && <p className="ion-padding-bottom">{p2}</p>}
                      <p>{p3}</p>
                      {artType === "art_on_paper" && (
                        <p style={{ paddingLeft: "16px" }}>
                          • Dessins, esquisses, fusains, dessins d’enfants etc.
                          <br />
                          <br />• Peintures, aquarelles, acryliques, gouaches
                          etc.
                          <br />
                          <br />• Affiches, posters, prints fine-art etc.
                          <br />
                          <br />• Gravures, estampes, lithographies etc.
                        </p>
                      )}
                      {artType === "textil" && (
                        <p style={{ paddingLeft: "16px" }}>
                          • Maillots de sport (foot, rubgy etc.), comme le
                          premier maillot de Karim Benzema au Real Madrid (oui,
                          c’est vrai)
                          <br />
                          <br />• Dentelles, tricots, body et bavoirs de bébé
                          <br />
                          <br />• Chaussons de danse, chaussons de naissance
                          <br />
                          <br />• Sac de café en toile de jute (du Brésil)
                        </p>
                      )}
                      {artType === "object" && (
                        <React.Fragment>
                          <p
                            style={{ paddingLeft: "16px" }}
                            className="ion-padding-bottom"
                          >
                            • Couverts, assiettes, porcelaine
                            <br />• Bouchons en liège, bouteilles de vin (oui,
                            c’est possible)
                            <br />• Une couronne de mariée, un vinyle, un stylo,
                            une pipe, des figurines en plâtre
                            <br />• Une flèche d’indien, des médailles, un
                            silex, des coquillages, du bois flotté etc.
                          </p>
                          <p>
                            À vrai dire, plus c'est original, plus on aime.
                            Alors à vous de jouer !
                          </p>
                        </React.Fragment>
                      )}
                      {artType === "document" && (
                        <React.Fragment>
                          <p
                            style={{ paddingLeft: "16px" }}
                            className="ion-padding-bottom"
                          >
                            • Cartes et plans, billets de banque, de train, de
                            bus, d'avion, et timbres
                            <br />
                            <br />• Magazines, articles de presse, unes de
                            journaux, livres, pages de livres
                            <br />
                          </p>
                          <p>Bref, les possibilités sont infinies.</p>
                        </React.Fragment>
                      )}
                      {artType === "digital_photo" && (
                        <React.Fragment>
                          <p
                            style={{ paddingLeft: "16px" }}
                            className="ion-padding-bottom"
                          >
                            1- Téléchargez la photo que vous souhaitez encadrer
                            <br /> <br />
                            2- Choisissez la taille d’impression - ici pas de
                            formats standards, vous décidez au mm près
                            <br /> <br />
                            3- Personnalisez votre cadre: baguette,
                            passe-partout, montage etc… tout est 100% sur mesure
                            <br /> <br />
                            4- Recevez directement chez vous votre création
                            unique
                          </p>
                          <p style={{ paddingTop: "8px" }}>
                            Simple, rapide, 100% unique - vous allez adorer.
                          </p>
                        </React.Fragment>
                      )}
                      {artType === "canvas" && (
                        <IonRadioGroup
                          value={canvasMount}
                          onIonChange={(e) => setCanvasMount(e.detail.value)}
                        >
                          <IonItem lines="none">
                            <IonText>
                              <p>Ma toile est déjà montée sur châssis</p>{" "}
                            </IonText>
                            <IonRadio
                              mode="md"
                              slot="start"
                              value="mounted_canvas"
                            />
                          </IonItem>
                          <IonItem lines="none">
                            <IonText>
                              <p>
                                Ma toile n’est pas encore montée sur châssis
                              </p>
                            </IonText>
                            <IonRadio
                              mode="md"
                              slot="start"
                              value="unmounted_canvas"
                            />
                          </IonItem>
                        </IonRadioGroup>
                      )}
                    </IonText>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="ion-text-center ion-padding-top ion-margin-top"
                  >
                    <IonButton
                      size="large"
                      fill="solid"
                      color="dark"
                      mode="ios"
                      shape="round"
                      className="text-button"
                      style={{ height: "48px" }}
                      onClick={(e) => selectArtTypeHandler(artType)}
                    >
                      JE CRÉE MON ENCADREMENT
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          <FooterRows />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default WhatWeFrameFrameToday;
