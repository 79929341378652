import React, { useState, useContext, useEffect } from "react";
import { getDivMulti } from "../../util/frame";
import {
  IonPage,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonImg,
  IonCard,
  isPlatform,
  IonBadge,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import FramePreview from "../../components/shared/FramePreview";
import DeleteLamAlert from "../../components/shared/DeleteLamAlert";
import fContext from "../../context/f-context";
import { useHttpClient } from "../../hooks/http-hook";
import LivingPreview from "../../components/shared/LivingPreview";
import { useHistory } from "react-router";
import PriceContext from "../../context/price-context";
import GallerySkeleton from "../../components/shop/GallerySkeleton";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import FooterRows from "../../components/shared/FooterRows";
import WizardLama from "../../components/shop/WizardLama";
import HidingHeader from "../../components/shared/HidingHeader";
import { useHidingHeader } from "../../hooks/hidding-header-hook";
import ProductCardElement from "../../components/shop/ProductCardElement";
import AuthContext from "../../context/auth-context";

const Gallery: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [loadedMouldings, setLoadedMouldings] = useState<any[]>([]);
  const [selectedView, setSelectedView] = useState<string | undefined>("plain");
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [hideDecimal, setScrollYCurrent] = useHidingHeader(50);
  const fCtx = useContext(fContext);
  const authCtx = useContext(AuthContext);
  const isOldEiffageFormat = authCtx.code
    ?.split("")
    .every(
      (character: string) => !"AZERTYUIOPQSDFGHJKLMWXCVBN".includes(character)
    );
  const history = useHistory();

  useEffect(() => {
    const fetchProducts = async () => {
      let mount;
      if (fCtx.mount === "") {
        mount = "all";
      } else {
        mount = fCtx.mount;
      }
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/products/" + mount
        );
        if (isOldEiffageFormat) {
          setLoadedMouldings(responseData.products);
        } else {
          setLoadedMouldings(
            responseData.products.filter((moulding: any) =>
              ["Mambo (blanc)", "Mambo (noir)", "Glama", "Coya"].includes(
                moulding.name
              )
            )
          );
        }

        //console.log(responseData.products);
      } catch (err) {}
    };
    fetchProducts();
  }, [sendRequest, fCtx.mount]);

  const cancelShowAlertHandler = () => {
    setShowAlert(false);
  };

  const deleteLamaHandler = () => {
    fCtx.deleteFrame();
    setShowAlert(false);
    history.push("/bonjour_lama");
  };

  var art_w_cm = fCtx.wSelected; //width art in cm
  var art_w = fCtx.imageWidth; //width art in px
  var art_h = fCtx.imageHeight; //height art in px

  var biseau_w_cm = 0.18; // biseau width cm

  var mode = fCtx.matStyle; //mode 'italiano' or 'standard'
  var mouldingWidth = 0; //moulding width in cm
  var mat = fCtx.matSize; //mat width in cm

  const div_list = loadedMouldings.map((moulding) =>
    getDivMulti(
      art_w,
      art_h,
      mode,
      mat,
      art_w_cm,
      Number(moulding.width),
      biseau_w_cm
    )
  );

  const mouldings = loadedMouldings.map((moulding, index) => ({
    name: moulding.name,
    width_cm: moulding.width,
    style: moulding.style,
    tags: moulding.tags.split(";"),
    div: div_list[index],
    collection_moulding: moulding.collection_moulding,
  }));

  return (
    <React.Fragment>
      <DeleteLamAlert
        show={showAlert}
        onCancel={cancelShowAlertHandler}
        onDelete={deleteLamaHandler}
      />
      <IonPage>
        <HidingHeader hideDecimal={hideDecimal}>
          <DesktopToolbar />
        </HidingHeader>
        <IonContent
          scrollY={true}
          color="light"
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
        >
          {isPlatform("desktop") && fCtx.articleId !== "" && (
            <IonFab
              horizontal="end"
              vertical="center"
              slot="fixed"
              //style={{ width: "30px", marginTop: "-63px" }}
            >
              <IonSegment
                value={selectedView}
                color="dark"
                onIonChange={(e) => setSelectedView(e.detail.value)}
                className="ion-padding-top ion-padding-bottom ion-text-center"
                style={{ display: "block" }}
                mode="ios"
                disabled={isLoading || !loadedMouldings}
              >
                <IonSegmentButton
                  value="plain"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    minWidth: "22px",
                  }}
                >
                  <div className="viewSwitch">
                    <IonImg src="assets/img/frame.png"></IonImg>
                  </div>
                </IonSegmentButton>
                <IonSegmentButton
                  value="tablet"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    minWidth: "22px",
                  }}
                >
                  <div className="viewSwitch">
                    <IonImg src="assets/img/tablet.png"></IonImg>
                  </div>
                </IonSegmentButton>
              </IonSegment>
            </IonFab>
          )}
          <IonGrid className="ion-no-padding">
            {fCtx.artType !== "" && <WizardLama />}
            <IonRow className="">
              <IonCol
                style={{ background: "white" }}
                size="12"
                className="ion-text-center ion-padding-bottom ion-padding-top"
              >
                <h1 className="ion-padding-bottom">
                  {fCtx.artType !== ""
                    ? "Choisissez et personnalisez votre cadre"
                    : "Notre collection de Lamas"}
                  {/*<IonButton
                    className="ion-no-margin"
                    slot="icon-only"
                    fill="clear"
                    //onClick={() => setShowPopover(true)}
                  >
                    <IonIcon icon={informationCircleOutline} />
                  </IonButton>*/}
                </h1>
                {isPlatform("mobile") && fCtx.artType !== "" && (
                  <IonSegment
                    mode="md"
                    value={selectedView}
                    color="dark"
                    onIonChange={(e) => setSelectedView(e.detail.value)}
                    className="ion-padding-start ion-padding-end ion-text-center"
                    disabled={isLoading || !loadedMouldings}
                  >
                    <IonSegmentButton value="plain">
                      <div className="viewSwitch">
                        <IonImg src="assets/img/frame.png"></IonImg>
                      </div>
                    </IonSegmentButton>
                    <IonSegmentButton value="tablet">
                      <div className="viewSwitch">
                        <IonImg src="assets/img/tablet.png"></IonImg>
                      </div>
                    </IonSegmentButton>
                  </IonSegment>
                )}
                {fCtx.articleId === "" && (
                  <IonButton
                    //className="xl-button"
                    color="dark"
                    fill="solid"
                    mode="ios"
                    shape="round"
                    routerLink="/"
                    routerDirection="forward"
                    //onClick={showPricingHandler}
                  >
                    <p className="text-button">JE CRÉE MON ENCADREMENT</p>
                  </IonButton>
                )}
              </IonCol>
              {!isLoading && loadedMouldings && (
                <React.Fragment>
                  <IonCol
                    size="12"
                    size-lg="10"
                    offset-lg="1"
                    size-xl="8"
                    offset-xl="2"
                    className="ion-text-center ion-padding-top"
                    style={{ paddingBottom: "64px" }}
                  >
                    <IonGrid className="ion-no-padding">
                      <IonRow>
                        {mouldings.map((moulding) => (
                          <React.Fragment>
                            {!moulding.collection_moulding && (
                              <IonCol
                                size="12"
                                size-sm="6"
                                size-md="6"
                                size-lg="4"
                              >
                                {fCtx.articleId !== "" ? (
                                  <IonCard
                                    button
                                    mode="md"
                                    key={moulding.name}
                                    routerDirection="forward"
                                    routerLink={`/product/${moulding.name}`}
                                    className={
                                      isPlatform("mobile") ? "no-ripple" : ""
                                    }
                                  >
                                    {moulding.collection_moulding && (
                                      <IonFab vertical="top" horizontal="end">
                                        <IonFabButton
                                          //activated={showKnowItAll}
                                          //onClick={knowItAllHandler}
                                          color="tertiary"
                                        >
                                          <IonText
                                            className="text-fab-button"
                                            color="light"
                                          >
                                            <div style={{ display: "block" }}>
                                              CO
                                            </div>
                                            <div style={{ display: "block" }}>
                                              LEC
                                            </div>
                                            <div style={{ display: "block" }}>
                                              TION
                                            </div>
                                          </IonText>
                                        </IonFabButton>
                                      </IonFab>
                                    )}
                                    <IonGrid
                                      className="ion-no-padding ion-padding-bottom"
                                      style={
                                        selectedView === "tablet"
                                          ? {
                                              paddingTop: "0px",
                                              paddingLeft: "0px",
                                              paddingRight: "0px",
                                            }
                                          : {}
                                      }
                                    >
                                      <IonRow>
                                        <IonCol size="12">
                                          <React.Fragment>
                                            {selectedView === "plain" && (
                                              <FramePreview
                                                art_bottom={moulding.div.art_bottom.toString()}
                                                art_left={moulding.div.art_left.toString()}
                                                art_top={moulding.div.art_top.toString()}
                                                frameToMatPct={moulding.div.frameToMatPct.toString()}
                                                img_name={
                                                  moulding.name + ".jpg"
                                                }
                                                mouldingHeight={moulding.div.mouldingHeight.toString()}
                                                padding_bottom={moulding.div.padding_bottom.toString()}
                                                art_path={
                                                  fCtx.imageUrl.includes(
                                                    "uploads/images"
                                                  )
                                                    ? process.env
                                                        .REACT_APP_BACKEND_URL +
                                                      fCtx.imageUrl
                                                    : fCtx.imageUrl
                                                }
                                                mat_color={fCtx.matColor}
                                                scaling={0.95}
                                                mount={fCtx.mount}
                                                hovering={true}
                                              />
                                            )}
                                            {selectedView === "tablet" && (
                                              <LivingPreview
                                                art_bottom={moulding.div.art_bottom.toString()}
                                                art_left={moulding.div.art_left.toString()}
                                                art_top={moulding.div.art_top.toString()}
                                                frameToMatPct={moulding.div.frameToMatPct.toString()}
                                                img_name={
                                                  moulding.name + ".jpg"
                                                }
                                                mouldingHeight={moulding.div.mouldingHeight.toString()}
                                                padding_bottom={moulding.div.padding_bottom.toString()}
                                                art_path={
                                                  fCtx.imageUrl.includes(
                                                    "uploads/images"
                                                  )
                                                    ? process.env
                                                        .REACT_APP_BACKEND_URL +
                                                      fCtx.imageUrl
                                                    : fCtx.imageUrl
                                                }
                                                living_room_img={
                                                  "assets/img/proj_desktop.jpg"
                                                }
                                                living_room_width={226}
                                                selected_w={Number(
                                                  fCtx.wSelected
                                                )}
                                                mat_color={fCtx.matColor}
                                                mat_size={Number(fCtx.matSize)}
                                                moulding_width={Number(
                                                  moulding.width_cm
                                                )}
                                                mount={fCtx.mount}
                                                fullscreen={false}
                                              />
                                            )}
                                          </React.Fragment>
                                          <div
                                            className={
                                              "ion-text-start ion-padding-start ion-padding-end ion-margin-bottom"
                                            }
                                          >
                                            <IonText
                                              style={
                                                moulding.collection_moulding
                                                  ? {
                                                      fontWeight: "bold",
                                                      marginTop: "0px",
                                                    }
                                                  : { fontWeight: "bold" }
                                              }
                                              color="dark"
                                              className="text-product-title"
                                            >
                                              {moulding.name}
                                            </IonText>
                                            <div style={{ minHeight: "30px" }}>
                                              <IonText
                                                color="dark"
                                                className="text-product-short-des"
                                              >
                                                {moulding.style}
                                              </IonText>
                                            </div>
                                          </div>
                                          {moulding.tags.map((tag: string) => (
                                            <React.Fragment>
                                              {tag !== "" && (
                                                /*<IonButton
                                          style={{
                                            height: "1.6em",
                                            "--padding-start": "10px",
                                            "--padding-end": "10px",
                                          }}
                                          size="small"
                                          mode="ios"
                                          shape="round"
                                          color="dark"
                                          fill="outline"
                                        >
                                          <IonText className="text-product-tag">
                                            {style}
                                          </IonText>
                                        </IonButton>*/
                                                <IonBadge
                                                  mode="ios"
                                                  color="light"
                                                  className="text-product-tag"
                                                >
                                                  <IonText className="text-product-tag">
                                                    {tag}
                                                  </IonText>
                                                </IonBadge>
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonCard>
                                ) : (
                                  <ProductCardElement
                                    name={moulding.name}
                                    style={moulding.style}
                                    collection_moulding={
                                      moulding.collection_moulding
                                    }
                                    tags={moulding.tags}
                                  />
                                )}
                              </IonCol>
                            )}
                          </React.Fragment>
                        ))}
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </React.Fragment>
              )}
              {(isLoading || !loadedMouldings) && (
                <IonCol
                  size="12"
                  size-lg="10"
                  offset-lg="1"
                  size-xl="8"
                  offset-xl="2"
                >
                  <IonGrid className="ion-no-padding">
                    <IonRow>
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                      <GallerySkeleton />
                    </IonRow>
                  </IonGrid>
                </IonCol>
              )}
            </IonRow>
            <FooterRows />
          </IonGrid>
        </IonContent>
      </IonPage>
    </React.Fragment>
  );
};
export default Gallery;
