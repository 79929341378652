import React, { useState, useEffect } from "react";

import FContext from "./f-context";

const FContextProvider: React.FC = (props) => {
  const [articleId, setArticleId] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).articleId !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).articleId
        : ""
      : ""
  );
  const [imageUrl, setImageUrl] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).imageUrl !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).imageUrl
        : ""
      : ""
  );
  const [originalName, setOriginalName] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).originalName !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).originalName
        : ""
      : ""
  );
  const [minPriceList, setMinPriceList] = useState<Number[]>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).minPriceList !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).minPriceList
        : []
      : []
 );
  const [lamaList, setLamaList] = useState<string[]>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).lamaList !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).lamaList
        : []
      : []
 );
  const [imageWidth, setImageWidth] = useState<Number>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).imageWidth !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).imageWidth
        : 0
      : 0
  );
  const [imageHeight, setImageHeight] = useState<Number>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).imageHeight !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).imageHeight
        : 0
      : 0
  );
  const [wList, setWList] = useState<Number[]>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).wList !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).wList
        : []
      : []
 );
  const [hList, setHlist] = useState<Number[]>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).hList !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).hList
        : []
      : []
 );
  const [wSelected, setWSelected] = useState<Number>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).wSelected !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).wSelected
        : 0
      : 0
  );
  const [hSelected, setHSelected] = useState<Number>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).hSelected !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).hSelected
        : 0
      : 0
  );
  const [indexSelected, setIndexSelected] = useState<Number>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).indexSelected !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).indexSelected
        : 0
      : 0
  );
  const [matColor, setMatColor] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).matColor !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).matColor
        : "#FEFEFE"
      : "#FEFEFE"
  );
  const [matStyle, setMatStyle] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).matStyle !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).matStyle
        : "standard"
      : "standard"
  );
  const [matShape, setMatShape] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).matShape !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).matShape
        : "standard"
      : "standard"
  );
  const [matSize, setMatSize] = useState<Number>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).matSize !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).matSize
        : 8
      : 8
  );
  const [mount, setMount] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).mount !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).mount
        : "simple"
      : "simple"
  );
  const [artType, setArtType] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).artType !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).artType
        : ""
      : ""
  );
  const [mouldingName, setMouldingName] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).mouldingName !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).mouldingName
        : ""
      : ""
  );
  const [selectedStyle, setSelectedStyle] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).selectedStyle !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).selectedStyle
        : "standard"
      : "standard"
  );

  const loadImageUrl = (url: string, width: Number, height: Number) => {
    setImageUrl(url); //callback guaranty last state used
    setImageWidth(width);
    setImageHeight(height);
  };

  const addImageUrl = (
    url: string,
    article_id: string,
    original_name: string,
    min_price_list: Number[],
    lama_list: string[],
    width: Number,
    height: Number,
    w_list: Number[],
    h_list: Number[]
  ) => {
    setImageUrl(url); //callback guaranty last state used
    setArticleId(article_id);
    setOriginalName(original_name);
    setMinPriceList(min_price_list);
    setLamaList(lama_list);
    setImageWidth(width);
    setImageHeight(height);
    setWList(w_list);
    setHlist(h_list);
    setWSelected(w_list[w_list.length - 1]);
    setHSelected(h_list[h_list.length - 1]);
    setIndexSelected(w_list.length - 1);
    selectMouldingName("");
  };

  const selectSize = (
    selected_w: Number,
    selected_h: Number,
    index_selected: Number
  ) => {
    setWSelected(selected_w);
    setHSelected(selected_h);
    setIndexSelected(index_selected);
  };

  const selectImageSize = (
    new_width: number,
    new_height: number,
    new_path:string
  ) => {
    setImageWidth(new_width);
    setImageHeight(new_height);
    setImageUrl(new_path);
  };

  const selectMatColor = (color_selected: string) => {
    setMatColor(color_selected);
  };

  const selectMatStyle = (style_selected: string) => {
    setMatStyle(style_selected);
  };

  const selectMatShape = (shape_selected: string) => {
    setMatShape(shape_selected);
  };

  const selectMatSize = (size_selected: Number) => {
    setMatSize(size_selected);
  };

  const selectMount = (selected_mount: string) => {
    setMount(selected_mount);
  };

  const selectArtType = (selected_art_type: string) => {
    setArtType(selected_art_type);
  };

  const selectMouldingName = (selected_moulding: string) => {
    setMouldingName(selected_moulding);
  };

  const selectSelectedStyle = (selected_style: string) => {
    setSelectedStyle(selected_style);
  };

  const deleteFrame = () => {
    setArticleId("");
    setHlist([]);
    setWList([]);
    setOriginalName("");
    setMinPriceList([]);
    setLamaList([]);
    setImageHeight(0);
    setImageWidth(0);
    setImageUrl("");
    setIndexSelected(0);
    selectSize(0, 0, 0);
    selectMatColor("");
    selectMatStyle("");
    selectMatShape("");
    selectMatSize(0);
    selectMount("");
    selectArtType("");
    selectMouldingName("");
    setArtType("");
    setSelectedStyle("");
    //localStorage.setItem('userFrame',JSON.stringify({}));
  };


  useEffect(() => {
    localStorage.setItem(
      "userFrame",
      JSON.stringify({
        imageUrl,
        articleId,
        originalName,
        minPriceList,
        lamaList,
        imageWidth,
        imageHeight,
        wList,
        hList,
        wSelected,
        hSelected,
        indexSelected,
        matColor,
        matStyle,
        matShape,
        matSize,
        mount,
        artType,
        mouldingName,
        selectedStyle,
      })
    );
  }, [
    imageUrl,
    articleId,
    originalName,
    minPriceList,
    lamaList,
    imageWidth,
    imageHeight,
    wList,
    hList,
    wSelected,
    hSelected,
    indexSelected,
    matColor,
    matStyle,
    matShape,
    matSize,
    mount,
    artType,
    mouldingName,
    selectedStyle,
  ]);

  return (
    <FContext.Provider
      value={{
        imageUrl,
        articleId,
        originalName,
        minPriceList,
        lamaList,
        imageWidth,
        imageHeight,
        wList,
        hList,
        wSelected,
        hSelected,
        indexSelected,
        matColor,
        matStyle,
        matShape,
        matSize,
        mount,
        artType,
        loadImageUrl,
        addImageUrl,
        mouldingName,
        selectedStyle,
        selectSize,
        selectMatColor,
        selectMatStyle,
        selectMatShape,
        selectMatSize,
        selectMount,
        selectArtType,
        selectMouldingName,
        deleteFrame,
        selectSelectedStyle,
        selectImageSize
      }}
    >
      {props.children}
    </FContext.Provider>
  );
};

export default FContextProvider;
