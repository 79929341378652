import React, { useState, useContext, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonGrid,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonRow,
  IonItem,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonSpinner,
  IonBackButton,
  IonTextarea,
  IonRouterLink,
  IonInput,
} from "@ionic/react";

import {
  closeCircleOutline,
  arrowForwardOutline,
  menuOutline,
  arrowBackOutline,
  createOutline,
  closeOutline,
  checkmarkOutline,
} from "ionicons/icons";

import "../../theme/home.css";
import fContext from "../../context/f-context";
import cContext from "../../context/cart-context";
import { menuController } from "@ionic/core";
import { useHttpClient } from "../../hooks/http-hook";
import AuthContext from "../../context/auth-context";
import FramePreview from "../../components/shared/FramePreview";
import PriceContext from "../../context/price-context";
import { useHistory } from "react-router";
import FooterRows from "../../components/shared/FooterRows";
import CustomDescription from "../../components/shared/CustomDescription";

const Cart: React.FC = () => {
  //CART function, on fornt end context, store article_id and qty.
  //STORE the cart inside localstorage
  //STORE a isadded field inside article
  //WHEN checkout pressed, create order and store order id in local storage

  //OR ALTERNATIVE -> when someone press add to cart, create cart
  //SEND back cart id
  //STORE cart id in localstorage
  //WHEN you add to cart, update backend and then fetch data form it
  //ON REFRESH fetch data from cart id contained in local storage

  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(cContext);
  const fCtx = useContext(fContext);
  const priceCtx = useContext(PriceContext);
  const [loadedItems, setLoadedItems] = useState<Array<any>>([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isUseEffectCalculating, setIsUseEffectCalculating] =
    useState<boolean>(false);
  const [noteId, setNoteId] = useState<string>("");
  const [noteText, setNoteText] = useState<string>("");
  const [subTotal, setSubTotal] = useState<Number>(0);
  const [showDiscountField, setShowDiscountField] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>("");
  const [discountCodeID, setDiscountCodeId] = useState<string>("");
  const [messageDiscount, setMessageDiscount] = useState<string>("");
  const history = useHistory();

  const fetchCart = async () => {
    try {
      let article_ids = [];
      if (cartCtx && cartCtx.cartItems) {
        for (var j = 0; j < cartCtx.cartItems?.length; j++) {
          article_ids.push(cartCtx.cartItems[j].article_id);
        }
        const data = JSON.stringify({
          article_ids: article_ids,
        });
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/order/getCart",
          "POST",
          data,
          {
            "Content-Type": "application/json",
          }
        );
        setLoadedItems(
          responseData.items.filter((item: any) => item.product_id !== null)
        );
      }
    } catch (err) {}
  };

  useEffect(() => {
    setIsUseEffectCalculating(true);
    fetchCart();
    setIsUseEffectCalculating(false);
  }, [sendRequest, cartCtx.cartItems]);

  useEffect(() => {
    setIsUseEffectCalculating(true);
    const fetchPrices = async () => {
      try {
        //replace hardcoded eur by context variable
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/prices_" + "eur"
        );
        priceCtx.populatePriceList(responseData.prices);
      } catch (err) {}
      setIsUseEffectCalculating(false);
    };
    fetchPrices();
  }, [sendRequest]);

  const deleteItemHandler = () => {};

  useEffect(() => {
    setIsUseEffectCalculating(true);
    const calculateSubTotal = async () => {
      try {
        let priceAddition = 0;
        for (var j = 0; j < loadedItems.length; j++) {
          priceAddition =
            priceAddition +
            Number(
              priceCtx.getPricePerDim(
                Number(loadedItems[j].art_h_cm_selected) +
                  Number(loadedItems[j].art_w_cm_selected)
              ).base_price
            ) +
            Number(
              loadedItems[j].mount_selected === "floating" ||
                loadedItems[j].mount_selected === "box"
                ? priceCtx.getPricePerDim(
                    Number(loadedItems[j].art_h_cm_selected) +
                      Number(loadedItems[j].art_w_cm_selected)
                  ).mount_special
                : 0
            ) +
            Number(
              loadedItems[j].mat_shape_selected === "italiano" ||
                loadedItems[j].mat_shape_selected === "oversized"
                ? priceCtx.getPricePerDim(
                    Number(loadedItems[j].art_h_cm_selected) +
                      Number(loadedItems[j].art_w_cm_selected)
                  ).pp_special
                : 0
            ) +
            Number(
              loadedItems[j].art_type === "unmounted_canvas"
                ? priceCtx.getPricePerDim(
                    Number(loadedItems[j].art_h_cm_selected) +
                      Number(loadedItems[j].art_w_cm_selected)
                  ).canvas_mount
                : 0
            ) +
            Number(
              loadedItems[j].product_id.collection_moulding
                ? priceCtx.getPricePerDim(
                    Number(loadedItems[j].art_h_cm_selected) +
                      Number(loadedItems[j].art_w_cm_selected)
                  ).collection_moulding
                : 0
            );
        }
        setSubTotal(priceAddition);
      } catch (err) {}
      setIsUseEffectCalculating(false);
    };
    calculateSubTotal();
  }, [loadedItems, priceCtx.priceList]);

  const orderHandler = async () => {
    setIsUseEffectCalculating(true);
    try {
      let article_ids = [];
      if (cartCtx && cartCtx.cartItems) {
        for (var j = 0; j < cartCtx.cartItems?.length; j++) {
          article_ids.push(cartCtx.cartItems[j].article_id);
        }
        const data = JSON.stringify({
          article_ids: article_ids,
          discount_code_id: discountCodeID,
        });
        let responseData;
        if (authCtx.isLoggedIn) {
          responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "api/shop/order/createAuth",
            "POST",
            data,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authCtx.token,
            }
          );
          if (responseData.collection_needed.toString() === "true") {
            history.push(`/order/${responseData.order_id}/collectionMethod`);
          } else {
            history.push(`/order/${responseData.order_id}/shippingAddress`);
          }
        } else {
          responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "api/shop/order/create",
            "POST",
            data,
            {
              "Content-Type": "application/json",
            }
          );
          history.push(`/order/${responseData.order_id}/info`);
        }
      }
    } catch (err) {}
    setIsUseEffectCalculating(false);
    //history.push("/order/checkout");
  };

  const openNoteHandler = (note_id: string) => {
    setNoteId(note_id);
  };

  const openExistingNote = (note_id: string, note_text: string) => {
    setNoteId(note_id);
    setNoteText(note_text);
  };

  const showDiscountFieldHandler = () => {
    setDiscountCode("");
    setMessageDiscount("");
    setShowDiscountField(true);
  };

  const checkDiscountCode = async () => {
    try {
      const data = JSON.stringify({
        discount_code: discountCode,
        sub_total: subTotal,
      });
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/order/checkDiscount",
        "POST",
        data,
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData.code.is_percent) {
        //setSubTotal(Number(subTotal)*(1-responseData.code.amount))
        setMessageDiscount(
          "-" + (Number(subTotal) * responseData.code.amount).toFixed(2) + "€"
        );
      } else {
        //setSubTotal(Number(subTotal)-responseData.code.amount)
        setMessageDiscount("-" + responseData.code.amount.toFixed(2) + "€");
      }

      if (responseData.code.is_shipping && responseData.code.is_collection) {
        setMessageDiscount("Collecte & Livraison gratuite");
      } else if (
        responseData.code.is_collection &&
        !responseData.code.is_shipping
      ) {
        setMessageDiscount("Collecte gratuite");
      } else if (
        !responseData.code.is_collection &&
        responseData.code.is_shipping
      ) {
        setMessageDiscount("Livraison gratuite");
      }
      setDiscountCodeId(responseData.code._id);
    } catch (err) {
      alert("Code Invalide");
    }
  };

  const sendNoteHandler = async (note_id: string) => {
    try {
      const data = JSON.stringify({
        article_id: note_id,
        note: noteText,
      });
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/order/postNote",
        "POST",
        data,
        {
          "Content-Type": "application/json",
        }
      );
      setNoteId("");
      fetchCart();
    } catch (err) {
      alert("Une erreure est survenue. Veuillez ré-essayer plus tard.");
    }
  };

  return (
    <React.Fragment>
      <IonPage>
        <IonHeader>
          <IonToolbar style={{ backgroundColor: "white" }}>
            <IonButtons slot="start">
              <IonButtons slot="start">
                <IonBackButton text="">
                  <IonIcon size="large" icon={arrowBackOutline}></IonIcon>
                </IonBackButton>
              </IonButtons>
            </IonButtons>
            <IonTitle
              style={{ position: "absolute" }}
              className="ion-text-center"
            >
              <IonButton
                routerLink="/"
                routerDirection="back"
                mode="ios"
                fill="clear"
              >
                <img
                  style={{ maxHeight: "40px" }}
                  src="assets/img/logo_black.png"
                />
              </IonButton>
            </IonTitle>
            <IonTitle
              style={{ position: "relative", height: "52px" }}
              className="ion-text-center"
            ></IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="dark"
                fill="clear"
                onClick={(e) => menuController.toggle()}
              >
                <IonIcon icon={menuOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="ion-no-padding">
            <IonRow
              color="light"
              style={{
                minHeight: "calc(100vh - 150px)",
                paddingBottom: "80px",
              }}
            >
              {isUseEffectCalculating && (
                <IonCol size="12" className="ion-text-center ">
                  <IonText className="ion-text-center">
                    <p>Chargement</p>
                  </IonText>
                  <IonSpinner className="ion-text-center" name="crescent" />
                </IonCol>
              )}
              {!isLoading && !isUseEffectCalculating && loadedItems.length < 1 && (
                <React.Fragment>
                  <IonCol
                    size="12"
                    size-md="6"
                    offset-md="3"
                    className="ion-no-padding ion-padding-top ion-margin-top"
                  >
                    <IonText
                      color="medium shade"
                      className="ion-text-center ion-padding-top ion-margin-top"
                    >
                      <p>Votre panier est vide</p>
                    </IonText>
                    <IonCard
                      button
                      routerLink="/what-we-frame-today"
                      routerDirection="forward"
                    >
                      <IonToolbar className="ion-text-center ion-padding">
                        <h2>&#129433; Creér un lama &#9728;&#65039;</h2>
                        <IonIcon icon={arrowForwardOutline} slot="end" />
                      </IonToolbar>
                    </IonCard>
                  </IonCol>
                </React.Fragment>
              )}

              {!isUseEffectCalculating && (
                <IonCol
                  size-md="6"
                  offset-md="3"
                  size="12"
                  className="ion-no-padding "
                >
                  {loadedItems.length > 0 &&
                    loadedItems.map((item) => (
                      <IonCard mode="md">
                        <IonGrid className="ion-no-padding">
                          <IonRow>
                            <IonCol
                              size="6"
                              className="ion-padding-start ion-padding-top"
                            >
                              <FramePreview
                                art_bottom={item.div.art_bottom}
                                art_left={item.div.art_left}
                                art_top={item.div.art_top}
                                frameToMatPct={item.div.frameToMatPct}
                                img_name={item.product_id.name + ".jpg"}
                                mouldingHeight={item.div.mouldingHeight}
                                padding_bottom={item.div.padding_bottom}
                                art_path={
                                  item.imagePath.includes("uploads/images")
                                    ? process.env.REACT_APP_BACKEND_URL +
                                      item.imagePath
                                    : item.imagePath
                                }
                                mat_color={item.mat_color_selected}
                                scaling={item.div.scaling}
                                mount={item.mount_selected}
                                hovering={false}
                              />
                            </IonCol>
                            <IonCol size="6">
                              <IonToolbar className="ion-no-padding">
                                <IonButton
                                  onClick={(e) => cartCtx.removeItem(item._id)}
                                  fill="clear"
                                  slot="end"
                                >
                                  <IonIcon icon={closeCircleOutline} />
                                </IonButton>
                              </IonToolbar>
                              <IonCardHeader>
                                <IonCardSubtitle>
                                  {item.product_id.name}
                                </IonCardSubtitle>
                                <IonCardTitle>
                                  {
                                    <React.Fragment>
                                      {Number(
                                        priceCtx.getPricePerDim(
                                          Number(item.art_h_cm_selected) +
                                            Number(item.art_w_cm_selected)
                                        ).base_price
                                      ) +
                                        Number(
                                          item.mount_selected === "floating" ||
                                            item.mount_selected === "box"
                                            ? priceCtx.getPricePerDim(
                                                Number(item.art_h_cm_selected) +
                                                  Number(item.art_w_cm_selected)
                                              ).mount_special
                                            : 0
                                        ) +
                                        Number(
                                          item.mat_shape_selected ===
                                            "italiano" ||
                                            item.mat_shape_selected ===
                                              "oversized"
                                            ? priceCtx.getPricePerDim(
                                                Number(item.art_h_cm_selected) +
                                                  Number(item.art_w_cm_selected)
                                              ).pp_special
                                            : 0
                                        ) +
                                        Number(
                                          item.art_type === "unmounted_canvas"
                                            ? priceCtx.getPricePerDim(
                                                Number(item.art_h_cm_selected) +
                                                  Number(item.art_w_cm_selected)
                                              ).canvas_mount
                                            : 0
                                        ) +
                                        Number(
                                          item.product_id.collection_moulding
                                            ? priceCtx.getPricePerDim(
                                                Number(item.art_h_cm_selected) +
                                                  Number(item.art_w_cm_selected)
                                              ).collection_moulding
                                            : 0
                                        ) +
                                        "€"}
                                    </React.Fragment>
                                  }
                                </IonCardTitle>
                              </IonCardHeader>
                            </IonCol>
                            <IonCol size="12">
                              <IonCardContent>
                                <IonGrid className="ion-no-padding">
                                  <IonRow>
                                    <CustomDescription
                                      artType={item.art_type}
                                      mount={item.mount_selected}
                                      matColor={item.mat_color_selected}
                                      matShape={item.mat_shape_selected}
                                      matSize={Number(item.mat_size_selected)}
                                      out_h_cm={Number(item.frame_h_cm)}
                                      out_w_cm={Number(item.frame_w_cm)}
                                      wSelected={Number(item.art_w_cm_selected)}
                                      hSelected={Number(item.art_h_cm_selected)}
                                      showCustomButton={false}
                                    />
                                    {/*
                                    <IonCol
                                      size="12"
                                      style={{ paddingBottom: "0.5rem" }}
                                    >
                                      <IonText color="medium shade">
                                        <p
                                          className="text-arttype-title"
                                          style={{ marginBottom: "0px" }}
                                        >
                                          TYPE D'OEUVRE :
                                        </p>
                                      </IonText>
                                      <IonText color="dark">
                                        <p className="text-arttype">
                                          {cleanArtType[item.art_type]}
                                        </p>
                                      </IonText>
                                    </IonCol>
                                    {item.mount_selected !== "canvas" && (
                                      <IonCol
                                        size="12"
                                        style={{ paddingBottom: "0.5rem" }}
                                      >
                                        <IonText color="medium shade">
                                          <p
                                            className="text-arttype-title"
                                            style={{ marginBottom: "0px" }}
                                          >
                                            PERSONNALISATION :
                                          </p>
                                        </IonText>
                                        <IonText color="dark">
                                          <p className="text-arttype">
                                            Montage {item.mount_selected}{" "}
                                            {item.mat_size_selected === 0
                                              ? ""
                                              : item.mount_selected === "simple"
                                              ? " avec passe-partout " +
                                                item.clean_mat_color_selected
                                              : item.mount_selected ===
                                                "floating"
                                              ? " avec fond " +
                                                item.clean_mat_color_selected
                                              : ""}
                                          </p>
                                        </IonText>
                                      </IonCol>
                                    )}
                                    <IonCol
                                      size="12"
                                      style={{ paddingBottom: "0.5rem" }}
                                    >
                                      <IonText color="medium shade">
                                        <p
                                          className="text-arttype-title"
                                          style={{ marginBottom: "0px" }}
                                        >
                                          TAILLE DE L'OEUVRE :
                                        </p>
                                      </IonText>
                                      <IonText color="dark">
                                        <p className="text-arttype">
                                          {item.art_w_cm_selected} x{" "}
                                          {item.art_h_cm_selected} cm
                                        </p>
                                      </IonText>
                                    </IonCol>
                                    <IonCol
                                      size="12"
                                      style={{ paddingBottom: "0.5rem" }}
                                      //className="ion-padding-bottom"
                                    >
                                      <IonText color="medium shade">
                                        <p
                                          className="text-arttype-title"
                                          style={{ marginBottom: "0px" }}
                                        >
                                          TAILLE DU CADRE :
                                        </p>
                                      </IonText>
                                      <IonText color="dark">
                                        <p className="text-arttype">
                                          {item.frame_w_cm} x {item.frame_h_cm}{" "}
                                          cm
                                        </p>
                                      </IonText>
                                            </IonCol>*/}
                                    {item.customer_note !== "" &&
                                    item.customer_note &&
                                    noteId !== item._id ? (
                                      <IonCol
                                        size="12"
                                        //className="ion-padding-bottom"
                                      >
                                        <IonText color="medium shade">
                                          <p
                                            className="text-arttype-title"
                                            style={{ marginBottom: "0px" }}
                                          >
                                            NOTE POUR L'ENCADREUR :
                                          </p>
                                        </IonText>
                                        <IonText color="dark">
                                          <p className="text-arttype">
                                            {item.customer_note}
                                          </p>
                                        </IonText>
                                        <div className="ion-text-right">
                                          <IonButton
                                            onClick={(e) =>
                                              openExistingNote(
                                                item._id,
                                                item.customer_note
                                              )
                                            }
                                            fill="clear"
                                            color="dark"
                                          >
                                            <IonIcon
                                              size="small"
                                              icon={createOutline}
                                            />
                                          </IonButton>
                                        </div>
                                      </IonCol>
                                    ) : (
                                      <IonCol
                                        size="12"
                                        className="ion-text-center"
                                      >
                                        {noteId !== item._id && (
                                          <IonButton
                                            fill="clear"
                                            mode="ios"
                                            onClick={(e) =>
                                              openNoteHandler(item._id)
                                            }
                                          >
                                            <IonText
                                              className="ion-text-wrap"
                                              color="dark"
                                            >
                                              <p>
                                                Ajouter une note pour
                                                l'encadreur (optionnel)
                                              </p>
                                            </IonText>
                                          </IonButton>
                                        )}
                                      </IonCol>
                                    )}
                                    {noteId === item._id && (
                                      <IonCol
                                        size="12"
                                        className="ion-text-center"
                                      >
                                        <div className="ion-text-right">
                                          <IonButton
                                            onClick={(e) => setNoteId("")}
                                            fill="clear"
                                            color="dark"
                                          >
                                            <IonIcon
                                              size="small"
                                              icon={closeOutline}
                                            />
                                          </IonButton>
                                        </div>
                                        <div
                                          className="ion-text-center ion-margin-bottom"
                                          style={{
                                            border:
                                              "1px solid var(--ion-color-light)",
                                          }}
                                        >
                                          <IonTextarea
                                            inputmode="text"
                                            rows={3}
                                            cols={20}
                                            maxlength={300}
                                            autoGrow={true}
                                            placeholder="ex: Laisser la signature de l'artiste visible..."
                                            value={noteText}
                                            onIonChange={(e) =>
                                              setNoteText(e.detail.value!)
                                            }
                                          ></IonTextarea>
                                        </div>
                                        <IonButton
                                          disabled={isLoading}
                                          fill="outline"
                                          mode="ios"
                                          shape="round"
                                          color="dark"
                                          size="small"
                                          onClick={(e) =>
                                            sendNoteHandler(item._id)
                                          }
                                        >
                                          <IonText>
                                            <p
                                              className="text-button"
                                              style={{ margin: "0px" }}
                                            >
                                              VALIDER
                                            </p>
                                          </IonText>
                                          {isLoading && (
                                            <IonSpinner
                                              color="tertiary"
                                              name="crescent"
                                            />
                                          )}
                                        </IonButton>
                                      </IonCol>
                                    )}
                                  </IonRow>
                                </IonGrid>
                              </IonCardContent>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    ))}
                  <IonCard mode="md" style={{ marginBottom: "80px" }}>
                    <IonGrid className="ion-no-padding">
                      <IonRow>
                        <IonCol size="12" className="ion-no-padding">
                          <IonItem lines="full">
                            <IonText>Sous-total</IonText>
                            <IonText slot="end">
                              {" "}
                              {subTotal.toFixed(2) + " €"}
                            </IonText>
                          </IonItem>
                          <IonItem lines="full">
                            {messageDiscount !== "" && (
                              <React.Fragment>
                                {" "}
                                <IonButton
                                  mode="ios"
                                  fill="clear"
                                  color="primary"
                                  onClick={showDiscountFieldHandler}
                                >
                                  <p>Réduction</p>
                                </IonButton>{" "}
                                <IonText slot="end" color="primary">
                                  <p>
                                    {messageDiscount} &nbsp;{" "}
                                    <IonIcon icon={checkmarkOutline} />
                                  </p>
                                </IonText>
                              </React.Fragment>
                            )}
                            {!showDiscountField && messageDiscount === "" && (
                              <IonButton
                                mode="ios"
                                fill="clear"
                                color="dark"
                                onClick={showDiscountFieldHandler}
                              >
                                <p>Ajouter un code promo &nbsp;</p>
                              </IonButton>
                            )}
                            {showDiscountField && messageDiscount === "" && (
                              <React.Fragment>
                                <IonInput
                                  mode="ios"
                                  value={discountCode}
                                  placeholder="Code promo"
                                  onIonChange={(e) =>
                                    setDiscountCode(e.detail.value!)
                                  }
                                ></IonInput>{" "}
                                <IonButton
                                  mode="ios"
                                  fill="clear"
                                  color="primary"
                                  onClick={checkDiscountCode}
                                >
                                  <p>Valider</p>
                                </IonButton>
                                <IonButton
                                  mode="ios"
                                  fill="clear"
                                  color="dark"
                                  onClick={(e) => setShowDiscountField(false)}
                                >
                                  <p>Annuler</p>
                                </IonButton>
                              </React.Fragment>
                            )}
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonButton
                            disabled={
                              subTotal === 0 || isUseEffectCalculating
                                ? true
                                : false
                            }
                            size="large"
                            className="ion-no-margin"
                            color="dark"
                            expand="full"
                            onClick={orderHandler}
                          >
                            <IonText>
                              <p className="text-button">PASSER COMMANDE</p>
                            </IonText>
                            {(isLoading || isUseEffectCalculating) && (
                              <IonSpinner name="crescent" color="warning" />
                            )}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonCol>
              )}
            </IonRow>
            {!isLoading && <FooterRows />}
          </IonGrid>
        </IonContent>
        {/*<IonFooter style={{ backgroundColor: "white" }}></IonFooter>*/}
      </IonPage>
    </React.Fragment>
  );
};

export default Cart;
