import React, { useContext } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonIcon,
  IonButtons,
  IonGrid,
  IonRow
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import FramePreview from "../shared/FramePreview";
import fContext from "../../context/f-context";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const FullScreenFrame: React.FC<{
  show: boolean;
  moulding: { name: string; width_cm: string; style: string; div: any };
  scaling: number;
  onCancel: () => void;
}> = props => {
  const fCtx = useContext(fContext);
  return (
    <IonModal isOpen={props.show} cssClass={/*'transparent'*/''}>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar color="undefined">
          <IonButtons slot="end">
            <IonButton onClick={props.onCancel}>
              <IonIcon size='large' icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={/*'transparent'*/''} onClick={props.onCancel}>
        <IonGrid className='ion-full-height ion-no-padding'>
          <IonRow class="ion-align-items-center ion-full-height">
              <TransformWrapper
                defaultScale={1}
                defaultPositionX={200}
                defaultPositionY={100}  
              >
                <TransformComponent>
                  <div
                    className='div-transform'
                    style={{ width: "100vw", height:'100vh'}}
                  >
                    <FramePreview
                      art_bottom={props.moulding.div.art_bottom.toString()}
                      art_left={props.moulding.div.art_left.toString()}
                      art_top={props.moulding.div.art_top.toString()}
                      frameToMatPct={props.moulding.div.frameToMatPct.toString()}
                      img_name={props.moulding.name + ".jpg"}
                      mouldingHeight={props.moulding.div.mouldingHeight.toString()}
                      padding_bottom={props.moulding.div.padding_bottom.toString()}
                      art_path={fCtx.imageUrl.includes("uploads/images")? process.env.REACT_APP_BACKEND_URL + fCtx.imageUrl:fCtx.imageUrl}
                      mat_color={fCtx.matColor}
                      scaling={props.scaling}
                      mount={fCtx.mount}
                      hovering={false}
                    />
                  </div>
                </TransformComponent>
              </TransformWrapper>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default FullScreenFrame;
