import React, { useRef, useContext, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  IonImg,
  isPlatform,
} from "@ionic/react";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import PriceContext from "../../context/price-context";
import FooterRows from "../../components/shared/FooterRows";
import ProgressiveImage from "react-progressive-graceful-image";

const Pricing: React.FC = () => {
  const widthInput = useRef<HTMLIonInputElement>(null);
  const heightInput = useRef<HTMLIonInputElement>(null);
  const priceCtx = useContext(PriceContext);
  const [basePrice, setBasePrice] = useState<string>("");
  const [lamaName, setLamaName] = useState<string>("");
  const [mountSpecialPrice, setMountSpecialPrice] = useState<string>("");
  const [matSpecialPrice, setMatSpecialPrice] = useState<string>("");
  const [collectionPrice, setCollectionPrice] = useState<string>("");
  const [showPricing, setShowPricing] = useState<boolean>(false);
  const pricingSectionRef = useRef<HTMLIonColElement>(null);
  const oversizedSectionRef = useRef<HTMLIonColElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [voidField, setVoidField] = useState<boolean>(false);
  const [oversized, setOversized] = useState<boolean>(false);

  const sizeInputHandler = () => {
    if (showPricing) {
      showPricingHandler();
    }
    if (widthInput.current && heightInput.current) {
      const widthSelected = widthInput.current!.value;
      const heightSelected = heightInput.current!.value;

      const from_price = priceCtx.getPricePerDim(
        Number(widthSelected) + Number(heightSelected)
      );

      if (from_price && from_price.name) {
        setBasePrice(from_price.base_price.toString());
        setMountSpecialPrice(from_price.mount_special.toString());
        setMatSpecialPrice(from_price.pp_special.toString());
        setCollectionPrice(from_price.collection_moulding.toString());
        switch (from_price.name.toString()) {
          case "small": {
            setLamaName("Bébé Lama");
            break;
          }
          case "medium": {
            setLamaName("Moyen Lama");
            break;
          }
          case "large": {
            setLamaName("Large Lama");
            break;
          }
          case "grand": {
            setLamaName("Grand Lama");
            break;
          }
          case "super": {
            setLamaName("Super Lama");
            break;
          }
          case "mega": {
            setLamaName("Mega Lama");
            break;
          }
          case "maxi": {
            setLamaName("Maxi Lama");
            break;
          }
          default: {
            setLamaName("Hors Catégorie");
          }
        }
      }
    }
  };

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const showPricingHandler = async () => {
    if (widthInput.current && heightInput.current) {
      const widthSelected = widthInput.current!.value;
      const heightSelected = heightInput.current!.value;
      if (
        widthSelected?.toString() !== "" &&
        heightSelected?.toString() !== ""
      ) {
        setVoidField(false);
        if (Number(widthSelected) + Number(heightSelected) <= 180) {
          setOversized(false);
          setShowPricing(true);
          await delay(100);
          let y = pricingSectionRef.current
            ? pricingSectionRef.current.offsetTop
            : 300;
          contentRef.current && contentRef.current.scrollToPoint(0, y, 500);
        } else {
          setShowPricing(false);
          await delay(100);
          let y = oversizedSectionRef.current
            ? oversizedSectionRef.current.offsetTop
            : 300;
          contentRef.current && contentRef.current.scrollToPoint(0, y, 500);
          setOversized(true);
        }
      } else {
        setOversized(false);
        setVoidField(true);
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <DesktopToolbar />
      </IonHeader>
      <IonContent ref={contentRef}>
        <IonGrid className="ion-no-padding">
          {isPlatform("mobile") ? (
            <IonRow>
              <IonCol size="12">
                <IonImg
                  style={{ width: "100%" }}
                  src="assets/img/pricing/cover.jpg"
                />
              </IonCol>
              <IonCol
                style={{ paddingTop: "24px" }}
                className="ion-text-center ion-padding"
                size="12"
              >
                <h1 className="ion-padding-bottom">
                  Pour une fois, c’est la taille qui compte.
                </h1>
                <p>
                  Chez Lama, nous avons à coeur de rendre les choses simples et
                  transparentes. Alors pas de chichi, le prix de votre Lama
                  dépendra logiquement de la taille de votre sujet. Il vous
                  suffit de mesurer sa largeur (L) et sa hauteur (H) pour
                  connaître votre tarification. Faites le test !
                </p>
              </IonCol>
            </IonRow>
          ) : (
            <ProgressiveImage
              src={"assets/img/pricing/cover.jpg"}
              placeholder={"assets/img/pricing/cover_tn.jpg"}
            >
              {(src: string | undefined, loading: any) => (
                <IonRow
                  className="pricing-section"
                  style={{
                    backgroundImage: "url('" + src + "')",
                    filter: loading ? "blur(15px)" : "",
                    minHeight: "300px",
                  }}
                >
                  <IonCol
                    style={{ paddingTop: "64px", paddingRight: "32px" }}
                    className="ion-text-right ion-padding"
                    size="12"
                    size-md="6"
                    offset-md="6"
                  >
                    <h1>Pour une fois, c’est la taille qui compte.</h1>
                    <p>
                      Chez Lama, nous avons à coeur de rendre les choses simples
                      et transprentes. Alors pas de chichi, le prix de votre
                      Lama dépendra logiquement de la taille de votre sujet. Il
                      vous suffit de mesurer sa largeur (L) et sa hauteur (H)
                      pour connaître votre tarification. Faites le test !
                    </p>
                  </IonCol>
                </IonRow>
              )}
            </ProgressiveImage>
          )}
          <IonRow
            style={{
              background: "var(--ion-color-secondary)",
              paddingBottom: "32px",
            }}
            className="ion-padding-start ion-padding-end"
          >
            <IonCol
              className="ion-text-center ion-padding-top ion-padding-bottom"
              size="12"
              size-md="10"
              offset-md="1"
              size-lg="8"
              offset-lg="2"
              size-xl="7"
              offset-xl="2.5"
            >
              <IonText>
                <h2 style={{ paddingBottom: "32px" }}>
                  Combien vous coûtera votre Lama ?
                </h2>
              </IonText>
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol
                    offset="1"
                    size="4"
                    color="undefined"
                    class="ion-text-center"
                  >
                    <IonItem  className="ion-text-center" lines="full">
                      <IonLabel className="ion-text-wrap" position="stacked">
                        Largeur du sujet
                      </IonLabel>
                      <IonInput
                        type="number"
                        ref={widthInput}
                        onIonChange={sizeInputHandler}
                        pattern="\d*"
                        clearOnEdit={true}
                        inputmode="decimal"
                        required={true}
                        autofocus={true}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="1" color="undefined" class="ion-text-center y-align">
                    <h2 style={{margin:'0px'}}>x</h2>
                  </IonCol>
                  <IonCol size="4" color="undefined" class="ion-text-center">
                    <IonItem className="ion-text-center" lines="full">
                      <IonLabel className="ion-text-wrap" position="stacked">
                        Hauteur du sujet
                      </IonLabel>
                      <IonInput
                        type="number"
                        ref={heightInput}
                        onIonChange={sizeInputHandler}
                        pattern="\d*"
                        clearOnEdit={true}
                        inputmode="decimal"
                        required={true}
                        //disabled
                        /*value={
                                widthInput.current
                                  ? (
                                      (Number(widthInput.current!.value) *
                                        Number(fCtx.imageHeight)) /
                                      Number(fCtx.imageWidth)
                                    ).toFixed(1)
                                  : ""
                              }*/
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol
                    size="2"
                    color="undefined"
                    className="ion-text-center ion-padding-end"
                  >
                    <IonText color="dark">
                      <h2>cm</h2>
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol className="ion-text-center ion-padding-top" size="12">
              {voidField && (
                <IonText>
                  <p>Veuillez renseigner les deux champs svp</p>
                </IonText>
              )}
              {oversized && (
                <IonText>
                  <p>
                    Votre oeuvre dépasse L+H=180cm, obtenez un devis dans la
                    section ci-dessous
                  </p>
                </IonText>
              )}
              <IonButton
                className="text-button"
                color="dark"
                fill="solid"
                mode="ios"
                shape="round"
                onClick={showPricingHandler}
              >
                OBTENIR MON PRIX
              </IonButton>
            </IonCol>
            {showPricing && (
              <React.Fragment>
                {" "}
                <IonCol
                  className="ion-text-center ion-padding-top ion-margin-top"
                  size="12"
                  ref={pricingSectionRef}
                >
                  <IonText>
                    <h2>
                      Pour L+H ={" "}
                      {(
                        Number(widthInput.current?.value) +
                        Number(heightInput.current?.value)
                      ).toString()}
                      cm
                    </h2>
                  </IonText>
                </IonCol>
                <IonCol
                  className="ion-text-center ion-padding"
                  size="12"
                  size-md="10"
                  offset-md="1"
                  size-lg="8"
                  offset-lg="2"
                  size-xl="7"
                  offset-xl="2.5"
                  style={{ backgroundColor: "white" }}
                >
                  <IonText>
                    <h3>{lamaName}</h3>
                  </IonText>
                  <IonText
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      fontWeight: "600",
                    }}
                  >
                    <p
                      className="text-price"
                      style={{ marginTop: "0px", display: "inline-block" }}
                    >
                      {basePrice}
                      <p
                        style={{
                          textAlign: "center",
                          display: "inline-block",
                          fontSize: "1rem !important",
                          verticalAlign: "text-top",
                          marginTop: "0px",
                        }}
                      >
                        €
                      </p>
                    </p>
                  </IonText>
                  <IonText
                    style={{ display: "block" }}
                    color="primary"
                    className="text-stage"
                  >
                    INCLUS
                  </IonText>
                  <IonText>
                    <p>
                      Un Lama unique fait main en France par l’un de nos
                      artisans <br />
                    </p>
                    <p>
                      La collecte de votre sujet par LamaBox
                      <br />
                    </p>
                    <p>
                      Le tirage de votre photo en qualité galerie dans le cas
                      d’une oeuvre digitale <br />
                    </p>
                    <p>
                      Le système de fixation mural <br />
                    </p>
                    <p style={{ paddingBottom: "32px" }}>
                      100% de satisfaction
                      <br />
                    </p>
                  </IonText>
                  <IonText
                    style={{ display: "block" }}
                    color="primary"
                    className="text-stage"
                  >
                    OPTION
                  </IonText>
                  <IonText>
                    <p>
                      • Passe-partout spécial (extra-large, italien): +
                      {matSpecialPrice}€ <br />
                    </p>
                    <p>
                      • Montage spécial (flottant, profond): +
                      {mountSpecialPrice}€ <br />
                    </p>
                    <p>
                      • Cadre Lama Collection: +{collectionPrice}€ <br />
                    </p>
                  </IonText>
                </IonCol>
                <IonCol
                  size="12"
                  className="ion-text-center ion-padding"
                  style={{ paddingTop: "32px" }}
                >
                  <IonButton
                    size="large"
                    fill="solid"
                    color="dark"
                    mode="ios"
                    shape="round"
                    className="text-button"
                    routerDirection="forward"
                    routerLink="/what-we-frame-today?art_type=digital_photo"
                  >
                    JE CRÉE MON LAMA
                  </IonButton>
                </IonCol>
              </React.Fragment>
            )}
          </IonRow>
          <IonRow>
            <IonCol
              className="ion-text-center ion-padding y-align"
              size="12"
              size-md="6"
              /*size-md="10"
              offset-md="1"
              size-lg="8"
              offset-lg="2"
              size-xl="7"
              offset-xl="2.5"*/
              ref={oversizedSectionRef}
            >
              <IonText>
                <h1>Encore plus grand? Plus fou?</h1>{" "}
                <p>
                  Pour tous les sujets supérieurs à L+H=180cm, ou bien pour
                  toutes les demandes spécifiques, nous proposons un service sur
                  devis. N’hésitez-pas à nous contacter, chez Lama tout est
                  possible !
                </p>
                {/*</IonCol>*/}
                {/*<IonCol className="ion-text-center" size="12">*/}
                <IonButton
                  href="mailto: hello@lamafactory.com"
                  size="large"
                  fill="solid"
                  color="dark"
                  mode="ios"
                  shape="round"
                  className="text-button"
                >
                  CONTACTEZ-NOUS!
                </IonButton>
              </IonText>
            </IonCol>
            <ProgressiveImage
              src={"assets/img/pricing/oryx.jpg"}
              placeholder={"assets/img/pricing/oryx_tn.jpg"}
            >
              {(src: string | undefined, loading: any) => (
                <IonCol
                  size="12"
                  size-md="6"
                  className=""
                  style={{
                    minHeight: "480px",
                    backgroundImage: "url('" + src + "')",
                    filter: loading ? "blur(15px)" : "",
                    background: "no-repeat 52% center/cover",
                  }}
                ></IonCol>
              )}
            </ProgressiveImage>
          </IonRow>
          <FooterRows />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Pricing;
