import React, { useState } from "react";
import s3 from '../../data/s3';


const FramePreview: React.FC<{
  img_name: string;
  padding_bottom: string;
  art_top: string;
  art_left: string;
  mouldingHeight: string;
  frameToMatPct: string;
  art_bottom: string;
  art_path: string;
  mat_color: string;
  scaling: number;
  mount: string;
  hovering: boolean;
}> = (props) => {
  const [showDetailedView, setShowDetailedView] = useState<boolean>(false);

  const frameCStyle = {
    paddingBottom: props.padding_bottom + "%",
  } as React.CSSProperties;

  const frameShadowStyle = {
    top: props.mouldingHeight + "%",
    right: props.mouldingHeight + "%",
    bottom: props.mouldingHeight + "%",
    left: props.mouldingHeight + "%",
    zIndex: 0,
    boxShadow: "inset 0 0 2px 2px rgba(0, 0, 0, 0.25)",
  } as React.CSSProperties;

  const frameShadowDeepBoxStyle = {
    top: props.mouldingHeight + "%",
    right: props.mouldingHeight + "%",
    bottom: props.mouldingHeight + "%",
    left: props.mouldingHeight + "%",
    zIndex: 1,
    boxShadow:
      "inset 0.1rem -0.1rem 0rem 0rem #f5f5f5,\
    inset 0.2rem -0.2rem 0rem 0rem #f5f5f5,\
    inset 0.3rem -0.3rem 0rem 0rem #f5f5f5,\
    inset 0.4rem -0.4rem 0rem 0rem #f5f5f5,\
    inset 0.5rem -0.5rem 0rem 0rem #f5f5f5,\
    inset 0.6rem -0.6rem 0rem 0rem #f5f5f5,\
    inset -0.1rem 0.1rem 0rem 0rem #f5f5f5,\
    inset -0.2rem 0.2rem 0rem 0rem #f5f5f5,\
    inset -0.3rem 0.3rem 0rem 0rem #f5f5f5,\
    inset -0.4rem 0.4rem 0rem 0rem #f5f5f5,\
    inset -0.5rem 0.5rem 0rem 0rem #f5f5f5,\
    inset -0.6rem 0.6rem 0rem 0rem #f5f5f5",
  } as React.CSSProperties;

  {
    /* boxShadow: "inset 1px -1px 0px 0px #f0efeb,\
    inset 2px -2px 0px 0px #f0efeb,\
    inset 3px -3px 0px 0px #f0efeb,\
    inset 4px -4px 0px 0px #f0efeb,\
    inset 5px -5px 0px 0px #f0efeb,\
    inset 6px -6px 0px 0px #f0efeb,\
    inset 7px -7px 0px 0px #f0efeb,\
    inset 8px -8px 0px 0px #f0efeb,\
    inset 9px -9px 0px 0px #f0efeb,\
    inset 10px -10px 0px 0px #f0efeb,\
    inset 11px -11px 0px 0px #f0efeb,\
    inset 12px -12px 0px 0px #f0efeb,\
    inset 13px -13px 0px 0px #f0efeb,\
    inset 14px -14px 0px 0px #f0efeb,\
    inset 15px -15px 0px 0px #f0efeb,\
    inset 16px -16px 0px 0px #f0efeb,\
    inset 17px -17px 0px 0px #f0efeb,\
    inset 18px -18px 0px 0px #f0efeb,\
    inset -1px 1px 0px 0px #f0efeb,\
    inset -2px 2px 0px 0px #f0efeb,\
    inset -3px 3px 0px 0px #f0efeb,\
    inset -4px 4px 0px 0px #f0efeb,\
    inset -5px 5px 0px 0px #f0efeb,\
    inset -6px 6px 0px 0px #f0efeb,\
    inset -7px 7px 0px 0px #f0efeb,\
    inset -8px 8px 0px 0px #f0efeb,\
    inset -9px 9px 0px 0px #f0efeb,\
    inset -10px 10px 0px 0px #f0efeb,\
    inset -11px 11px 0px 0px #f0efeb,\
    inset -12px 12px 0px 0px #f0efeb,\
    inset -13px 13px 0px 0px #f0efeb,\
    inset -14px 14px 0px 0px #f0efeb,\
    inset -15px 15px 0px 0px #f0efeb,\
    inset -16px 16px 0px 0px #f0efeb,\
    inset -18px 18px 0px 0px #f0efeb"*/
  }

  const mouldingLeftStyle = {
    backgroundImage: "url('" + s3 + "public/assets/img/" + props.img_name + "')",
    height: props.mouldingHeight + "%",
    width: props.padding_bottom + "%",
  } as React.CSSProperties;

  const mouldingRightStyle = {
    backgroundImage: 'url("' + s3 + "public/assets/img/" + props.img_name + '")',
    height: props.mouldingHeight + "%",
    width: props.padding_bottom + "%",
  } as React.CSSProperties;

  const frameEdgeTopStyle = {
    backgroundImage: 'url("' + s3 + "public/assets/img/" + props.img_name + '")',
    height: props.mouldingHeight + "%",
    WebkitClipPath:
    "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
      props.frameToMatPct +
      "%) 100%, " +
      props.frameToMatPct +
      "% 100%)",
    clipPath:
      "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
      props.frameToMatPct +
      "%) 100%, " +
      props.frameToMatPct +
      "% 100%)",
  } as React.CSSProperties;

  const frameEdgeBottomStyle = {
    backgroundImage: 'url("' + s3 + "public/assets/img/" + props.img_name + '")',
    height: props.mouldingHeight + "%",
    WebkitClipPath:
    "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
    props.frameToMatPct +
    "%) 100%, " +
    props.frameToMatPct +
    "% 100%)",
    clipPath:
      "polygon(-1px -1px, calc(100% + 1px) -1px, calc(100% - " +
      props.frameToMatPct +
      "%) 100%, " +
      props.frameToMatPct +
      "% 100%)",
  } as React.CSSProperties;

  const matStyle = {
    background: props.mat_color,
    top: props.mouldingHeight + "%",
    bottom: props.mouldingHeight + "%",
    left: props.frameToMatPct + "%",
    right: props.frameToMatPct + "%",
  } as React.CSSProperties;

  const matStyleUS = {
    background: props.mat_color,
    top: props.mouldingHeight + "%",
    bottom: props.mouldingHeight + "%",
    left: props.frameToMatPct + "%",
    right: props.frameToMatPct + "%",
    zIndex: 24,
    boxShadow: "2px 5px 8px 4px rgba(0, 0, 0, 0.2)",
  } as React.CSSProperties;

  const artStyle = {
    top: props.art_top + "%",
    bottom: props.art_bottom + "%",
    left: props.art_left + "%",
    right: props.art_left + "%",
  } as React.CSSProperties;

  const artStyleFloat = {
    top: props.art_top + "%",
    bottom: props.art_bottom + "%",
    left: props.art_left + "%",
    right: props.art_left + "%",
    zIndex: 23,
    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.2)",
  } as React.CSSProperties;

  const artImgStyleSimple = {
    border: "1px solid",
    borderBottomColor: "#ffe",
    borderLeftColor: "#eed",
    borderRightColor: "#eed",
    borderTopColor: "#ccb",
    boxShadow: "0px 0px 7px 1px rgba(0, 0, 0, 0.5) inset",
  } as React.CSSProperties;

  const artImgStyle = {
    border: "",
    borderBottomColor: "",
    borderLeftColor: "",
    borderRightColor: "",
    borderTopColor: "",
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.5) inset",
  } as React.CSSProperties;

  const wallStyle = {
    //paddingBottom: Number(props.padding_bottom+10).toString()+"%"
    paddingBottom:
      (
        (Number(props.padding_bottom) + 10) *
        (Number(props.scaling) + (1 - Number(props.scaling)) / 2)
      ).toString() + "%",
  } as React.CSSProperties;

  const frameZoneStyle = {
    WebkitTransform: "scale(" + props.scaling.toString() + ")",
    transform: "scale(" + props.scaling.toString() + ")",
    top: (-((1 - props.scaling) / 4) * 100).toString() + "%",
  } as React.CSSProperties;


  return (
    <div
      onMouseEnter={(e) => setShowDetailedView(true)}
      onMouseLeave={(e) => setShowDetailedView(false)}
      onTouchStartCapture={(e) => setShowDetailedView(true)}
      onTouchEndCapture={(e) => setShowDetailedView(false)}
      className="wall"
      style={wallStyle}
    >
      
        {props.hovering && 
        <div className="frame-zone-image">
          <img
            className={showDetailedView?"fade-in":"fade-out"}
            width='100%'
            src={
              s3 +
              "public/assets/img/" +
              props.img_name.replace(".jpg", "") +
              "/" +
              props.img_name.replace(".jpg", "") +
              "3.jpg"
            }
          />
          </div>
          }
          
          <div className="ion-padding-top ion-padding-start ion-padding-end frame-zone" style={frameZoneStyle}>
            <div className={props.hovering ? (showDetailedView?"fade-out frame-c":"fade-in frame-c"):"frame-c"} style={frameCStyle}>
              <div
                id="frame-shadow"
                //data-v-2fafbb69=""
                style={
                  props.mount === "box"
                    ? frameShadowDeepBoxStyle
                    : frameShadowStyle
                }
              ></div>
              <div
                className="moulding moulding-left"
                id="left"
                style={mouldingLeftStyle}
              ></div>
              <div
                className="moulding moulding-right"
                id="right"
                style={mouldingRightStyle}
              ></div>
              <div
                className="frame-edge"
                id="top"
                style={frameEdgeTopStyle}
              ></div>
              <div
                className="frame-edge"
                id="bottom"
                style={frameEdgeBottomStyle}
              ></div>
              <div
                className="mat"
                id="matDiv"
                style={props.mount === "canvas" ? matStyleUS : matStyle}
              >
                {" "}
                <div
                  className="art"
                  id="art"
                  style={props.mount === "floating" ? artStyleFloat : artStyle}
                >
                  <img
                    id="art2"
                    style={
                      props.mount === "simple" ? artImgStyleSimple : artImgStyle
                    }
                    src={props.art_path}
                  />
                </div>
              </div>
            </div>

      </div>
    </div>
  );
};

export default FramePreview;
