import React, { useState, useRef } from "react";
import {
  IonPage,
  isPlatform,
  IonHeader,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import MountDisplay from "../../components/shop/MountDisplay";
import FrameDisplay from "../../components/shop/FrameDisplay";
import MatDisplay from "../../components/shop/MatDisplay";
import FooterRows from "../../components/shared/FooterRows";

const Guide: React.FC = () => {
  const [showKnowItAll, setShowKnowItAll] = useState<boolean>(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const knowItAllRef = useRef<HTMLIonColElement | null>(null);
  const stage1Ref = useRef<HTMLIonColElement | null>(null);
  const stage2Ref = useRef<HTMLIonColElement | null>(null);
  const stage3Ref = useRef<HTMLIonColElement | null>(null);

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const knowItAllHandler = async () => {
    setShowKnowItAll(!showKnowItAll);
    await delay(50);
    let y = knowItAllRef.current ? knowItAllRef.current.offsetTop : 300;
    contentRef.current && contentRef.current.scrollToPoint(0, y, 500);
  };

  const onMountChange = () => {
    let y = stage1Ref.current ? stage1Ref.current.offsetTop : 1600;
    isPlatform("mobile") &&
      contentRef.current &&
      contentRef.current.scrollToPoint(0, y, 500);
    return true;
  };

  const onStyleChange = () => {
    let y = stage2Ref.current ? stage2Ref.current.offsetTop : 1600;
    isPlatform("mobile") &&
      contentRef.current &&
      contentRef.current.scrollToPoint(0, y, 500);
    return true;
  };

  const onMatChange = () => {
    let y = stage3Ref.current ? stage3Ref.current.offsetTop : 2500;
    isPlatform("mobile") &&
      contentRef.current &&
      contentRef.current.scrollToPoint(0, y, 500);
    return true;
  };

  return (
    <IonPage>
      <IonHeader>
        <DesktopToolbar />
      </IonHeader>
      <IonContent ref={contentRef}>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol
              className="ion-text-center ion-padding"
              size="12"
              size-lg="6"
              offset-lg="3"
            >
              <IonText>
                <h1>Les 3 étapes clés pour un Lama réussi</h1>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow
            style={{
              backgroundColor: "var(--ion-color-secondary)",
              paddingTop: "16px",
            }}
          >
            <IonCol
              color="primary"
              className="ion-text-center ion-padding-start ion-padding-end"
              size="12"
              size-md="3.5"
              size-xl="2"
              offset-xl="1.5"
              offset-md="0.3"
              //style={{ paddingBottom: "64px" }}
            >
              <IonText className="ion-text-wrap">
                <h1 style={{ display: "block" }}>1.</h1>
                <h3 style={{ display: "block", marginTop: "0px" }}>
                  Le montage
                </h3>
                <p style={{ display: "block" }}>
                  Ou la manière dont sera exposée votre oeuvre à l’intérieur du
                  cadre
                </p>
              </IonText>
            </IonCol>
            <IonCol
              size-xl="2"
              offset-xl="1.5"
              className="ion-text-center ion-padding-start ion-padding-end"
              size="12"
              size-md="3.5"
              offset-md="0.3"
            >
              <IonText className="ion-text-wrap">
                <h1 style={{ display: "block" }}>2.</h1>
                <h3 style={{ display: "block", marginTop: "0px" }}>Le cadre</h3>
                <p style={{ display: "block" }}>
                  Ou cet entourage quadrangulaire appelé « baguette »
                </p>
              </IonText>
            </IonCol>
            <IonCol
              className="ion-text-center ion-padding-start ion-padding-end ion-padding-bottom ion-margin-bottom"
              size="12"
              size-md="3.5"
              offset-md="0.3"
              size-xl="2"
              offset-xl="1.5"
              style={{ paddingBottom: "32px" }}
            >
              <IonText className="ion-text-wrap">
                <h1 style={{ display: "block" }}>3.</h1>
                <h3 style={{ display: "block", marginTop: "0px" }}>
                  Le passe-partout
                </h3>
                <p style={{ display: "block" }}>
                  Ou ce fameux carton biseauté à 45° que l’on positionne sur
                  votre œuvre (ou pas)
                </p>
              </IonText>
            </IonCol>
            <IonCol size="12">
              <IonFab vertical="bottom" horizontal="center" edge>
                <IonFabButton
                  activated={showKnowItAll}
                  onClick={knowItAllHandler}
                  color="dark"
                >
                  <IonText className="text-fab-button">
                    <div style={{ display: "block" }}>TOUT</div>
                    <div style={{ display: "block" }}>SA</div>
                    <div style={{ display: "block" }}>VOIR</div>
                  </IonText>
                </IonFabButton>
              </IonFab>
            </IonCol>
            <IonCol
              hidden={!showKnowItAll}
              className="ion-padding"
              style={{
                backgroundColor: "white",
                paddingLeft: "32px",
                paddingRight: "32px",
                paddingTop: "64px",
                paddingBottom: "64px",
              }}
              size-md="6"
              offset-md="3"
              size="11"
              offset="0.5"
              ref={knowItAllRef}
            >
              <IonText>
                <h2 style={{ display: "block" }}>
                  Tout savoir sur les montages
                </h2>
                <p
                  className="justify-text ion-padding-bottom"
                  style={{ display: "block" }}
                >
                  Lorsqu’on parle de montage, on fait référence à la manière
                  dont sera exposé votre sujet au sein du cadre. Chez Lama
                  Factory vous pouvez choisir entre 4 types de montage donnant
                  des rendus tous aussi unique à votre encadrement.
                </p>
                <h2 style={{ display: "block" }}>Tout savoir sur les cadres</h2>
                <p
                  className="justify-text ion-padding-bottom"
                  style={{ display: "block" }}
                >
                  Le cadre, la baguette, ou encore la moulure, désigne
                  l’entourage quadrangulaire (oui, oui, quadrangulaire) qui
                  enserrera votre sujet afin de la mettre en valeur. Tous nos
                  cadres sont exclusivement issus des meilleurs matériaux et
                  assemblés à la main par nos artisans. Vous pouvez choisir
                  parmi des dizaines de cadres aux couleurs, formes et finitions
                  différentes, alors laissez libre court à votre créativité!
                </p>
                <h2 style={{ display: "block" }}>
                  Tout savoir sur les passe-partout
                </h2>
                <p className="justify-text" style={{ display: "block" }}>
                  Le passe-partout est le carton biseauté à 45° que l’on
                  positionne sur votre œuvre. Il a deux fonctionnalités clés :
                  la première est de protéger votre œuvre du contact direct avec
                  le verre de présentation, la seconde est de la mettre en
                  valeur en y apportant de la couleur, de la luminosité et de la
                  profondeur. Tous nos passe-partout sont sont sans-acide et
                  issus des meilleurs matériaux, de manière à assurer une
                  conservation optimale de votre sujet. Vous pouvez choisir
                  entre plusieurs couleurs, épaisseurs et style de passe-partout
                  afin de créer un Lama vraiment unique et original. Le
                  passe-partout, c’est tout un art.
                </p>
              </IonText>
            </IonCol>
            <IonCol
              hidden={!showKnowItAll}
              size="12"
              style={{ height: "70px" }}
            ></IonCol>
          </IonRow>
          <IonRow style={{ paddingTop: "64px", paddingBottom: "64px" }}>
            <IonCol
              className="ion-padding"
              size="10"
              size-md="6"
              offset-xl="1.5"
              offset-lg="1"
            >
              <IonText color="primary" className="text-stage">
                ÉTAPE 1
              </IonText>
              <IonText>
                <h1>Choisir son type de montage</h1>
              </IonText>
            </IonCol>
            <IonCol
              className="ion-padding"
              size="12"
              size-lg="10"
              size-xl="9"
              offset-xl="1.5"
              offset-lg="1"
              ref={stage1Ref}
            >
              <MountDisplay onChange={onMountChange} />
            </IonCol>
          </IonRow>
          <IonRow
            style={{
              backgroundColor: "var(--ion-color-light)",
              paddingTop: "64px",
              paddingBottom: "64px",
            }}
          >
            <IonCol
              className="ion-padding ion-text-right"
              size="10"
              size-md="6"
              size-xl="4.5"
              size-lg="5"
              offset-md="6"
              offset="2"
            >
              <IonText color="primary" className="text-stage">
                ÉTAPE 2
              </IonText>
              <IonText>
                <h1>Choisir son type de cadre</h1>
              </IonText>
            </IonCol>
            <IonCol
              ref={stage2Ref}
              className="ion-padding"
              size="12"
              size-lg="10"
              size-xl="9"
              offset-xl="1.5"
              offset-lg="1"
            >
              <FrameDisplay onChange={onStyleChange} />
            </IonCol>
          </IonRow>
          <IonRow
            style={{
              paddingTop: "64px",
              paddingBottom: "64px",
              //backgroundColor: "var(--ion-color-tertiary-tint)",
              backgroundColor: "var(--ion-color-tertiary-tint)",
            }}
          >
            <IonCol
              className="ion-padding"
              size="11"
              size-md="6"
              size-lg="5"
              size-xl="4.5"
              offset-xl="1.5"
              offset-lg="1"
            >
              <IonText color="primary" className="text-stage">
                ÉTAPE 3
              </IonText>
              <IonText>
                <h1>Choisir son type de passe-partout</h1>
              </IonText>
            </IonCol>
            <IonCol
              className="ion-padding"
              size="12"
              size-lg="10"
              size-xl="9"
              offset-xl="1.5"
              offset-lg="1"
              ref={stage3Ref}
            >
              <MatDisplay onChange={onMatChange} />
            </IonCol>
          </IonRow>
          <FooterRows />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Guide;
