import React, { useContext } from "react";
import { IonList, IonItem, IonLabel, IonText } from "@ionic/react";
import fContext from "../../context/f-context";

const IncludedList: React.FC = () => {
  const fCtx = useContext(fContext);
  return (
    <IonList
      className="ion-text-center ion-padding-top ion-padding-bottom"
      lines="none"
    >
      <IonItem key="title">
        <IonLabel className="ion-text-center ion-padding-bottom ion-text-wrap">
          <h2 style={{ fontWeight: 500 }}>Un Lama, c’est tout ça ❤️</h2>
        </IonLabel>
      </IonItem>
      <IonItem key="item-1">
        <IonLabel className="ion-text-center ion-text-wrap ion-no-margin">
          <h2>01.</h2>
          <div className="ion-text-center white-highlight div-line"></div>
          <IonText className="ion-text-wrap">
            <p className="ion-padding-bottom" style={{ paddingTop: "6px" }}>
              Le cadre sur mesure
            </p>
          </IonText>
        </IonLabel>
      </IonItem>
      {fCtx.artType !== "mounted_canvas" && (
        <React.Fragment>
          <IonItem key="item-2">
            <IonLabel className="ion-text-center ion-text-wrap ion-no-margin">
              <h2>02.</h2>
              <div className="ion-text-center white-highlight div-line"></div>
              <IonText className="ion-text-wrap">
                <p className="ion-padding-bottom" style={{ paddingTop: "6px" }}>
                  {fCtx.artType !== "unmounted_canvas"
                    ? "Le passe-partout sans-acide"
                    : "Le montage sur chassis en pin massif"}
                </p>
              </IonText>
            </IonLabel>
          </IonItem>
          {fCtx.artType !== "unmounted_canvas" && (
            <IonItem key="item-3">
              <IonLabel className="ion-text-center ion-text-wrap ion-no-margin">
                <h2>03.</h2>
                <div className="ion-text-center white-highlight div-line"></div>
                <IonText className="ion-text-wrap">
                  <p
                    className="ion-padding-bottom"
                    style={{ paddingTop: "6px" }}
                  >
                    Le verre acrylique résistant aux UV
                  </p>
                </IonText>
              </IonLabel>
            </IonItem>
          )}
        </React.Fragment>
      )}
      <IonItem key="item-4">
        <IonLabel className="ion-text-center ion-text-wrap ion-no-margin">
          <h2>
            {" "}
            {fCtx.mount !== "canvas"
              ? "04."
              : fCtx.artType !== "mounted_canvas"
              ? "03."
              : "02."}
          </h2>
          <div className="ion-text-center white-highlight div-line"></div>
          <IonText className="ion-text-wrap">
            <p className="ion-padding-bottom" style={{ paddingTop: "6px" }}>
            Le kraft de dos anti-poussière
            </p>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem key="item-5">
        <IonLabel className="ion-text-center ion-text-wrap ion-no-margin">
          <h2>
            {" "}
            {fCtx.mount !== "canvas"
              ? "05."
              : fCtx.artType !== "mounted_canvas"
              ? "04."
              : "03."}
          </h2>
          <div className="ion-text-center white-highlight div-line"></div>
          <IonText className="ion-text-wrap">
            <p className="ion-padding-bottom" style={{ paddingTop: "6px" }}>
              Le système d'accroches murales
            </p>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem key="item-6">
        <IonLabel className="ion-text-center ion-text-wrap ion-no-margin">
          <h2>
            {" "}
            {fCtx.mount !== "canvas"
              ? "06."
              : fCtx.artType !== "mounted_canvas"
              ? "05."
              : "04."}
          </h2>
          <div className="ion-text-center white-highlight div-line"></div>
          <IonText className="ion-text-wrap">
            <p className="ion-padding-bottom" style={{ paddingTop: "6px" }}>
              {fCtx.artType === "digital_photo"
                ? "L'impression photo"
                : "La collecte par LamaBox (voie postale)"}
            </p>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem key="item-7">
        <IonLabel className="ion-text-center ion-text-wrap ion-no-margin">
          <h2>
            {" "}
            {fCtx.mount !== "canvas"
              ? "07."
              : fCtx.artType !== "mounted_canvas"
              ? "06."
              : "05."}
          </h2>
          <div className="ion-text-center white-highlight div-line"></div>
          <IonText className="ion-text-wrap">
            <p className="ion-padding-bottom" style={{ paddingTop: "6px" }}>
              La promesse d'un encadrement unique
            </p>
          </IonText>
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

export default IncludedList;
