import React, { useState } from "react";
import {
  IonCard,
  isPlatform,
  IonFab,
  IonFabButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
} from "@ionic/react";
import s3 from "../../data/s3";
import ProgressiveImage from "react-progressive-graceful-image";

const ProductCardElement: React.FC<{
  name: string;
  collection_moulding: boolean;
  tags: string[];
  style: string;
}> = (props) => {
  const [showDetailedView, setShowDetailedView] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [showImageDetails, setShowImageDetails] = useState<boolean>(false);

  return (
    <IonCard
      button
      mode="md"
      key={props.name}
      routerDirection="forward"
      routerLink={`/product/${props.name}`}
      className={isPlatform("mobile") ? "no-ripple" : ""}
      onMouseEnter={(e) => setShowDetailedView(true)}
      onMouseLeave={(e) => setShowDetailedView(false)}
      onTouchStartCapture={(e) => setShowDetailedView(true)}
      onTouchEndCapture={(e) => setShowDetailedView(false)}
    >
      {props.collection_moulding && (
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            color="tertiary"
          >
            <IonText color='light' className="text-fab-button">
              <div style={{ display: "block" }}>CO</div>
              <div style={{ display: "block" }}>LEC</div>
              <div style={{ display: "block" }}>TION</div>
            </IonText>
          </IonFabButton>
        </IonFab>
      )}
      <IonGrid
        className="ion-padding"
        style={{
          paddingTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <IonRow>
          <IonCol size="12">
            <ProgressiveImage
              src={
                s3 +
                  "public/assets/img/" +
                  props.name +
                  "/" +
                  props.name +
                  "4.jpg"
              }
              placeholder={
                s3 +
                  "public/assets/img/" +
                  props.name +
                  "/" +
                  props.name +
                  "4_tn.jpg"
              }
            >
              {(src: string | undefined, loading: any) => (
                <img
                  style={{width:'1000px', maxWidth:'100% !important', filter: loading ? "blur(7px)" : "" }}
                  className={showDetailedView ? "fade-out" : "fade-in"}
                  src={src}
                  alt="an image"
                />
              )}
            </ProgressiveImage>
            <ProgressiveImage
              src={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.name +
                  "/" +
                  props.name +
                  "3.jpg"
              )}
              placeholder={encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.name +
                  "/" +
                  props.name +
                  "3_tn.jpg"
              )}
            >
              {(src: string | undefined, loading: any) => (
                <img
                  style={{ width:'1000px', maxWidth:'100% !important', filter: loading ? "blur(7px)" : "" }}
                  className={showDetailedView ? "fade-in" : "fade-out"}
                  src={src}
                  alt="an image"
                />
              )}
            </ProgressiveImage>
            <div
              className={
                "ion-text-start ion-padding-start ion-padding-end ion-margin-bottom"
              }
            >
              <IonText color="dark">
                <div className="text-product-title ion-padding-top">
                  {props.name}
                </div>
              </IonText>
              <div style={{ minHeight: "30px" }}>
                <IonText color="dark">
                  <div className="text-product-short-des">{props.style}</div>
                </IonText>
              </div>
            </div>
            {props.tags.map((tag: string) => (
              <React.Fragment>
                {tag !== "" && (
                  <IonBadge
                    mode="ios"
                    color="light"
                    className="text-product-tag"
                  >
                    <IonText className="text-product-tag">{tag}</IonText>
                  </IonBadge>
                )}
              </React.Fragment>
            ))}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default ProductCardElement;
