import React, { useContext } from "react";
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonText,
  IonTitle,
  IonBackButton,
  IonBadge,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import {
  basketOutline,
  personOutline,
  menuOutline,
  arrowBackOutline,
} from "ionicons/icons";
import AuthContext from "../../context/auth-context";
import cContext from "../../context/cart-context";

const DesktopToolbar: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(cContext);
  return (
    <IonToolbar
      style={{
        paddingTop: "4px",
        paddingBottom: "4px",
        backgroundColor: "white",
      }}
    >
      <IonButtons slot="start">
        <IonBackButton className="hidden-lg-up" color="dark" text="">
          <IonIcon icon={arrowBackOutline} />
        </IonBackButton>
      </IonButtons>
      <IonTitle
        style={{ position: "absolute" }}
        className="ion-text-center"
      >
        <IonButton
          routerLink="/"
          routerDirection="back"
          mode="ios"
          fill="clear"
        >
          <img style={{ maxHeight: "40px" }} src="assets/img/logo_black.png" />
        </IonButton>
      </IonTitle>
      <IonTitle
        style={{ position: "relative", height: "52px" }}
        className="ion-text-center"
      ></IonTitle>
    </IonToolbar>
  );
};

export default DesktopToolbar;
