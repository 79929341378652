import React from "react";
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonText,
  IonRouterLink,
  IonGrid,
  isPlatform,
} from "@ionic/react";
import { logoFacebook, logoInstagram, logoPinterest } from "ionicons/icons";

const FooterRows: React.FC = () => {
  return (
    <React.Fragment>
      <IonRow
        style={{
          background: "var(--ion-color-dark)",
          borderBottom: "solid 1px white",
        }}
      >
        <IonCol
          size="12"
          className="ion-text-center ion-padding-top ion-padding-bottom"
          style={{ borderBottom: "solid 1px white" }}
        >
          <IonButton
            href="https://www.facebook.com/lamafactory"
            target='Facebook'
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoFacebook} />
          </IonButton>
          <IonButton
            href="https://www.instagram.com/lamafactory_com/"
            target="Instagram"
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoInstagram} />
          </IonButton>
          <IonButton
            href="https://www.pinterest.fr/lama_factory_design/"
            target="Pinterest"
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoPinterest} />
          </IonButton>
        </IonCol>
        <IonCol               size="12"
              size-md="10"
              offset-md="1"
              size-lg="8"
              offset-lg="2"
              size-xl="7"
              offset-xl="2.5">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol
                size="12"
                className={isPlatform('mobile')?"ion-padding":"ion-padding y-align"}
              >
                <IonText className='ion-text-wrap ion-text-center' color="light">
                  <p style={{paddingLeft:'8px', paddingRight:'8px'}}>Une question ? Une idée à partager ?</p>
                  <IonRouterLink
                    color="light"
                    href="mailto: hello@lamafactory.com"
                  >
                    {" "}
                    <p style={{paddingLeft:'8px', paddingRight:'8px'}} className="text-footer-link">👉 hello@lamafactory.com</p>
                  </IonRouterLink>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
      <IonRow style={{ background: "var(--ion-color-dark)" }}>
        <IonCol
          size="12"
          size-md="6"
          className="ion-padding-start ion-text-left"
        >
          <IonText color="light">
            <p>© Lama Factory 2020, entre Lyon et Paris</p>
          </IonText>
        </IonCol>
        <IonCol
          size="12"
          size-md="6"
          className="ion-padding-end ion-text-right"
        >
          <IonText color="light">
            <IonRouterLink
              color="light"
              routerDirection="back"
              routerLink="/legal"
              style={{ display: "inline-block", paddingRight: "16px" }}
            >
              {" "}
              <p className="text-footer-link">Mentions légales</p>
            </IonRouterLink>
            <IonRouterLink
              color="light"
              routerDirection="back"
              routerLink="/trade"
              style={{ display: "inline-block" }}
            >
              {" "}
              <p className="text-footer-link">CGV</p>
            </IonRouterLink>
          </IonText>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default FooterRows;
