import React, { useState, useContext } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import {
  IonText,
  IonCol,
  IonCard,
  isPlatform,
} from "@ionic/react";
import fContext from "../../context/f-context";
import FullScreenImage from "./FullScreenImage";
import s3 from "../../data/s3";

const ProductDetailsDescriptionDesktop: React.FC<{
  moulding_name: string;
  moulding_style: string;
  moulding_description: string;
  out_w_cm: string;
  out_h_cm: string;
  art_by: string;
  scrollToDetails: () => void;
}> = (props) => {
  const [showDescription, setShowDescription] = useState<boolean>(
    isPlatform("desktop") ? true : false
  );
  const [showWhatsIncluded, setShowWhatsIncluded] = useState<boolean>(false);
  const fCtx = useContext(fContext);

  const [showFullScreenImage, setShowFullScreenImage] = useState<boolean>(
    false
  );
  const [imageFullScreenSrc, setImageFullScreenSrc] = useState<string>("");
  const [img1Spinner, setImg1Spinner] = useState<boolean>(true);
  const [img2Spinner, setImg2Spinner] = useState<boolean>(true);
  const [img3Spinner, setImg3Spinner] = useState<boolean>(true);
  const [img4Spinner, setImg4Spinner] = useState<boolean>(true);

  const showDescriptionHandler = () => {
    if (!showDescription) {
      props.scrollToDetails();
    }
    setShowDescription(!showDescription);
  };

  const showWhatsIncludedHandler = () => {
    if (showDescription && !showWhatsIncluded) {
      setShowDescription(!showDescription);
    }
    setShowWhatsIncluded(!showWhatsIncluded);
  };

  const showFullScreenImageHandler = (image_src: string) => {
    setImageFullScreenSrc(image_src);
    setShowFullScreenImage(true);
    return true;
  };

  const cancelShowFullScreenImage = () => {
    setShowFullScreenImage(false);
  };

  const scrollToBottom = () => {
    let list = document.querySelector("ion-content");
    return list && list.scrollByPoint(0, 100, 2);
  };

  return (
    <React.Fragment>
      <FullScreenImage
        image_src={imageFullScreenSrc}
        onCancel={cancelShowFullScreenImage}
        show={showFullScreenImage}
      />
      <IonCol size="5" offset="1" size-xl="5" offset-xl="1" className="">
        <IonCard
          mode="ios"
          button
          onClick={(e) =>
            showFullScreenImageHandler(
              encodeURI(
                s3 +
                  "public/assets/img/" +
                  props.moulding_name +
                  "/" +
                  props.moulding_name +
                  "1.jpg"
              )
            )
          }
        >
          <ProgressiveImage
            src={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "1.jpg"
            }
            placeholder={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "1_tn.jpg"
            }
          >
            {(src: string | undefined, loading: any) => (
              <img
                style={{ width:'1200px', maxWidth:'100% !important', filter: loading ? "blur(7px)" : "" }}
                src={src}
                alt="an image"
              />
            )}
          </ProgressiveImage>
        </IonCard>
      </IonCol>
      <IonCol size="5" size-xl="5">
        <IonCard
          mode="ios"
          button
          onClick={(e) =>
            showFullScreenImageHandler(
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "2.jpg"
            )
          }
        >
          <ProgressiveImage
            src={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "2.jpg"
            }
            placeholder={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "2_tn.jpg"
            }
          >
            {(src: string | undefined, loading: any) => (
              <img
                style={{ width:'1200px', maxWidth:'100% !important', filter: loading ? "blur(7px)" : "" }}
                src={src}
                alt="an image"
              />
            )}
          </ProgressiveImage>
        </IonCard>
      </IonCol>
      <IonCol size="5" offset="1" size-xl="5" offset-xl="1">
        <IonCard
          mode="ios"
          button
          onClick={(e) =>
            showFullScreenImageHandler(
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "3.jpg"
            )
          }
        >
          <ProgressiveImage
            src={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "3.jpg"
            }
            placeholder={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "3_tn.jpg"
            }
          >
            {(src: string | undefined, loading: any) => (
              <img
                style={{ width:'1200px', maxWidth:'100% !important', filter: loading ? "blur(7px)" : "" }}
                src={src}
                alt="an image"
              />
            )}
          </ProgressiveImage>
        </IonCard>
      </IonCol>
      <IonCol size="5" size-xl="5">
        <IonCard
          mode="ios"
          button
          onClick={(e) =>
            showFullScreenImageHandler(
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "4.jpg"
            )
          }
        >
          <ProgressiveImage
            src={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "4.jpg"
            }
            placeholder={
              s3 +
                "public/assets/img/" +
                props.moulding_name +
                "/" +
                props.moulding_name +
                "4_tn.jpg"
            }
          >
            {(src: string | undefined, loading: any) => (
              <img
                style={{ width:'1200px', maxWidth:'100% !important', filter: loading ? "blur(7px)" : "" }}
                src={src}
                alt="an image"
              />
            )}
          </ProgressiveImage>
        </IonCard>
      </IonCol>
      <IonCol
        className="ion-text-center ion-padding-top ion-padding-bottom"
        size="12"
        size-md="10"
        offset-md="1"
        size-lg="8"
        offset-lg="2"
        size-xl="7"
        offset-xl="2.5"
      >
        <IonText>
          <p>
            <i>{props.art_by}</i>
          </p>
        </IonText>
      </IonCol>

      {/*</div>*/}
    </React.Fragment>
  );
};

export default ProductDetailsDescriptionDesktop;
