import React, { useContext, useState } from "react";
import {
  IonPage,
  IonButton,
  IonContent,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonList,
  IonLabel,
  IonInput,
  IonSpinner,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { useHistory } from "react-router";

import AuthContext from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import FooterRows from "../../components/shared/FooterRows";
import ResetPasswordModal from "../../components/order/ResetPasswordModal";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// set the default values for the controls
let initialValues = {
  email: "",
  password: "",
};

const Login: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState,
    reset,
    errors,
    getValues,
    setValue,
  } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });

  //I KEEP because it can help to prevent double query
  /* const authSubmitHandler = async (event: { preventDefault: () => void }) => {
    event.preventDefault();*/

  const authSubmitHandler = async (data: any) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}api/users/login`,
        "POST",
        JSON.stringify(
          data,
        ),
        {
          "Content-Type": "application/json",
        }
      );
      console.log(responseData);
      authCtx.login(responseData.userId, responseData.token);
      history.push("/account/orders");
      window.location.reload();
    } catch (err) {
      alert(err.message);
    }
  };
  const resetPasswordHandler = async () => {

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}api/users/requestResetToken`,
        "POST",
        JSON.stringify({ email: getValues("email")}),
        {
          "Content-Type": "application/json",
        }
      );

      setShowModal(true);
    } catch (err) {
      console.log(err);
      setShowModal(true);
    }
  };

  const onDismissModalHandler = () => {
    setShowModal(false);
    return true;
  };

  return (
    <IonPage>
      <ResetPasswordModal
        email={getValues("email")}
        onDismiss={onDismissModalHandler}
        show={showModal}
      />
      <IonHeader>
        <DesktopToolbar />
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-no-padding">
          <form onSubmit={handleSubmit((data) => authSubmitHandler(data))}>
            <IonRow
              style={{
                minHeight: "calc(100vh - 150px)",
                paddingBottom: "80px",
              }}
            >
              <IonCol size="12" size-lg="6" offset-lg="3">
                <IonItem lines="none">
                  <IonText>
                    <h2>Mon compte</h2>
                  </IonText>
                </IonItem>
                <IonCard mode="md">
                  <IonCardContent>
                    <IonList>
                      <IonItem mode="md" key="email">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap "
                          position="floating"
                        >
                          Email *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="email"
                              inputmode="text"
                              autocomplete="on"
                              autocorrect="on"
                              clearInput={true}
                              //onIonInput={onShippingChangeHandler}
                              onIonChange={onChange}
                              value={value}
                              enterkeyhint="go"
                            />
                          )}
                          control={control}
                          name="email"
                          rules={{
                            required: "Champs requis"
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      <IonItem mode="md" key="password">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap "
                          position="floating"
                        >
                          Mot de passe *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="password"
                              inputmode="text"
                              autocomplete="on"
                              autocorrect="on"
                              clearInput={true}
                              //onIonInput={onShippingChangeHandler}
                              onIonChange={onChange}
                              value={value}
                              enterkeyhint="go"
                            />
                          )}
                          control={control}
                          name="password"
                          rules={{
                            required: "Champ requis",
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="password"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      <IonItem mode="md" key="password_reset" lines="none">
                        <IonButton
                          onClick={resetPasswordHandler}
                          fill="clear"
                          mode="ios"
                          slot="end"
                        >
                          <IonText>
                            <p className="text-button">RÉ-INITIALISER?</p>
                          </IonText>
                        </IonButton>
                      </IonItem>
                      <IonButton
                        //size="large"
                        className="ion-no-margin ion-margin-top"
                        type="submit"
                        mode="ios"
                        expand="full"
                        color="dark"
                        disabled={isLoading}
                      >
                        <IonLabel>
                          <p className="text-button">SE CONNECTER</p>
                        </IonLabel>
                        {isLoading && (
                          <IonSpinner
                            className="ion-margin-left"
                            color="tertiary"
                            name="crescent"
                          />
                        )}
                      </IonButton>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <FooterRows />
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
