import React, { useRef, useState, useContext } from "react";
import "../../theme/build-size.css";
import { getDivMulti } from "../../util/frame";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  isPlatform,
  IonImg,
  IonCard,
  IonToast,
} from "@ionic/react";
import {
  closeOutline,
  arrowForwardOutline,
  informationCircleOutline,
  arrowBackOutline,
} from "ionicons/icons";
import DeleteLamAlert from "../../components/shared/DeleteLamAlert";
import fContext from "../../context/f-context";
import { useHistory } from "react-router";
import SizeRange from "../../components/shop/SizeRange";
import { useHttpClient } from "../../hooks/http-hook";
import PriceContext from "../../context/price-context";
import WizardLama from "../../components/shop/WizardLama";
import DesktopToolbar from "../../components/shared/DesktopToolbar";
import BuildSizeModal from "../../components/shop/BuildSizeModal";
import LivingPreview from "../../components/shared/LivingPreview";

const BuildSize: React.FC = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const fCtx = useContext(fContext);
  const priceCtx = useContext(PriceContext);
  const widthInput = useRef<HTMLIonInputElement>(null);
  const heightInput = useRef<HTMLIonInputElement>(null);
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [basePrice, setBasePrice] = useState<Number>(0);
  const [lamaName, setLamaName] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [sizeComplete, setSizeComplete] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  

  //const image_ratio -> change label into inout for size and auto adjust with ratio

  const sizeInputHandler = () => {
    if (widthInput.current && heightInput.current) {
      const widthSelected = widthInput.current!.value;
      const heightSelected = heightInput.current!.value;

      const from_price = priceCtx.getPricePerDim(
        Number(widthSelected) + Number(heightSelected)
      );

      if (from_price) {
        if (fCtx.artType === "unmounted_canvas") {
          setBasePrice(
            Number(from_price.base_price) + Number(from_price.canvas_mount)
          );
        } else {
          setBasePrice(from_price.base_price);
        }
        switch (from_price.name) {
          case "small": {
            setLamaName("Bébé Lama");
            break;
          }
          case "medium": {
            setLamaName("Moyen Lama");
            break;
          }
          case "large": {
            setLamaName("Large Lama");
            break;
          }
          case "grand": {
            setLamaName("Grand Lama");
            break;
          }
          case "super": {
            setLamaName("Super Lama");
            break;
          }
          case "mega": {
            setLamaName("Mega Lama");
            break;
          }
          case "maxi": {
            setLamaName("Maxi Lama");
            break;
          }
          default: {
            setLamaName("Hors Catégorie");
          }
        }
      }
      fCtx.selectSize(Number(widthSelected), Number(heightSelected), 0);
    }
  };

  const showModalHandler = () => {
    setShowModal(true);
  };

  const dismissModalHandler = () => {
    setShowModal(false);
  };

  const closeButtonHandler = () => {
    setShowAlert(true);
  };

  const cancelShowAlertHandler = () => {
    setShowAlert(false);
  };

  const showToastHandler = () =>{
    setShowToast(true);
  }

  const dismissToastHandler = () =>{
    setShowToast(false);
  }

  const calculatePriceHandlerSlider = (price_index: Number) => {
    const from_price = priceCtx.getPricePerDim(
      Number(fCtx.wList[Number(price_index)]) +
        Number(fCtx.hList[Number(price_index)])
    );
    //console.log(from_price);
    if (from_price) {
      if (fCtx.artType === "unmounted_canvas") {
        setBasePrice(
          Number(from_price.base_price) + Number(from_price.canvas_mount)
        );
      } else {
        setBasePrice(from_price.base_price);
      }
      switch (from_price.name.toString()) {
        case "small": {
          setLamaName("Bébé Lama");
          break;
        }
        case "medium": {
          setLamaName("Moyen Lama");
          break;
        }
        case "large": {
          setLamaName("Large Lama");
          break;
        }
        case "grand": {
          setLamaName("Grand Lama");
          break;
        }
        case "super": {
          setLamaName("Super Lama");
          break;
        }
        case "mega": {
          setLamaName("Mega Lama");
          break;
        }
        case "maxi": {
          setLamaName("Maxi Lama");
          break;
        }
        default: {
          setLamaName("Hors Catégorie");
        }
      }
    }
  };

  const deleteLamaHandler = () => {
    fCtx.deleteFrame();
    setShowAlert(false);
    history.push("/");
  };

  const galleryHandler = async () =>{

    if(fCtx.artType === '' || fCtx.artType === 'digital_photo'){
      history.push('/gallery');
    }else{

    try {
      const formData = new FormData();
      formData.append("art_path", fCtx.imageUrl.toString());
      formData.append("article_id", fCtx.articleId.toString());
      formData.append("width", fCtx.imageWidth.toString());
      formData.append("height", fCtx.imageHeight.toString());
      formData.append("w_selected", fCtx.wSelected.toString());
      formData.append("h_selected", fCtx.hSelected.toString());
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/resizePhysical",
        "POST",
        formData
      );
      fCtx.selectImageSize(Number(responseData.new_width), Number(responseData.new_height),responseData.new_path);
        history.push("/gallery");
    } catch (err) {
      console.log(err);
    }
  }
    console.log(fCtx.artType);
    return true;
  }

  var art_w_cm = fCtx.wSelected; //width art in cmkkdmdkkdfdkkeekedkkdkelkfjekeekejwkwnwwdjejwkeekkekmekeekekekeekeekikeldedelslkdldkfjekeekedmssdjdkekeekmdskkekeknekenelellekskkdkkklkmdldkfmekekdk
  var art_w = fCtx.imageWidth; //width art in px
  var art_h = fCtx.imageHeight; //height art in px
  var biseau_w_cm = 0.18; // biseau width cmjdekemleldkdkdejkkwkwjwjkwjkdsskwkwkdmdkkddkdiekdedkdjks
  var mode = "standard";
  var matSize = 4;

  var mouldingWidth = 2; //moulding width in cm

  var scaling =
    (Number(20) + 2 * matSize + 2 * Number(mouldingWidth)) /
    (1.1 * (Number(20) + 2 * 16 + 2 * Number(mouldingWidth)));

  const div = getDivMulti(
    art_w,
    art_h,
    mode,
    matSize,
    art_w_cm,
    mouldingWidth,
    biseau_w_cm
  );

  //const padding_bottom = div.padding_bottom.toString();

  const scrollToBottom = () => {
    let list = document.querySelector("ion-content");
    return list && list.scrollToBottom();
  };

  /*useEffect(() => {
    const fetchPrices = async () => {
      try {
        //replace hardcoded eur by context variable
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/prices_" + "eur"
        );
        priceCtx.populatePriceList(responseData.prices);
      } catch (err) {}
    };
    fetchPrices();
  }, [sendRequest]);*/

  return (
    <React.Fragment>
            <IonToast
        isOpen={showToast}
        message={"Taille maximum d'impression de votre image afin de préserver sa qualité"}
        onDidDismiss={(e) => setShowToast(false)}
        keyboardClose={true}
        mode='ios'
        duration={10000}
        position='top'
        buttons={[
          {
            icon: "close",
            role: "cancel",
            side: "start",
            handler: () => {
              setShowToast(false);
            },
          },
        ]}
      />
      <BuildSizeModal show={showModal} onDismiss={dismissModalHandler} />
      <DeleteLamAlert
        show={showAlert}
        onCancel={cancelShowAlertHandler}
        onDelete={deleteLamaHandler}
      />
      <IonPage>
        <IonHeader
          class={isPlatform("mobile") ? "ion-no-border" : ""}
          translucent={true}
        >
          {isPlatform("mobile") && (
            <IonToolbar style={{ backgroundColor: "white" }} className="">
              <IonButtons slot="start">
                <IonButton routerLink="/build-digital" routerDirection="back">
                  <IonIcon icon={arrowBackOutline} />
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton onClick={closeButtonHandler}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle></IonTitle>
            </IonToolbar>
          )}
          {isPlatform("desktop") && <DesktopToolbar />}
        </IonHeader>
        <IonContent scrollY={true} color="undefined">
          <IonGrid
            className={
              fCtx.artType === "digital_photo"
                ? "ion-no-padding ion-full-height"
                : "ion-no-padding"
            }
          >
            {isPlatform("desktop") && <WizardLama />}
            <IonRow
              className={
                fCtx.artType === "digital_photo"
                  ? "ion-align-items-start ion-full-height"
                  : "ion-align-items-start"
              }
            >
              <IonCol color="undefined" size="12" class="ion-text-center">
                {(fCtx.artType === "document" ||
                  fCtx.artType === "object" ||
                  fCtx.artType === "textil" ||
                  fCtx.artType === "art_on_paper" ||
                  fCtx.artType === "mounted_canvas" ||
                  fCtx.artType === "unmounted_canvas") && (
                  <IonText color="dark">
                    <h1>Quelles sont ses dimensions?</h1>
                    <p>
                      Pas d’inquiétude, nos artisans reprendront précisément
                      les mesures de votre sujet dès son arrivée à la Lama
                      Factory.
                    </p>
                  </IonText>
                )}
                {fCtx.artType === "digital_photo" && (
                  <React.Fragment>
                    <div style={{ display: "inline-block" }}>
                      <IonText color="dark">
                        <h1>
                          {isPlatform("mobile")
                            ? "Quelle taille d'impression?"
                            : "Quelle taille d'impression souhaitez-vous?"}
                        </h1>
                      </IonText>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        display: "inline-block",
                        marginTop: "12px",
                      }}
                    >
                      <IonButton
                        className="ion-no-margin"
                        mode="ios"
                        size="large"
                        fill="clear"
                        color="primary"
                        onClick={showModalHandler}
                        style={{ display: "inline-block" }}
                      >
                        <IonIcon icon={informationCircleOutline} />
                      </IonButton>
                    </div>
                  </React.Fragment>
                )}
              </IonCol>
              {fCtx.artType === "digital_photo" && (
                <React.Fragment>
               {false && <IonCol
                  className="ion-text-center ion-no-padding equal-sized-item-desktop"
                  size="12"
                  size-xl="9"
                  offset-xl="1.5"
                >
                  {/*<SizePreview
                    art_bottom={"0"}
                    art_left={"0"}
                    art_top={"0"}
                    frameToMatPct={"0"}
                    img_name={img_name}
                    mouldingHeight={"0"}
                    padding_bottom={padding_bottom}
                    //art_path={"assets/img/sample.jpg"}
                    art_path={process.env.REACT_APP_BACKEND_URL + fCtx.imageUrl}
                    living_room_img={"assets/img/proj_desktop.jpg"}
                    living_room_width={226}
                    selected_w={Number(fCtx.wSelected)}
                  />*/}
                  {/*<LivingPreview
                    art_bottom={"0"}
                    art_left={"0"}
                    art_top={"0"}
                    frameToMatPct={"0"}
                    img_name={"Mambo (blanc).jpg"}
                    mouldingHeight={"2"}
                    padding_bottom={padding_bottom}
                    art_path={process.env.REACT_APP_BACKEND_URL+fCtx.imageUrl}
                    //living_room_img={"assets/img/living_room.jpg"}
                    living_room_img={"assets/img/proj_desktop.jpg"}
                    //living_room_width={310}
                    living_room_width={226}
                    selected_w={Number(fCtx.wSelected)}
                    mat_color={fCtx.matColor}
                    mat_size={Number(fCtx.matSize)}
                    moulding_width={2}
                    mount={fCtx.mount}
                  />*/}
                   <LivingPreview
                    art_bottom={div.art_bottom.toString()}
                    art_left={div.art_left.toString()}
                    art_top={div.art_top.toString()}
                    frameToMatPct={div.frameToMatPct.toString()}
                    img_name={"Mambo (blanc).jpg"}
                    mouldingHeight={div.mouldingHeight.toString()}
                    padding_bottom={div.padding_bottom.toString()}
                    art_path={fCtx.imageUrl.includes("uploads/images")? process.env.REACT_APP_BACKEND_URL + fCtx.imageUrl:fCtx.imageUrl}
                    //living_room_img={"assets/img/living_room.jpg"}
                    living_room_img={"assets/img/proj_desktop.jpg"}
                    //living_room_width={310}
                    living_room_width={226}
                    selected_w={Number(fCtx.wSelected)}
                    mat_color={fCtx.matColor}
                    mat_size={Number(fCtx.matSize)}
                    moulding_width={2}
                    mount={fCtx.mount}
                    fullscreen={true}
                  />
                </IonCol>}
                </React.Fragment>
              )}
              {(fCtx.artType === "document" ||
                fCtx.artType === "object" ||
                fCtx.artType === "textil" ||
                fCtx.artType === "art_on_paper" ||
                fCtx.artType === "physical_photo" ||
                fCtx.artType === "mounted_canvas" ||
                fCtx.artType === "unmounted_canvas") && (
                <React.Fragment>
                  <IonCol
                    size="12"
                    size-md="6"
                    offset-md="3"
                    className={"ion-padding-top ion-text-center"}
                    style={{ paddingBottom: "32px" }}
                  >
                    <IonImg
                      style={{ width: "180px", margin: "auto" }}
                      src={"assets/img/build-size/physical_artwork_size.png"}
                    />
                    <IonGrid className="">
                      <IonRow>
                        <IonCol
                          offset="1"
                          size="4"
                          color="undefined"
                          class="ion-text-center"
                        >
                          <IonItem className="ion-text-center" lines="full">
                            <IonLabel
                              className="ion-text-wrap"
                              position="stacked"
                            >
                              Largeur
                            </IonLabel>
                            <IonInput
                              type="number"
                              ref={widthInput}
                              onIonChange={sizeInputHandler}
                              pattern="\d*"
                              clearOnEdit={true}
                              inputmode="decimal"
                              required={true}
                              autofocus={true}
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          size="1"
                          color="undefined"
                          class="ion-text-center"
                        >
                          <h2>x</h2>
                        </IonCol>
                        <IonCol
                          size="4"
                          color="undefined"
                          class="ion-text-center"
                        >
                          <IonItem className="ion-text-center" lines="full">
                            <IonLabel position="stacked">Hauteur</IonLabel>
                            <IonInput
                              type="number"
                              ref={heightInput}
                              onIonChange={sizeInputHandler}
                              pattern="\d*"
                              clearOnEdit={true}
                              inputmode="decimal"
                              required={true}
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          size="2"
                          color="undefined"
                          className="ion-text-center ion-padding-end"
                        >
                          <IonText color="dark">
                            <h2>cm</h2>
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </React.Fragment>
              )}
              {fCtx.artType === "digital_photo" && (
                <SizeRange
                  calculatePriceHandler={calculatePriceHandlerSlider}
                  showToast={showToastHandler}
                  dismissToast={dismissToastHandler}
                />
              )}
              {lamaName !== '' && <IonCol size="12" className="ion-text-center ion-padding-bottom">
                <IonCard
                  mode="ios"
                  className='no-shadow'
                  style={{
                    backgroundColor: "white",
                    maxWidth: "200px",
                    margin: "auto",
                    boxShadow:'0 0 black !important'
                  }}
                >
                  <IonText style={{ display: "block" }} color="dark">
                    {basePrice === -1 && <p>Sur devis (L+l {">"} 180cm)</p>}
                    {basePrice > 0 && basePrice && (
                      <React.Fragment>
                        <h2>{lamaName}</h2>
                        <p style={{ display: "block" }}>à partir de </p>
                        <p
                          className="text-price"
                          style={{
                            marginTop: "0px",
                            display: "inline-block",
                            marginBottom: "16px",
                          }}
                        >
                          {basePrice.toString()}
                          <p
                            style={{
                              textAlign: "center",
                              display: "inline-block",
                              fontSize: "1rem !important",
                              verticalAlign: "text-top",
                              marginTop: "0px",
                            }}
                          >
                            €
                          </p>
                        </p>
                      </React.Fragment>
                    )}
                  </IonText>
                </IonCard>
              </IonCol>}
              {isPlatform("desktop") && (
                <IonCol
                  size="12"
                  className="ion-text-center ion-padding-bottom"
                >
                  <IonButton
                    size="large"
                    color="dark"
                    mode="ios"
                    shape="round"
                    class="ion-no-margin"
                    onClick={galleryHandler}
                    style={{
                      "--padding-start": "48px",
                      "--padding-end": "48px",
                    }}
                    disabled={
                      fCtx.artType === "digital_photo"
                        ? false
                        : !(
                            widthInput.current &&
                            heightInput.current &&
                            widthInput.current?.value !== "" &&
                            heightInput.current?.value !== ""
                          )
                    }
                  >
                    <IonText>
                      <p className="text-button">SUIVANT</p>
                    </IonText>
                    <IonIcon icon={arrowForwardOutline} />
                  </IonButton>
                </IonCol>
              )}
              {isPlatform("mobile") && (
                <IonCol size="12" className="ion-text-center">
                  <IonButton
                    strong
                    size="large"
                    color="dark"
                    expand="full"
                    class="ion-no-margin"
                    onClick={galleryHandler}
                    disabled={
                      fCtx.artType === "digital_photo"
                        ? false
                        : !(
                            widthInput.current &&
                            heightInput.current &&
                            widthInput.current?.value !== "" &&
                            heightInput.current?.value !== ""
                          )
                    }
                  >
                    <IonText>
                      <p className="text-button">SUIVANT</p>
                    </IonText>
                  </IonButton>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
        {/*isPlatform("mobile") && (
          <IonFooter color="dark" class="ion-text-center">
            <IonButton
              strong
              size="large"
              color="dark"
              expand="full"
              class="ion-no-margin"
              routerLink="/gallery"
              routerDirection="forward"
              disabled={
                fCtx.artType === "digital_photo"
                  ? false
                  : !(
                      widthInput.current &&
                      heightInput.current &&
                      widthInput.current?.value !== "" &&
                      heightInput.current?.value !== ""
                    )
              }
            >
              <IonText>
                <p className="text-button">SUIVANT</p>
              </IonText>
            </IonButton>
          </IonFooter>
            )*/}
      </IonPage>
    </React.Fragment>
  );
};
export default BuildSize;
