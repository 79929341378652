export const getDivMulti = (
  art_w,
  art_h,
  mode,
  mat,
  art_w_cm,
  mouldingWidth,
  biseau_w_cm
) => {
  var frameToArtPct =
    (mat + mouldingWidth) / (2 * mat + 2 * mouldingWidth + art_w_cm);
  var frameToMatPct = mouldingWidth / (2 * mat + 2 * mouldingWidth + art_w_cm);
  //var aspectRatio = art_w / art_h;

  //Define frame padding
  var frameWidth = art_w / (1 - 2 * frameToArtPct);
  if (mode == "italiano") {
    if (art_w >= art_h) {
      var frameHeight = 2 * art_h + 2 * frameToArtPct * frameWidth;
    } else {
      var frameHeight = art_w / 2 + art_h + 2 * frameToArtPct * frameWidth;
    }
  } else {
    var frameHeight = art_h + 2 * frameToArtPct * frameWidth;
  }
  var frameAspectRatio = frameHeight / frameWidth;
  //console.log('padding:'+frameAspectRatio);

  //Define mat padding
  var matTopAndBottom = (frameToMatPct * frameWidth) / frameHeight;

  //Define art padding
  var distToFrame = (frameToArtPct - frameToMatPct) * frameWidth;
  var matWidth = (1 - 2 * frameToMatPct) * frameWidth;
  var artLeft = distToFrame / matWidth;
  //console.log('left:'+artLeft);

  var matHeight = (1 - 2 * matTopAndBottom) * frameHeight;
  var artTop = distToFrame / matHeight;
  if (mode == "italiano") {
    if (art_w >= art_h) {
      var distToFrameIT =
        (frameToArtPct + art_h / frameWidth - frameToMatPct) * frameWidth;
    } else {
      var distToFrameIT =
        (frameToArtPct + art_w / frameWidth / 2 - frameToMatPct) * frameWidth;
    }

    var matHeightIT = (1 - 2 * matTopAndBottom) * frameHeight;
    console.log(distToFrameIT);
    console.log(matHeightIT);
    var art_bottom = distToFrameIT / matHeightIT;
    console.log(art_bottom);
  } else {
    var art_bottom = artTop;
  }

  //Set CSS variable
  var padding_bottom = Number((frameAspectRatio * 100).toFixed(3));
  var art_top = Number((artTop * 100).toFixed(3));
  var art_left = Number((artLeft * 100).toFixed(3));
  var mouldingHeight = Number(
    (((frameToMatPct * frameWidth) / frameHeight) * 100).toFixed(3)
  );
  var frameToMatPct = Number((100 * frameToMatPct).toFixed(3));
  var art_bottom = Number((100 * art_bottom).toFixed(3));
  var biseau_mult = Number((biseau_w_cm / art_w_cm).toFixed(3));

  return {
    padding_bottom: padding_bottom,
    art_top: art_top,
    art_left: art_left,
    mouldingHeight: mouldingHeight,
    frameToMatPct: frameToMatPct,
    art_bottom: art_bottom,
    biseau_mult: biseau_mult, //multiplier biseau with art w
  };
};

export const getOutsideDimensions = (
  art_w_cm,
  art_h_cm,
  mode,
  mat,
  mouldingWidth,
  mount,
  artType
) => {
  //Hypothesis
  ////Passe partout mount, passe partout 'eat' 2mm on the artwork
  const pp_offset = 0.2;
  ////Floating mount, full size artwork
  ////Canava mount, 12mm overlay on the moulding (width of the stair moulding space)
  const canvas_overlay = 1.2;

  let out_w_cm;
  let out_h_cm;

  if (
    artType === "unmounted_canvas" ||
    artType === "mounted_canvas" ||
    artType === "printed_canvas"
  ) {
    out_w_cm =
    Number(art_w_cm) -
    2 * canvas_overlay +
    2 * Number(mouldingWidth);
  out_h_cm =
    Number(art_h_cm) -
    2 * canvas_overlay +
    2 * Number(mouldingWidth);
  } else {
    if (mode === "standard") {
      if (mount === "simple" || mount === "box") {
        out_w_cm =
          Number(art_w_cm) -
          2 * pp_offset +
          2 * Number(mat) +
          2 * Number(mouldingWidth);
        out_h_cm =
          Number(art_h_cm) -
          2 * pp_offset +
          2 * Number(mat) +
          2 * Number(mouldingWidth);
      } else if (mount === "floating") {
        out_w_cm =
          Number(art_w_cm) + 2 * Number(mat) + 2 * Number(mouldingWidth);
        out_h_cm =
          Number(art_h_cm) + 2 * Number(mat) + 2 * Number(mouldingWidth);
      }
    } else if (mode === "italiano") {
      if (mount === "simple" || mount === "box") {
        out_w_cm =
          Number(art_w_cm) -
          2 * pp_offset +
          2 * Number(mat) +
          2 * Number(mouldingWidth);
        out_h_cm =
          Number(art_h_cm) +
          Number(art_w_cm) -
          2 * pp_offset +
          2 * Number(mat) +
          2 * Number(mouldingWidth);
      } else if (mount === "floating") {
        out_w_cm =
          Number(art_w_cm) + 2 * Number(mat) + 2 * Number(mouldingWidth);
        out_h_cm =
          Number(art_h_cm) +
          Number(art_w_cm) +
          2 * Number(mat) +
          2 * Number(mouldingWidth);
      }
    }
  }

  if(out_w_cm){out_w_cm = out_w_cm.toFixed(1);}
  if(out_h_cm){out_h_cm = out_h_cm.toFixed(1);}

  return{out_w_cm, out_h_cm};
};

//GET MAXIMUM PRINTABLE SIZE LIST GIVEN PHOTO ATTRIBUTES ///////////////////////////////////////////////////////////

export const calculateMaxSize = (art_w, art_h) => {
  var DPPmin = 170;
  var iToCm = 2.54;
  var minSetCm = 5;

  console.log(art_w);

  var max_w_i;
  var max_h_i;
  var max_w_cm;
  var max_h_cm;
  var min;
  var trunc_min;

  var w_list = [];
  var h_list = [];

  if (art_w <= art_h) {
    max_w_i = art_w / DPPmin;
    max_h_i = (max_w_i * art_h) / art_w;
  } else {
    max_w_i = art_h / DPPmin;
    max_h_i = (max_h_i * art_w) / art_w;
  }

  max_h_cm = max_h_i * iToCm;
  max_w_cm = max_w_i * iToCm;
  console.log(max_w_cm);

  if (max_h_cm <= max_w_cm) {
    var min_max_cm = max_h_cm;
  } else {
    var min_max_cm = max_w_cm;
  }

  var trunc_min_max_cm = Number(min_max_cm.toFixed(0));

  if (min_max_cm == max_h_cm) {
    if (trunc_min_max_cm < minSetCm) {
      h_list.push(trunc_min_max_cm);
      w_list.push(Number(((trunc_min_max_cm * art_h) / art_w).toFixed(1)));
    } else {
      for (var i = trunc_min_max_cm; i > minSetCm; i = i - 1) {
        h_list.push(i);
        w_list.push(Number(((i * art_w) / art_h).toFixed(1)));
      }
    }
  } else {
    if (trunc_min_max_cm < minSetCm) {
      w_list.push(trunc_min_max_cm);
      h_list.push(Number(((trunc_min_max_cm * art_w) / art_h).toFixed(1)));
    } else {
      for (var i = trunc_min_max_cm; i > minSetCm; i = i - 1) {
        w_list.push(i);
        h_list.push(Number(((i * art_h) / art_w).toFixed(1)));
      }
    }
  }

  w_list = w_list.reverse();
  h_list = h_list.reverse();

  return { w_list, h_list };

  /*   var select = document.getElementById("selectSize"); 
    
    
    for(var j = 0; j < w_list.length; j++){
        var opt = w_list[j]+'x'+h_list[j]+'cm';
        var el = document.createElement("option");
        el.textContent = opt;
        el.value = opt;
        select.appendChild(el);
    }
    */
};
