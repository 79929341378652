import React from "react";

interface Context {
  imageUrl: string;
  articleId: string;
  originalName: string;
  minPriceList: Number[];
  lamaList: string[];
  imageWidth: Number;
  imageHeight: Number;
  wList:Number[];
  hList: Number[];
  wSelected: Number;
  hSelected: Number;
  indexSelected: Number,
  matColor: string,
  matStyle: string,
  matShape: string,
  matSize: Number,
  mount: string,
  artType: string,
  mouldingName:string,
  selectedStyle: string,
  addImageUrl: (url: string, articleId:string, originalName:string, minPriceList:Number[], lamaList:string[], imageWidth: Number, imageHeight: Number, wList:Number[], hList: Number[]) => void;
  loadImageUrl: (url: string, imageWidth: Number, imageHeight: Number)=>void;
  selectSize: (w_selected: Number, h_selected: Number, index_selected: Number) => void;
  selectMatColor: (color_selected: string)=> void;
  selectMatStyle: (style_selected: string)=> void;
  selectMatSize: (selected_size: Number)=> void;
  selectMatShape: (style_selected: string)=> void;
  selectMount: (selected_mount: string)=>void;
  selectArtType: (selected_art_type: string)=> void;
  selectMouldingName: (selected_moulding_name:string)=>void;
  deleteFrame: ()=>void;
  selectSelectedStyle: (selected_style: string)=>void;
  selectImageSize: (new_width:number, new_height:number, new_path:string)=> void;
}

const fContext = React.createContext<Context>({
  imageUrl: "",
  articleId:"",
  originalName:"",
  minPriceList:[],
  lamaList:[],
  imageWidth: 0,
  imageHeight: 0,
  wList:[],
  hList: [],
  wSelected: 0,
  hSelected: 0,
  indexSelected: 0,
  matColor: '#FEFEFE',
  matStyle: 'standard',
  matShape:'standard',
  matSize: 8,
  mount: 'simple',
  artType:'',
  mouldingName:'',
  selectedStyle: 'standard',
  loadImageUrl: ()=>{},
  addImageUrl: () => {},
  selectSize: ()=>{},
  selectMatColor: ()=>{},
  selectMatStyle: ()=>{},
  selectMatShape: ()=>{},
  selectMatSize: ()=>{},
  selectMount: ()=>{},
  selectArtType: ()=>{},
  selectMouldingName:()=>{},
  deleteFrame: ()=>{},
  selectSelectedStyle:()=>{},
  selectImageSize:()=>{}
});

export default fContext;
